import API from './index'
import {
  API_USER_INFO,
  API_USER_CHILD_USERS,
  API_USER_PERMISSIONS,
  API_USER_WARRANTS,
  API_USER_UPDATE_INFO,
  API_USER_PERMISSION_LIST,
  API_USER_ACCOUNT,
  API_USER_CONSUMPTION,
  API_USER_PREFERENCES,
  API_USER_ALL_PERMISSION_LIST,
  API_REPORT_CATEGORIES,
  API_REPORT,
  API_USER_PLUS_RF_PERMISSION_LIST,
} from './endpoints'

export function getUserInfo(headers?: PLUS.Headers): any {
  return API.get(API_USER_INFO, { headers })
}

/**
 * 获取子用户
 * @param level 1 一级,2 二级 不传则代表全部
 */
export function getChildUsers(level?: 1 | 2) {
  return API.get(API_USER_CHILD_USERS, {
    params: {
      level,
    },
  })
}

export function getPermissions(userId?: string | number) {
  return API.get(API_USER_PERMISSIONS, {
    params: {
      plus_user_id: userId,
    },
  })
}

export function getWarrants(userId?: string | number) {
  return API.get(API_USER_WARRANTS, {
    params: {
      plus_user_id: userId,
    },
  })
}

/**
 * 更新用户信息
 * @param name 姓名
 * @param position 职位
 * @param department 部门
 */
export function updateUserInfo({
  name,
  subsidiary,
  position,
  department,
}: {
  name: string
  subsidiary: string
  position: string
  department: string
}) {
  return API.post(API_USER_UPDATE_INFO, {
    name,
    position,
    department,
    sub_company_name: subsidiary,
  })
}

/**
 * 获取用户权限列表
 */
export function getUserPermissions(headers?: PLUS.Headers) {
  return API.get(API_USER_PERMISSION_LIST, { headers })
}

/**
 * 获取全部授权基本信息列表
 */
export function getAllUserPermissions(headers?: PLUS.Headers) {
  return API.get(API_USER_ALL_PERMISSION_LIST, { headers })
}

/**
 * 获取用plus打通微利套餐权限列表
 */
export function getPlusRfPermissions(headers?: PLUS.Headers) {
  return API.get(API_USER_PLUS_RF_PERMISSION_LIST, { headers })
}

/**
 * 获取账户信息
 */
export function getUserAccountInfo(headers?: PLUS.Headers) {
  return API.get(API_USER_ACCOUNT, { headers })
}

/**
 * 获取账户消费统计
 */
export function getUserConsumption(headers?: PLUS.Headers) {
  return API.get(API_USER_CONSUMPTION, { headers })
}

/**
 * 设置偏好设置
 */
export function updatePreferenceSetting(writeWarrant: boolean, isSubCompany?: boolean, headers?: PLUS.Headers) {
  return API.post(
    API_USER_PREFERENCES,
    {
      write_warrant: writeWarrant,
      is_sub_company: isSubCompany,
    },
    { headers }
  )
}

/**
 * 设置偏好设置
 */
export function getReportCategories() {
  return API.get(API_REPORT_CATEGORIES, { useCache: true })
}

/**
 * 举报
 */
export function report({
  categoryId,
  content,
  resourceId,
  resourceType,
}: {
  categoryId: number
  content: string
  resourceId: number
  resourceType: any
}) {
  return API.post(API_REPORT, {
    report_category_id: categoryId,
    content,
    resource_id: resourceId,
    resource_type: resourceType,
  })
}

/**
 * 记录版本通知
 * @param version 版本号
 */
export function setVersionNotified(version: string) {
  return API.post('/user/version-notice', {
    version,
  })
}
