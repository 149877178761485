import React, { useRef } from 'react'
import styled from 'styled-components'
import classNames from 'classnames'
import { palette } from '@zcool/theme'

import DraggableCore, { DraggableData } from './draggable'

interface Props extends CommonProps {
  max?: number
  min?: number
  step?: number
  value: number
  onChange: (value: number) => void
  className?: string
  style?: React.CSSProperties
}

interface StyleProps extends CommonProps {
  percentage: number
}

interface CommonProps {
  direction: 'hor' | 'ver'
}

const SliderStyle = styled.div`
  display: flex;
  position: relative;
  cursor: pointer;

  .slider--default {
    border-radius: 2px;
    background: rgba(255, 255, 255, 0.3);
  }

  .slider--active {
    position: absolute;
    border-radius: 2px;
    background: ${palette.primary};

    & > i {
      display: inline-block;
      height: 10px;
      width: 10px;
      border-radius: 50%;
      background: ${palette.white};
      position: absolute;
      transition: box-shadow 0.2s;
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.3);

      &:hover {
        box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.3);
      }
    }
  }

  &.slider__wrapper--hor {
    width: 100%;
    align-items: center;
    height: 16px;

    .slider--default {
      width: 100%;
      height: 4px;
    }

    .slider--active {
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      height: 4px;
      width: ${({ percentage }: StyleProps) => percentage * 100}%;

      & > i {
        right: -5px;
        top: -3px;
      }
    }
  }

  &.slider__wrapper--ver {
    width: 16px;
    justify-content: center;

    .slider--default {
      height: 100%;
      width: 4px;
    }

    .slider--active {
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      width: 4px;
      height: ${({ percentage }) => percentage * 100}%;

      & > i {
        top: -5px;
        left: -3px;
      }
    }
  }
`

Slider.defaultProps = {
  min: 0,
  max: 100,
  step: 1,
}

export default function Slider({ max, min, step, value, style, direction, onChange, className }: Props) {
  const ref = useRef(null)

  const getRect = () => ref.current && ref.current.getBoundingClientRect()

  const calcLengthToValue = (currentLength: number, totalLength: number): number => {
    return Math.floor(((currentLength / totalLength) * max) / step) * step
  }

  const calcLimitValue = (v: number): number => {
    if (v > max) {
      return max
    }
    if (v < min) {
      return min
    }
    return v
  }

  const handleClick = (ev: React.MouseEvent) => {
    const rect = getRect()
    const totalLength = direction === 'hor' ? rect.width : rect.height
    const clickLength = direction === 'hor' ? ev.clientX - rect.left : rect.bottom - ev.clientY
    const newValue = calcLimitValue(calcLengthToValue(clickLength, totalLength))

    if (newValue !== value) {
      onChange(newValue)
    }
  }

  const handleDrag = (_, data: DraggableData) => {
    const rect = getRect()
    const totalLength = direction === 'hor' ? rect.width : rect.height
    const dragLength = direction === 'hor' ? data.x : -data.y
    const newValue = calcLimitValue(calcLengthToValue(dragLength, totalLength) + value)

    if (newValue !== value) {
      onChange(newValue)
    }
  }

  return (
    <SliderStyle
      direction={direction}
      percentage={value / max}
      onClick={handleClick}
      ref={ref}
      className={classNames({
        [`slider__wrapper--${direction}`]: true,
        className,
      })}
      style={style}
    >
      <div className="slider--default" />
      <div className="slider--active">
        <DraggableCore onDrag={handleDrag}>
          <i onClick={(ev) => ev.stopPropagation()} />
        </DraggableCore>
      </div>
    </SliderStyle>
  )
}
