import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import { palette } from '@zcool/theme'
import Icon from 'components/ui/icon'
import { STORAGE_KEYS } from 'shared/constants'

const Content = styled.section`
  position: fixed;
  background: white;
  font-size: 14px;
  color: ${palette.spruce};
  padding: 0 0 0 16px;
  bottom: 8px;
  height: 36px;
  z-index: 1100;
  display: flex;
  align-items: center;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  transform: translateX(-50%);
  left: 50%;

  .close {
    line-height: 1;
    padding: 8px 15px;
  }
`

export default function ViewReminder() {
  const [screenWidth, setScreenWidth] = useState(null)

  useEffect(() => {
    const value = window.localStorage.getItem(STORAGE_KEYS.VIEW_REMINDER)
    setScreenWidth(value === '1' ? null : window.screen.width)
  }, [])

  return !screenWidth || screenWidth >= 1440 ? null : (
    <Content>
      <FormattedMessage id="common.view-remind-text" />
      <span className="close">
        <Icon
          glyph="close"
          size={8}
          hoverColor={palette.primary}
          hoverTitle="关闭"
          onClick={() => {
            setScreenWidth(null)
            window.localStorage.setItem(STORAGE_KEYS.VIEW_REMINDER, '1')
          }}
        />
      </span>
    </Content>
  )
}
