import React from 'react'
import styled from 'styled-components'
import Icon from 'components/ui/icon'

interface Props {
  collapsed?: boolean
  defaultCollapsed?: boolean
  nodeLabel?: React.ReactNode
  className?: string
  itemClassName?: string
  childrenClassName?: string
  treeViewClassName?: string
  onClick?: () => void
}

interface State {
  collapsed: boolean
}

const TreeViewContainer = styled.div`
  .tree-view_item {
    white-space: nowrap;
    display: flex;
    align-items: center;
  }

  .tree-view_children {
    margin-left: 16px;
  }

  .tree-view_children-collapsed {
    height: 0;
  }

  .tree-view_arrow {
    display: inline-flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    margin-right: 8px;
    user-select: none;
  }

  .tree-view_arrow-collapsed {
    transform: rotate(-90deg);
  }
`

class TreeView extends React.PureComponent<Props> {
  state = {
    collapsed: this.props.defaultCollapsed,
  }

  handleClick = () => {
    this.setState((prevState: State) => ({
      collapsed: !prevState.collapsed,
    }))
    if (this.props.onClick) {
      this.props.onClick()
    }
  }

  render() {
    const {
      collapsed = this.state.collapsed,
      className = '',
      itemClassName = '',
      treeViewClassName = '',
      childrenClassName = '',
      nodeLabel,
      children,
      ...rest
    } = this.props

    let arrowClassName = 'tree-view_arrow'
    let containerClassName = 'tree-view_children'
    if (collapsed) {
      arrowClassName += ' tree-view_arrow-collapsed'
      containerClassName += ' tree-view_children-collapsed'
    }

    const arrow = (
      <div {...rest} className={`${className} ${arrowClassName}`} onClick={this.handleClick}>
        <Icon glyph="triangle-down" size={8} />
      </div>
    )

    return (
      <TreeViewContainer className={treeViewClassName}>
        <div className={`tree-view_item ${itemClassName}`}>
          {arrow}
          {nodeLabel}
        </div>
        <div className={`${containerClassName} ${childrenClassName}`}>{collapsed ? null : children}</div>
      </TreeViewContainer>
    )
  }
}

export default TreeView
