import React, { useState, useEffect } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import styled, { css } from 'styled-components'
import Tooltip from '@zcool/tooltip'
import Flex from '@zcool/flex'
import { palette } from '@zcool/theme'
import { Text } from 'components/ui/base'
import Icon from 'components/ui/icon'
import { showCreateModal } from 'components/account/collection/input-name'
import { cancelCollected, addCollected } from 'components/account/collection/actions'
import { events } from 'helpers/event-emitter'
import { getUrlData, handleLocationToPageName, noop } from 'helpers/util'
import {
  ContentType,
  CollectType,
  CollectActionType,
  MeituAnalyzeSearchContentType,
  MeituAnalyzeBuyButtonType,
  MeituAnalyzeContentType,
  MeituAnalyzeSearchPageName,
} from 'helpers/enums'
import { ACTIONS, STORAGE_KEYS } from 'shared/constants'
import { showModal, toast, hideModal } from 'shared/actions'
import { addMaterialIntoCollection, getLastCollection, createCollection } from 'api/collection'
import { meituCollectClick, meituCollectConfirmClick, meituSearchResultsInfoDownloadClick } from 'helpers/analyze-meitu'

const AnimationIcon = styled.span`
  position: relative;

  &.easeout {
    animation: easeout 0.1s ease-out forwards;
  }

  &.easein {
    animation: easein 0.3s ease-in forwards;
  }

  @keyframes easein {
    0% {
      transform: scale(0, 0);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  @keyframes easeout {
    0% {
      transform: scale(1, 1);
    }

    100% {
      transform: scale(0, 0);
    }
  }
`

const AnimationContainer = styled.div`
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: 0 center;

  .star {
    width: 1px;
    height: 1px;
    background-color: ${palette.primary};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform-origin: 0 center;
  }

  .star1 {
    animation: animate1 0.3s ease;
  }

  .star2 {
    animation: animate2 0.3s ease;
  }

  .star3 {
    animation: animate3 0.3s ease;
  }

  .star4 {
    animation: animate4 0.3s ease;
  }

  .star5 {
    animation: animate5 0.3s ease;
  }

  @keyframes animate1 {
    0% {
      transform: translate(-0.5px, 0);
      height: 1px;
    }

    25% {
      transform: translate(-0.5px, -3px);
      height: 2px;
    }

    50% {
      transform: translate(-0.5px, -6px);
      height: 3px;
    }

    75% {
      transform: translate(-0.5px, -9px);
      height: 3px;
    }

    100% {
      transform: translate(-0.5px, -12px);
      height: 2px;
    }
  }

  @keyframes animate2 {
    0% {
      transform: rotate(15deg) translate(0, 0);
      width: 1px;
    }

    25% {
      transform: rotate(15deg) translate(-3px, 0);
      width: 2px;
    }

    50% {
      transform: rotate(15deg) translate(-6px, 0);
      width: 3px;
    }

    75% {
      transform: rotate(15deg) translate(-9px, 0);
      width: 2px;
    }

    100% {
      transform: rotate(15deg) translate(-12px, 0);
      width: 1px;
    }
  }

  @keyframes animate3 {
    0% {
      transform: rotate(-15deg) translate(0, 0);
      width: 1px;
    }

    25% {
      transform: rotate(-15deg) translate(3px, 0);
      width: 2px;
    }

    50% {
      transform: rotate(-15deg) translate(6px, 0);
      width: 3px;
    }

    75% {
      transform: rotate(-15deg) translate(9px, 0);
      width: 2px;
    }

    100% {
      transform: rotate(-15deg) translate(12px, 0);
      width: 1px;
    }
  }

  @keyframes animate4 {
    0% {
      transform: rotate(45deg) translate(0, 0);
      height: 1px;
    }

    25% {
      transform: rotate(45deg) translate(0, 3px);
      height: 2px;
    }

    50% {
      transform: rotate(45deg) translate(0, 6px);
      height: 3px;
    }

    75% {
      transform: rotate(45deg) translate(0, 9px);
      height: 3px;
    }

    100% {
      transform: rotate(45deg) translate(0, 12px);
      height: 1px;
    }
  }

  @keyframes animate5 {
    0% {
      transform: rotate(50deg) translate(0, 0);
      width: 1px;
    }

    25% {
      transform: rotate(50deg) translate(3px, 0);
      width: 2px;
    }

    50% {
      transform: rotate(50deg) translate(6px, 0);
      width: 3px;
    }

    75% {
      transform: rotate(50deg) translate(9px, 0);
      width: 3px;
    }

    100% {
      transform: rotate(50deg) translate(12px, 0);
      width: 1px;
    }
  }
`

const StyledIcon = styled(Flex)<{
  canChangeColor: boolean
}>`
  cursor: pointer;
  ${({ canChangeColor }) =>
    canChangeColor
      ? css`
          :hover {
            color: ${palette.primary};
            svg {
              color: ${palette.primary};
            }
          }
        `
      : null}
`

const cachedCollection = new Map()

const Collect = React.memo(
  function ({
    isCollected,
    index,
    id,
    contributorId,
    resourceId,
    contentType,
    label,
    size = 20,
    placement = 'top',
    onToggleCollect = noop,
    canChangeColor = false,
    svgPrefix = '',
    cb,
    searchStatistic = {},
  }: {
    id: string
    contributorId: string
    resourceId?: string
    isCollected: boolean
    contentType: ContentType
    size?: number
    index?: number
    label?: string
    placement?: 'top' | 'bottom'
    onToggleCollect?: (isCollected: boolean) => void
    canChangeColor?: boolean
    svgPrefix?: string
    cb?: (type: string) => void
    searchStatistic?: Record<string, any>
  }) {
    let collectionId
    let collectionName
    const targetResourceId = resourceId || id
    const cacheKey = `${id}--${contentType}`
    const [collected, setCollected] = useState(isCollected)
    const [className, setClassName] = useState('')
    const [showStar, setShowStar] = useState(false)
    const [collectInfo, setCollectInfo] = useState(null)
    const intl = useIntl()

    useEffect(() => {
      events.on(ACTIONS.BATCH_COLLECTION, handleBatchCollection)
      events.on(ACTIONS.SYNC_MUSIC_STATUS, syncCollectStatus)
      return () => {
        events.off(ACTIONS.BATCH_COLLECTION, handleBatchCollection)

        cachedCollection.clear()
      }
    }, [])

    useEffect(() => {
      setCollected(!!isCollected)
    }, [isCollected])

    function handleBatchCollection(checkedIds: Array<string>) {
      if (checkedIds && checkedIds.length && checkedIds.includes(id)) {
        setCollected(true)
      }
    }

    function handleMeituAnalyzeCollect(type) {
      if (contentType === 5) return
      try {
        const material_type =
          MeituAnalyzeSearchContentType[
            MeituAnalyzeContentType[contentType] as keyof typeof MeituAnalyzeSearchContentType
          ]

        const request_id = window.sessionStorage.getItem(STORAGE_KEYS.SEARCH_ID_KEY) || searchStatistic.searchid
        const href = handleLocationToPageName(MeituAnalyzeContentType[contentType])
        const page_source = MeituAnalyzeSearchPageName[href as keyof typeof MeituAnalyzeSearchPageName]
        const postion_id = index?.toString() // 索引
        const package_id = type.toString() // 收藏夹id
        const card_id = id // id
        const author_id = contributorId // 作者id
        const params = getUrlData() // url参数
        const key_word = searchStatistic?.term || decodeURIComponent(params.q)
        /** 收藏确认埋点 */
        if (typeof type === 'number') {
          meituCollectConfirmClick({
            key_word,
            package_id,
            page_source,
            material_type,
            postion_id,
            request_id,
            card_id,
            author_id,
          })
        }
        /** 收藏点击埋点 */
        if (type === 'Collect') {
          meituCollectClick({
            key_word,
            page_source,
            material_type,
            postion_id,
            request_id,
            card_id,
            author_id,
          })
        }
        /** 详情页收藏点击埋点 */
        if (href === 'SearchResult2' && typeof type === 'string') {
          const button_type = MeituAnalyzeBuyButtonType[type as keyof typeof MeituAnalyzeBuyButtonType]
          meituSearchResultsInfoDownloadClick({
            button_type,
            request_id,
            material_type,
            card_id,
            author_id,
          })
        }
      } catch (error) {
        console.error(error)
      }
    }
    function handleClickCollection(e: React.MouseEvent<HTMLDivElement>) {
      e.stopPropagation()

      getCollectInfo(() => {
        if (collected) {
          handleMeituAnalyzeCollect('UnCollect')

          cancelCollected(targetResourceId, contentType, () => {
            onToggleCollect(!collected)
            setClassName('easeout')
            setTimeout(() => {
              setCollected(false)
              setClassName('easein')
            }, 100)
            setTimeout(() => {
              setClassName('')
            }, 500)
            cb && cb(CollectActionType.Cancel)
          })
        } else {
          handleMeituAnalyzeCollect('Collect')

          addCollected({
            id: targetResourceId,
            contentType,
            collectionId,
            collectionName,
            callback: () => {
              handleMeituAnalyzeCollect(collectionId)
              onToggleCollect(!collected)
              setClassName('easeout')
              setTimeout(() => {
                setCollected(true)
                setClassName('easein')
                setShowStar(true)
              }, 100)
              setTimeout(() => {
                setClassName('')
                setShowStar(false)
              }, 500)
              cb && cb(CollectActionType.Collect)
            },
          })
        }
      })
    }

    function handleMouseOver() {
      getCollectInfo()
    }

    async function getCollectInfo(callback?: () => void) {
      let response

      if (cachedCollection.get(cacheKey)) {
        response = cachedCollection.get(cacheKey)
      } else {
        response = await getLastCollection(
          collected ? targetResourceId : undefined,
          collected ? contentType : undefined
        )

        cachedCollection.set(cacheKey, response)
      }

      const { result, data } = response

      if (result) {
        if (data.id) {
          collectionId = data.id
          collectionName = data.name
          setCollectInfo(data)
        } else {
          collectionName = intl.formatMessage({ id: 'common.default-lightbox' })
          setCollectInfo({ name: collectionName })
        }

        if (callback) {
          callback()
        }
      }
    }

    function syncCollectStatus({ id: targetId, collected: c }: { id: number; collected: boolean }) {
      if (targetId.toString() === id.toString()) {
        setCollected(c)
      }
    }

    return (
      <Tooltip
        key="icon-collect"
        placement={placement}
        mouseEnterDelay={100}
        mouseLeaveDelay={100}
        overlayStyle={{ fontSize: 12, padding: 0 }}
        title={
          <CollectTooltip
            id={targetResourceId}
            index={index}
            contributorId={contributorId}
            contentType={contentType}
            collectInfo={collectInfo}
            isCollected={collected}
            onMouseEnter={() => toggleAction(index, 'flex', contentType)}
            onMouseLeave={() => toggleAction(index, 'none', contentType)}
            callback={(value) => {
              setCollected(true)
              setCollectInfo(value)
              cachedCollection.set(cacheKey, { result: true, data: value })
            }}
          />
        }
      >
        <StyledIcon
          className="collect"
          valign="center"
          canChangeColor={canChangeColor}
          onMouseOver={handleMouseOver}
          onClick={handleClickCollection}
        >
          <AnimationIcon className={`collect_icon ${className}`}>
            {collected && showStar && <AnimationStar />}
            <Icon
              glyph={collected ? `${svgPrefix}collect-active` : `${svgPrefix}collect`}
              size={size}
              color={palette.spruce}
            />
          </AnimationIcon>
          {label && (
            <Text ml={8} mr={8}>
              {collected ? intl.formatMessage({ id: 'actions.collect-cancel' }) : label}
            </Text>
          )}
        </StyledIcon>
      </Tooltip>
    )
  },
  (nextProps, prevProps) => {
    if (nextProps.isCollected !== prevProps.isCollected) {
      return false
    }

    return true
  }
)

Collect.displayName = 'Collect'

export default Collect

const AnimationStar = React.memo(function () {
  return (
    <AnimationContainer>
      <div className="star star1" />
      <div className="star star2" />
      <div className="star star3" />
      <div className="star star4" />
      <div className="star star5" />
    </AnimationContainer>
  )
})

AnimationStar.displayName = 'AnimationStar'

function toggleAction(index: number, display: string, contentType: ContentType) {
  const type = contentType === ContentType.Video ? 'video' : 'image'
  const item: any = document.getElementsByClassName(`${type}__actions`)[index]

  if (item) {
    item.style.display = display
  }
}

const COLLECT_MAP = {
  [CollectType.Add]: {
    title: 'actions.add-to-lightbox',
    toast: 'toast.added-to-lightbox',
  },
  [CollectType.Move]: {
    title: 'actions.remove-from-lightbox',
    toast: 'toast.moved-to-lightbox',
  },
}

const CollectTooltip = React.memo(function ({
  id,
  index,
  contributorId,
  isCollected,
  contentType,
  collectInfo,
  callback,
  onMouseEnter,
  onMouseLeave,
}: {
  id: string
  index: number
  contributorId: string
  isCollected: boolean
  contentType: ContentType
  collectInfo: { id?: number; name: string }
  callback: (params: { id?: number; name: string }) => void
  onMouseEnter: (event: any) => void
  onMouseLeave: (event: any) => void
}) {
  const type = isCollected ? CollectType.Move : CollectType.Add

  async function addIntoCollection(collectionId: number, name: string) {
    const { result, message } = await addMaterialIntoCollection({
      id,
      collectionId,
      contentType,
    })

    if (result) {
      toast(
        <FormattedMessage id={COLLECT_MAP[type].toast} values={{ name }}>
          {(msg) => msg}
        </FormattedMessage>
      )
      hideModal('MOVE_COLLECTION')

      handleMeituAnalyzeCollect(collectionId)

      callback({ id: collectionId, name })
    } else {
      toast(message)
    }
  }

  /** 添加收藏夹弹窗埋点 */
  function handleMeituAnalyzeCollect(btnType) {
    if (contentType === 5) return
    try {
      const material_type =
        MeituAnalyzeSearchContentType[
          MeituAnalyzeContentType[contentType] as keyof typeof MeituAnalyzeSearchContentType
        ]

      const request_id = window.sessionStorage.getItem(STORAGE_KEYS.SEARCH_ID_KEY)

      const href = handleLocationToPageName(MeituAnalyzeContentType[contentType])
      const page_source = MeituAnalyzeSearchPageName[href as keyof typeof MeituAnalyzeSearchPageName]
      const postion_id = index?.toString()
      const card_id = id
      const author_id = contributorId

      /** 收藏确认埋点 */
      if (typeof btnType === 'number') {
        meituCollectConfirmClick({
          package_id: btnType.toString(),
          page_source,
          material_type,
          postion_id,
          request_id,
          card_id,
          author_id,
        })
      }
      /** 收藏点击埋点 */
      if (btnType === 'Collect') {
        meituCollectClick({
          page_source,
          material_type,
          postion_id,
          request_id,
          card_id,
          author_id,
        })
      }
      /** 详情页收藏点击埋点 */
      if (href === 'SearchResult2' && typeof btnType === 'string') {
        meituSearchResultsInfoDownloadClick({
          button_type: MeituAnalyzeBuyButtonType[btnType as keyof typeof MeituAnalyzeBuyButtonType],
          request_id,
          material_type,
          card_id,
          author_id,
        })
      }
    } catch (error) {
      console.error(error)
    }
  }

  function handleClick() {
    toggleAction(index, 'none', contentType)
    if (collectInfo && collectInfo.id) {
      showModal('MOVE_COLLECTION', {
        resourceId: id,
        resourceType: contentType,
        type,
        addIntoCollection,
      })
    } else {
      handleMeituAnalyzeCollect('Collect')
      showCreateModal(
        (name) => createCollection(name),
        (name: string, collectId: number) => addIntoCollection(collectId, name)
      )
    }
  }

  return (
    <Flex
      halign="space-between"
      valign="center"
      style={{ minWidth: 150, cursor: 'pointer', padding: 8 }}
      onMouseOver={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={handleClick}
    >
      <Flex direction="column">
        <Text color="white" size={12} lineHeight="17px">
          <FormattedMessage id={COLLECT_MAP[type].title}>{(msg) => msg}</FormattedMessage>
        </Text>
        <Text color="#66ADFF" size={12} lineHeight="17px">
          {collectInfo ? collectInfo.name : '...'}
        </Text>
      </Flex>
      <Icon glyph="arrow-down" size={16} color={palette.gray} />
    </Flex>
  )
})

CollectTooltip.displayName = 'CollectTooltip'
