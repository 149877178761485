import styled from 'styled-components'
import Button from '@zcool/button'
import { palette, spacing, font } from '@zcool/theme'
import { zIndex } from '@zcool/util'
import Dropdown from '@zcool/dropdown'

export const SearchButton = styled(Button)`
  position: absolute;
  width: 104px;
  min-width: auto;
  height: 100%;
  border-radius: 25px;
  right: -1px;
  top: 0;
  background-color: ${({ theme }) => theme.customize.header_search_btn_color};
  border: none;
  line-height: 0;

  &:hover,
  &:active {
    background-color: ${({ theme }) => theme.customize.header_search_btn_hover_color};
    opacity: 0.9;
  }
`

export const HeaderContainer = styled.header<{ transparent: boolean }>`
  @media (max-width: 760px) {
    overflow-x: scroll;
    padding: 0;

    .logo {
      display: none !important;
    }

    .left__tabs > .item {
      margin: 0 3px !important;
    }

    .try {
      width: 66px !important;
      margin-right: 4px !important;
      padding: 0;
    }

    .language {
      width: 76px !important;
      margin-right: 4px !important;
    }

    .login {
      width: 80px !important;
      padding: 0;
    }
  }

  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ transparent, theme }) => (transparent ? 'transparent' : theme.customize.header_bg_color)};
  height: 90px;
  padding: 0 32px;
  margin-left: auto;
  margin-right: auto;
  color: ${palette.white};
  z-index: ${zIndex.navFixed};
  transition: ease-in 0.3s;

  .logo {
    display: inline-block;
    width: 216px;
    height: 40px;
    background: ${({ theme }) => `url(${theme.customize.logo_url})`} no-repeat left/160px auto;
    background-size: contain;
  }

  .drawer {
    line-height: 0;
  }

  .drawer + .logo {
    margin-left: 24px;
  }

  .buttons {
    min-width: 220px;
  }

  .shein-topup {
    height: 32px;
    padding: 6px 20px;
    min-width: 67px;
    line-height: 20px;
    font-size: 14px;
    color: #773912;
    background: #f1d1b0;
    border: none;
  }

  .icon {
    color: rgba(255, 255, 255, 0.8);

    &:hover {
      color: ${palette.white};
    }
  }

  .search__box {
    flex-grow: 2;
    display: flex;
    align-items: center;
    font-size: 14px;
    height: 46px;
    margin: 0 70px;
    position: relative;
    padding: 0 100px 0 24px;
    border-radius: 25px;
    background-color: ${palette.white};
    color: ${palette.spruce};

    input {
      flex: 1;
      margin: 0 ${spacing.sm}px;
      height: 100%;
      border: none;
    }

    & > span {
      margin-right: ${spacing.sm}px;
      line-height: 0;
    }
  }

  & > .buttons {
    display: flex;
    justify-content: flex-end;
    height: 20px;
    align-items: center;

    > *:not(:first-child) {
      padding-left: 40px;
    }
  }

  .left__tabs {
    flex: 1;
    font-size: ${font.size.md}px;

    > .item {
      margin: 0 35px;
      opacity: 0.8;

      &:hover {
        opacity: 1;
      }

      &.active {
        font-weight: bold;
        opacity: 1;
      }
    }
  }

  .language {
    margin-right: 16px;
    width: 120px;
    text-align: right;
    opacity: 0.8;

    &:hover {
      opacity: 1;
    }

    [data-text='true'] {
      color: ${palette.white};
    }

    [data-icon='true'] {
      color: ${palette.gray};
    }

    > div:nth-child(2) {
      width: 120px;
    }
  }

  .try,
  .login {
    border-radius: 0;
  }

  .try {
    margin-right: 16px;
  }

  .login {
    color: ${palette.white};
    border-color: ${palette.white};
    opacity: 0.8;

    :hover {
      color: ${palette.white};
      border-color: ${palette.white};
      opacity: 1;
    }
  }
`

export const StyledDropdown = styled(Dropdown)`
  [data-text='true'] {
    display: flex;
    align-items: center;
  }

  > div:nth-child(2) {
    transform: translate3d(0, 0, 0) !important;
    margin: ${spacing.xs}px 0;
    top: 18px !important;
  }
`

export const WhiteDropdown = styled(StyledDropdown)`
  > div:nth-child(2) {
    left: -19px !important;
  }

  [data-icon='true'] {
    color: ${palette.white};
  }
`

export const PositionWhiteDropdown = styled(WhiteDropdown)`
  > div:nth-child(2) {
    left: -216px !important;
  }
`

export const Consultant = styled.div`
  min-width: 310px;
  padding: 16px;
  z-index: ${zIndex.dropdown};
  background-color: ${palette.white};
  display: flex;
`

export const QRCode = styled.img`
  margin-top: 2px;
  margin-right: 16px;
  width: 48px;
  height: 48px;
  background: #fff;
`
