import React, { memo, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import Link from 'next/link'
import getConfig, { setConfig } from 'next/config'
import Badge from '@zcool/badge'
import Icon from 'components/ui/icon'
import { getCartCount } from 'api/cart'
import { ACTIONS } from 'shared/constants'
import { events } from 'helpers/event-emitter'

export default memo(function Cart() {
  const { publicRuntimeConfig } = getConfig()
  const [cartNum, setCartNum] = useState(publicRuntimeConfig.CART_COUNT)

  useEffect(() => {
    fetchCartCounts()
  }, [])

  useEffect(() => {
    events.on(ACTIONS.UPDATE_CART_COUNT, updateCart)
    return () => {
      events.off(ACTIONS.UPDATE_CART_COUNT, updateCart)
    }
  }, [])

  async function fetchCartCounts() {
    const { result, data } = await getCartCount()
    if (result) {
      setCartNum(data)
      setConfig({
        publicRuntimeConfig: { ...publicRuntimeConfig, CART_COUNT: data },
      })
    }
  }

  function updateCart(count: number) {
    setCartNum(count)
    setConfig({
      publicRuntimeConfig: { ...publicRuntimeConfig, CART_COUNT: count },
    })
  }

  return (
    <Link href="/cart">
      <a id="cart-container">
        <Badge visible={cartNum > 0} count={cartNum} offset={[3, 3]} className="cart-badge">
          <FormattedMessage id="page.cart.title">
            {(msg) => <Icon glyph="cart" size={20} className="icon" hoverTitle={msg} />}
          </FormattedMessage>
        </Badge>
      </a>
    </Link>
  )
})
