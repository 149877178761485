import React, { memo } from 'react'
import { FormattedMessage } from 'react-intl'
import Link from 'next/link'
import styled from 'styled-components'
import Flex from '@zcool/flex'
import { palette } from '@zcool/theme'
import Icon from 'components/ui/icon'

const StyledBackToHome = styled(Flex)`
  position: fixed;
  left: 32px;
  bottom: 32px;
  width: 136px;
  height: 40px;
  text-align: center;
  line-height: 36px;
  color: ${palette.white};
  border: 1px solid ${palette.white};
  border-radius: 20px;
  background-color: transparent;

  :hover {
    background-color: rgba(255, 255, 255, 0.3);
  }
`

export default memo(function BackToHome() {
  return (
    <StyledBackToHome halign="center" valign="center">
      <Icon glyph="back-home" size={16} mr={8} />
      <FormattedMessage id="actions.back-to-home">
        {(msg) => (
          <Link href="/dam">
            <a>{msg}</a>
          </Link>
        )}
      </FormattedMessage>
    </StyledBackToHome>
  )
})
