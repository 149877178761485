import { saveSearchId, searchStatic, canUseDOM } from 'helpers/util'
import { Platform, RESOURCETYPE } from 'helpers/enums'

import API from './index'
import {
  AUDIO_BANNER,
  AUDIO_HOT_TAGS,
  AUDIO_INDEX_RECOMMEND,
  API_EVENT_LISTS,
  API_EVENT_MENUS,
  AUDIO_ALBUMS_CATEGORIES,
  AUDIO_INDEX_TOPICS,
  AUDIO_INVENTION_DETAIL,
  AUDIO_INVENTION_SEARCH,
  AUDIO_ALBUMS_LISTS,
  AUDIO_PROVIDERS,
} from './endpoints'

/**
 * 音乐首页 - Banner
 *
 */
export function getAudioBannerImages({
  headers,
}: {
  headers?: PLUS.Headers
} = {}) {
  return API.get(AUDIO_BANNER, {
    headers,
  })
}

/**
 * 音乐首页 - 音乐专题
 *
 */
export function getAudioTopics({
  headers,
}: {
  headers?: PLUS.Headers
} = {}) {
  return API.get(`${AUDIO_INDEX_TOPICS}/6`, {
    headers,
  })
}

/**
 * 音乐首页 - 热搜分类 - 标签组
 */
export function getAudioHotTags({ headers }: { headers?: PLUS.Headers } = {}) {
  return API.get(AUDIO_HOT_TAGS, {
    headers,
  })
}

/**
 * 音乐首页 - 音乐推荐列表
 * @param num 随机音乐数量
 */
export function getAudioRecommend({ headers }: { headers?: PLUS.Headers } = {}) {
  return API.get(`${AUDIO_INDEX_RECOMMEND}/15`, {
    headers,
  })
}

/**
 * 专辑列表
 * @param recommend 是否首页展示
 */
export function getAlbums({
  catId,
  keyword,
  page = 1,
  perPage = 30,
  headers,
  recommend,
}: {
  catId?: number
  keyword?: string
  page?: number
  perPage?: number
  headers?: PLUS.Headers
  recommend?: number
}) {
  return API.get(AUDIO_ALBUMS_LISTS, {
    headers,
    params: {
      category_id: catId,
      keyword,
      page,
      per_page: perPage,
      recommend,
    },
  }).then((res: any) => {
    return res
  })
}

/**
 * 音乐 - 精选专辑 - 分类列表
 */
export function getAlbumsCategories({ headers }: { headers?: PLUS.Headers }) {
  return API.get(AUDIO_ALBUMS_CATEGORIES, { headers })
}

/**
 * 专辑详情
 * @param id 专辑id
 */
export function getAlbumById({ id, page = 1, perPage = 100, headers }: PLUS.APIPageParams & { id: string }) {
  return API.get(`/audio/topic/detail?id=${id}`, {
    headers,
    params: {
      page,
      per_page: perPage,
    },
  })
}

/**
 * 相似音乐
 * @param id 图片id
 */
export function getSimilarById({ id, page = 1, perPage = 5, headers }: PLUS.APIPageParams & { id: string }) {
  return API.get(`/audio/similar/${id}`, {
    headers,
    params: {
      page,
      per_page: perPage,
    },
  })
}

/**
 * 音乐详情
 */
export function getMusicById({
  id,
  searchStatistic = { source: undefined, term: undefined },
  headers = null,
}: {
  id: string
  searchStatistic?: PLUS.SearchStatistic
  headers?: PLUS.Headers
}) {
  return API.get(`/audio/show/${id}`, {
    params: {
      ...searchStatistic,
    },
    headers,
  })
}

export function downloadMusicSampleById(id: number | string) {
  return API.get('/audio/preview-download-url', {
    params: {
      id,
    },
  })
}

/**
 * 音乐搜索 - 供应商列表
 */
export function getProviders({ headers }: { headers?: PLUS.Headers } = {}) {
  return API.get(AUDIO_PROVIDERS, {
    headers,
  })
}

/**
 * 音乐搜索
 */
export function getMusics({
  page = 1,
  headers,
  perPage = 30,
  keyword,
  artist,
  moodId,
  genreId,
  levelType,
  instrumentId,
  isInstrumental,
  bpmId,
  sort,
  canPurchase,
  effect,
  providerId,
}: PLUS.APIPageParams & {
  keyword?: string
  artist?: string
  moodId?: number
  genreId?: number
  levelType?: number
  instrumentId?: number
  isInstrumental?: boolean
  bpmId?: number
  sort?: 'popular' | 'newest'
  canPurchase?: number
  effect?: number
  providerId?: string
} = {}) {
  searchStatic(Platform.Audio, keyword)

  return API.get('/audio/search', {
    headers,
    params: {
      page,
      per_page: perPage,
      keyword: keyword || undefined,
      mood_id: moodId,
      genre_id: genreId,
      level_type: levelType || undefined,
      bpm_id: bpmId,
      instrument_id: instrumentId,
      is_instrumental: isInstrumental,
      sort,
      artist,
      can_purchase: canPurchase,
      effect,
      provider_user_id: providerId,
    },
  })
    .then(saveSearchId)
    .catch((err) => {
      console.error('error in getMusics', err)
      return err
    })
}

/**
 * 音乐试听统计
 * @param id 音乐ID
 * @returns
 */
export function musicStatic(id: number | string) {
  return API.get('/audio/audition-static', {
    params: {
      id,
    },
  })
}

/**
 * 音乐全部专题列表
 */
export function getSubjects({
  id,
  page = 1,
  perPage = 12,
  headers,
  keyword,
}: {
  id: number
  page?: number
  perPage?: number
  headers?: PLUS.Headers
  keyword?: string
}) {
  return API.get(API_EVENT_LISTS, {
    params: {
      resource_type: RESOURCETYPE.MUSIC,
      menu_type: id,
      page,
      per_page: perPage,
      keyword,
    },
    headers,
  }).catch((err) => {
    console.error('音乐全部专题列表', err.message)
    return {}
  })
}

let cachedMenus = []

/**
 * 音乐全部专题列表 分类
 */
export function getMenus({ headers }: { headers: PLUS.Headers }) {
  if (cachedMenus.length > 0 && canUseDOM()) {
    return Promise.resolve({
      data: cachedMenus,
      result: true,
    })
  }

  return API.get(API_EVENT_MENUS, { headers, params: { resource_type: RESOURCETYPE.MUSIC } })
    .then((res: any) => {
      if (res.result) {
        cachedMenus = res.data
      }
      return res
    })
    .catch(() => ({
      data: [],
      result: false,
    }))
}

/**
 * 音乐 - 专题 - 详情
 */

export function getInventionById({
  headers,
  id,
  page = 1,
  perPage = 30,
}: {
  headers?: PLUS.Headers
  id: number
  page?: number
  perPage?: number
}) {
  return API.get(`${AUDIO_INVENTION_DETAIL}/${id}`, {
    headers,
    params: {
      page,
      per_page: perPage,
    },
  })
}

/**
 * 音乐 - 专题 - 搜索
 */
export function getRelatedTopicByInventionId({
  headers,
  id,
  keyword,
  page = 1,
  perPage = 9,
}: {
  headers?: PLUS.Headers
  id: number
  keyword: string
  page?: number
  perPage?: number
}) {
  return API.get(AUDIO_INVENTION_SEARCH, {
    headers,
    params: {
      id,
      keyword,
      page,
      per_page: perPage,
    },
  })
}
