import React from 'react'
import { FormattedMessage } from 'react-intl'
import Link from 'next/link'
import Flex from '@zcool/flex'
import { spacing } from '@zcool/theme'
import { Text } from 'components/ui/base'
import Drawer from 'components/drawer'
import Icon from 'components/ui/icon'
import styled from 'styled-components'

const GoHome = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 232px;
  height: 36px;
  padding: 8px 10px;
  border-radius: 6px;
  background: #f74640;
  color: #f4eded;
  font-family: 'PingFang SC';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
`

const NoDrawLayout = styled.div`
  height: 100vh;
`

interface ErrorInfo {
  status: number
  code: string
  message: React.ReactNode
  imageWidth: number
  originMessage?: string
}

/**
 * 错误状态码 自定义枚举
 */
enum ErrorStatus {
  /* 404 找不到资源 */
  '8000x01' = 404,
  /* 410 资源下架 */
  '8000x02' = 410,
  /* 411 ss下架 */
  '8000x03' = 411,
  /* 412 ss到期 */
  '8000x04' = 412,
  /* 408 连接超时 */
  '8000x05' = 408,
  /* 500 服务器错误 以及未知错误*/
  '8000x06' = 500,
  /* ss账号错误 */
  '8000x07' = 511,
  /* 图片规格出问题/改变分类 */
  '8000x08' = 512,
}

export default function Error({
  statusCode,
  message,
  href = '/creative',
  noDrawer = false,
}: {
  statusCode: number
  message?: string
  href?: string
  noDrawer?: boolean
}) {
  const originMessages: string = message
  // 捕捉自定义错误信息
  const ErrCustomizationErrorArray = [
    // ss音乐权限绑定的图片的client_id 所以下不了音乐
    {
      message: 'Audio from Shutterstock collection cannot be licensed with Premier license type',
      code: '8000x07',
    },
    // 权限图片大小不匹配
    {
      message: 'Size is not supported by subscription',
      code: '8000x08',
    },
    // 资源现为编辑类，暂不支持
    {
      message: 'Editorial status must be acknowledged',
      code: '8000x08',
    },
    // 411 ss下架
    {
      message: 'unavailable in size or format',
      code: '8000x03',
    },
    {
      message: 'is no longer available for licensing',
      code: '8000x03',
    },
    // 412 ss到期
    {
      message: 'Subscription has expired',
      code: '8000x04',
    },
    // ss账号问题
    {
      message: 'SSApi:',
      code: '8000x07',
    },
    // 408 连接超时
    {
      message: 'ECONNABORTED',
      code: '8000x05',
    },
  ]

  ErrCustomizationErrorArray.map((i) => {
    if (message?.includes(i.message)) {
      // eslint-disable-next-line no-param-reassign
      statusCode = ErrorStatus[i.code]
      // eslint-disable-next-line no-param-reassign
      message = ''
    }
  })

  let errorInfo: ErrorInfo
  switch (statusCode) {
    // 404 找不到资源
    case ErrorStatus['8000x01']:
      errorInfo = {
        ...errorInfo,
        status: ErrorStatus['8000x01'],
        code: '8000x01',
        message: <FormattedMessage id="errors.404" />,
        imageWidth: 255,
      }
      break
    // 410 资源下架
    case ErrorStatus['8000x02']:
      errorInfo = {
        ...errorInfo,
        status: ErrorStatus['8000x02'],
        code: '8000x02',
        message: <FormattedMessage id="errors.410" />,
        imageWidth: 300,
      }
      break
    // 411 ss下架
    case ErrorStatus['8000x03']:
      errorInfo = {
        ...errorInfo,
        status: ErrorStatus['8000x03'],
        code: '8000x03',
        message: <FormattedMessage id="errors.411" />,
        imageWidth: 300,
      }
      break
    // 412 ss到期
    case ErrorStatus['8000x04']:
      errorInfo = {
        ...errorInfo,
        status: ErrorStatus['8000x04'],
        code: '8000x04',
        message: <FormattedMessage id="errors.412" />,
        imageWidth: 300,
      }
      break
    // 408 连接超时
    case ErrorStatus['8000x05']:
      errorInfo = {
        ...errorInfo,
        status: ErrorStatus['8000x05'],
        code: '8000x05',
        imageWidth: 300,
        message: (
          <>
            <FormattedMessage id="errors.408" />
            &nbsp;&nbsp;
            {/* eslint-disable-next-line no-restricted-globals */}
            <span className="link__underline" onClick={() => location.reload()}>
              <FormattedMessage id="actions.retry" />
            </span>
          </>
        ),
      }
      break
    /* ss账号错误 */
    case ErrorStatus['8000x07']:
      errorInfo = {
        ...errorInfo,
        status: ErrorStatus['8000x07'],
        code: '8000x07',
        imageWidth: 300,
        originMessage: originMessages,
        message: <FormattedMessage id="errors.511" />,
      }
      break
    /* 图片规格出问题/改变分类 */
    case ErrorStatus['8000x08']:
      errorInfo = {
        ...errorInfo,
        status: ErrorStatus['8000x08'],
        code: '8000x08',
        imageWidth: 300,
        originMessage: originMessages,
        message: <FormattedMessage id="errors.512" />,
      }
      break
    // 500 服务器错误 以及未知错误
    default:
      errorInfo = {
        ...errorInfo,
        status: ErrorStatus['8000x06'],
        code: '8000x06',
        message: <FormattedMessage id="errors.500" />,
        imageWidth: 300,
        originMessage: originMessages,
      }
      break
  }

  function ErrorLayout({ status, code, message: messages, imageWidth, originMessage }: ErrorInfo) {
    const Content = () => (
      <Flex direction="column" valign="center" halign="center" as="main" style={{ height: '100%' }}>
        <Icon
          glyph={`${status === ErrorStatus['8000x01'] ? ErrorStatus['8000x01'] : ErrorStatus['8000x02']}`}
          size={imageWidth}
          cursor="default"
        />
        <Text color="stone">{messages}</Text>
        {originMessage ? (
          <Text mt={spacing.sm} color="stone" size={12} style={{ opacity: 0.6 }}>
            {originMessage}
          </Text>
        ) : null}
        <Text mb={spacing.lg} color="stone" size={12} style={{ opacity: 0.3 }}>
          code: {code}
        </Text>
        <Link href={href}>
          <GoHome>
            <FormattedMessage id="actions.back-to-home" />
          </GoHome>
        </Link>
      </Flex>
    )
    return noDrawer ? (
      <NoDrawLayout>
        <Content />
      </NoDrawLayout>
    ) : (
      <Drawer>
        <Content />
      </Drawer>
    )
  }

  return <ErrorLayout {...errorInfo} />
}

Error.getInitialProps = function ({ res, err }) {
  const statusCode = res ? res.statusCode : err ? err.statusCode : null
  return { statusCode }
}
