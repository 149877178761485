import { getEditorialChannels } from 'api/search'

// 获取编辑图频道信息
export class EditorialChannelsStore {
  static channels = new Map()

  static getChannelsSync(id: number) {
    return this.channels.get(id)
  }

  static async getChannels({ id, headers }: { id?: number; headers?: PLUS.Headers }) {
    const channelData = this.channels.get(id)

    if (channelData) {
      return Promise.resolve(channelData)
    }

    const { result, message, data } = await getEditorialChannels({
      parentId: id,
      headers,
    })

    if (result) {
      this.channels.set(id, data)

      return Promise.resolve(data)
    }

    return Promise.reject(new Error(message))
  }
}
