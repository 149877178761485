import getConfig from 'next/config'
import * as Sentry from '@sentry/browser'
import packageJson from '../package.json'

const { publicRuntimeConfig } = getConfig()
const ignoreBrowserNames = ['Android', 'iPhone', 'Maxthon', 'PhantomJS']
const dsn = publicRuntimeConfig.SENTRY_DSN

// sentry 错误收集
const configSentry = (currentUser: PLUS.User): void => {
  if (!dsn) {
    return
  }

  Sentry.init({
    release: packageJson.version,
    dsn,
    environment: `browser-${process.env.ENV}`,
    blacklistUrls: [/www.sobot.com/, /assets.giocdn.com/, /growingio.com/, /hm.baidu.com/],
    ignoreErrors: [
      '$',
      'jQuery',
      'origin message',
      'Network request failed',
      'Loading chunk',
      '这个系统不支持该功能。',
      "Can't find variable: webkit",
      "Can't find variable: $",
      "Can't find variable: objectInnerText",
      '内存不足',
      'out of memory',
      'DOM Exception 18',
      'The operation is insecure',
      '[object Event]',
      '[object FileError]',
      '[object DOMError]',
      '[object Object]',
      '拒绝访问。',
      '请求超时。',
      'Maximum call stack size exceeded',
      'Non-Error promise rejection captured',
      'UploadError',
      'AbortError',
      'timeout of 8000ms exceeded',
      /^Network Error$/,
      /^Request aborted$/,
      /^Non-Error promise rejection captured/,
      'undefined', // 未知错误
    ],
    tracesSampleRate: 1.0,
    autoSessionTracking: false,
    beforeSend(event: any) {
      const userAgent = event.request.headers?.['User-Agent']

      if (ignoreBrowserNames.some((name) => userAgent.indexOf(name) > -1)) {
        return null
      }

      return event
    },
  })

  if (currentUser) {
    const { status, id, ...rest } = currentUser
    Sentry.configureScope((scope) => {
      scope.setTag('user_status', String(status))
      scope.setUser({ id: String(id), ...rest })
    })
  }
}

export default configSentry
