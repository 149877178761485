// import getConfig from 'next/config'
import cookies from 'js-cookie'
import { SENSITIVE_WORDS } from 'shared/constants'
import { canUseDOM, saveSearchId, searchStatic } from 'helpers/util'
import { Platform, SearchStatisticsType } from 'helpers/enums'
import API from './index'
import {
  API_IMAGE_SEARCH,
  API_FONT_SEARCH,
  API_FONT_SEARCH_PREVIEW,
  API_IMAGE_SEARCH_SIMILAR,
  API_IMAGE_CATEGORIES,
  API_IMAGE_TOPIC_SEARCH,
  API_EDITORIAL_SEARCH_IMAGE,
  API_EDITORIAL_SEARCH_GROUP,
  API_EDITORIAL_CATEGORIES,
  API_IMAGE_INTELLIGENT_SEARCH,
  IP_SEARCH,
  API_IMAGE_RECOMMEND,
} from './endpoints'

const defaultData = {
  current_page: 1,
  last_page: 1,
  total: 0,
  data: [],
}

let cancel

/* pv uv 统计 ，仅限客户端调用
 * @param data
 * https://wiki.zcool.cn/pages/viewpage.action?pageId=10322953
 */
export function statistics(
  keyword: string,
  currentUser: PLUS.User,
  data?: any,
  params?: {
    log_type?: undefined | 'click' | 'search'
    search_type?: SearchStatisticsType
    resource_id?: string | number
    search_id?: string
    uid?: number
    current_page?: number
    ab?: string
    ext_info?: any
  }
) {
  const searchType = params && params.search_type

  const ignoreTypes = [SearchStatisticsType.Music, SearchStatisticsType.Font]
  if (ignoreTypes.includes(searchType)) {
    return {}
  }

  // const logType = params && params.log_type
  // if (searchType === 'video' && logType === 'search') {
  //   return {}
  // }

  const deviceId = cookies.get('_hid')

  if (!params) {
    // eslint-disable-next-line no-param-reassign
    params = {}
  }

  // eslint-disable-next-line no-param-reassign
  params.uid = currentUser ? currentUser.id : 0

  return {
    keyword,
    data,
    deviceId,
  }
}

/**
 * 搜索结果页---创意图片
 */
export function getCreativeImages({
  keyword,
  options,
  headers,
  currentUser,
  needStatistics = true,
  page = 1,
  perPage = 100,
}: {
  page?: number
  perPage?: number
  keyword?: string
  needStatistics?: boolean
  currentUser?: PLUS.User
  options?: PLUS.SearchOption
  headers?: PLUS.Headers
}) {
  const target = SENSITIVE_WORDS.find((word) => word.value.test(keyword))
  // 命中了敏感词
  if (target) {
    return Promise.resolve({
      data: {
        images: { ...defaultData, message: target.warning },
      },
    })
  }

  searchStatic(Platform.Image, keyword)

  return API.get(API_IMAGE_SEARCH, {
    params: {
      page,
      per_page: perPage,
      keyword,
      ...options,
    },
    headers,
    // useCache: true,
  })
    .then((res: any) => {
      // 统计
      if (res.result && needStatistics) {
        if (res.data.statistics_data && canUseDOM()) {
          statistics(keyword, currentUser, res.data.statistics_data, {
            log_type: 'search',
            search_type: SearchStatisticsType.Creative,
            search_id: res.data.searchid,
          })
        }
      }
      return res
    })
    .then(saveSearchId)
    .catch((err) => {
      console.error('error in API_IMAGE_SEARCH ', err)
      return {
        data: {
          images: defaultData,
        },
      }
    })
}

/**
 * 搜索结果页---编辑图片单图
 */
export function getEditorialImages({
  keyword,
  options,
  headers,
  page = 1,
  perPage = 100,
}: {
  page?: number
  perPage?: number
  keyword?: string
  options?: PLUS.EditorialSearchOption
  headers?: PLUS.Headers
}) {
  const target = SENSITIVE_WORDS.find((word) => word.value.test(keyword))
  // 命中了敏感词
  if (target) {
    return Promise.resolve({
      data: {
        images: { ...defaultData, message: target.warning },
      },
    })
  }

  return API.get(API_EDITORIAL_SEARCH_IMAGE, {
    params: {
      page,
      per_page: perPage,
      keyword,
      ...options,
    },
    headers,
  }).catch((err) => {
    console.error('error in API_EDITORIAL_SEARCH_IMAGE ', err)
    return {
      data: defaultData,
    }
  })
}

/**
 * 搜索结果页---编辑图片组图
 */
export function getEditorialGroups({
  keyword,
  options,
  headers,
  page = 1,
  perPage = 100,
}: {
  page?: number
  perPage?: number
  keyword?: string
  options?: PLUS.EditorialSearchOption
  headers?: PLUS.Headers
}) {
  const target = SENSITIVE_WORDS.find((word) => word.value.test(keyword))
  // 命中了敏感词
  if (target) {
    return Promise.resolve({
      data: {
        ...defaultData,
        message: target.warning,
      },
    })
  }
  return API.get(API_EDITORIAL_SEARCH_GROUP, {
    params: {
      page,
      per_page: perPage,
      keyword,
      ...options,
    },
    headers,
  }).catch((err) => {
    console.error('error in API_EDITORIAL_SEARCH_GROUP ', err)
    return {
      data: defaultData,
    }
  })
}

/**
 * 搜索结果页---字体，update perPage = 10
 */
export function getFonts({
  keyword,
  options,
  headers,
  page = 1,
  perPage = 10,
}: {
  page?: number
  perPage?: number
  keyword?: string
  options?: PLUS.FontSearchOption
  headers?: PLUS.Headers
}) {
  searchStatic(Platform.Font, keyword)

  return API.get(API_FONT_SEARCH, {
    params: {
      page,
      per_page: perPage,
      keyword,
      ...options,
    },
    headers,
  })
    .then(saveSearchId)
    .catch((err) => {
      console.error('error in API_FONT_SEARCH ', err)
      return {
        data: defaultData,
        result: false,
        status: -1,
        message: '',
      }
    })
}

/**
 * 搜索结果页---Ip
 */
export function getIps({
  q,
  headers,
  page = 1,
  perPage = 50,
}: {
  page?: number
  perPage?: number
  q?: string
  headers?: PLUS.Headers
}) {
  searchStatic(Platform.Ip, q)

  return API.get(IP_SEARCH, {
    params: {
      page,
      per_page: perPage,
      keyword: q,
    },
    headers,
  })
    .then(saveSearchId)
    .catch((err) => {
      console.error('error in API_IP_SEARCH ', err)
      return {
        data: defaultData,
        result: false,
        status: -1,
        message: '',
      }
    })
}

/**
 * 搜索结果页--预览-字体，update perPage = 10
 */
export function getFontsPre({
  ids,
  txt,
  page = 1,
  perPage = 10,
  headers,
}: {
  ids?: string // 字体id
  txt?: string // 预览显示的文字
  page?: number
  perPage?: number
  headers?: PLUS.Headers
}) {
  return API.get(API_FONT_SEARCH_PREVIEW, {
    params: {
      font_ids: ids,
      text: txt,
      page,
      per_page: perPage,
    },
    headers,
  }).catch((err) => {
    console.error('error in API_FONT_SEARCH_PREVIEW ', err)
    return {
      data: defaultData,
      result: false,
      status: -1,
      message: '',
    }
  })
}

/**
 * 以图搜图
 * @param param0
 */
export function getSimilarByImage({
  file,
  page = 1,
  perPage = 100,
  headers,
}: {
  file: string
  page?: number
  perPage?: number
  headers?: PLUS.Headers
}) {
  if (!file) {
    return Promise.resolve({
      result: false,
      data: null,
      message: 'file 不能为空',
    })
  }

  return API.get<{ result: boolean; data: any; message: string }>(API_IMAGE_SEARCH_SIMILAR, {
    params: {
      file: decodeURIComponent(file),
      page,
      per_page: perPage,
    },
    headers,
  })
}

/**
 * 图片分类
 */
export function getImagesCategories() {
  return API.get(API_IMAGE_CATEGORIES).catch((err) => {
    console.error('error in API_IMAGE_CATEGORIES ', err)
    return {
      data: defaultData,
    }
  })
}

/**
 * 相关图册
 */
export function getCreativeAtlas({
  keyword,
  headers,
  page = null,
  perPage = 100,
}: {
  page?: number | null
  perPage?: number
  keyword?: string
  headers?: PLUS.Headers
}) {
  if (typeof keyword !== 'string') {
    return Promise.resolve({})
  }

  return API.get(API_IMAGE_TOPIC_SEARCH, {
    params: {
      page,
      per_page: perPage,
      keyword,
    },
    headers,
    // useCache: true,
  }).catch((err) => {
    console.error('error in API_IMAGE_TOPIC_SEARCH ', err)
    return {
      data: defaultData,
    }
  })
}

/**
 * 编辑图片频道
 */
export function getEditorialChannels({ parentId = 0, headers }: { parentId?: number; headers?: PLUS.Headers }) {
  return API.get(API_EDITORIAL_CATEGORIES, {
    params: {
      parent_id: parentId,
    },
    headers,
  }).catch((err) => {
    console.error('error in API_EDITORIAL_CATEGORIES ', err)
    return { result: false, message: 'error', data: null }
  })
}

/**
 * 图片智能搜索提示
 */

export function getImageSearchTips({ keyword, headers }: { keyword?: string; headers?: PLUS.Headers }) {
  if (cancel) {
    cancel('plus cancel')
  }
  return API.get(API_IMAGE_INTELLIGENT_SEARCH, {
    params: {
      keyword,
    },
    headers,
    cancelToken: new API.CancelToken(function executor(c: any) {
      cancel = c
    }),
  }).catch((err) => {
    console.error('error in API_IMAGE_INTELLIGENT_SEARCH', err)
    return { result: false, message: 'error', data: null }
  })
}

/**
 * 图片搜索联想词提示
 */

export function getImageAssociateWord({ keyword, headers }: { keyword?: string; headers?: PLUS.Headers }) {
  return API.get(API_IMAGE_RECOMMEND, {
    params: {
      keyword,
    },
    headers,
  }).catch((err) => {
    console.error('error in API_IMAGE_RECOMMEND', err)
    return { result: false, message: 'error', data: null }
  })
}
