import * as React from 'react'
import styled, { css } from 'styled-components'
import { palette, font, spacing } from '@zcool/theme'
import { ListItem } from '@zcool/list'

export const LeftListItem = styled(ListItem)`
  width: 120px;
  height: 48px;
  background-color: ${palette.white};
  padding: 14px 16px;
  color: ${palette.black3};
  font-size: ${font.size.sm}px;
  text-align: left !important;

  &:hover {
    background-color: ${palette.pearl};
  }

  &[data-disabled='true'] {
    cursor: not-allowed;
    opacity: 0.6;
  }
`

export const AutoWidthLeftListItem = styled(LeftListItem)`
  width: auto;
  min-width: 120px;
`

export const Transition = {
  hover: {
    on: 'all 0.2s ease-in',
    off: 'all 0.2s ease-out',
  },
  reaction: {
    on: 'all 0.3s ease-in',
    off: 'all 0.3s ease-out',
  },
  dropdown: {
    off: 'all 0.35s ease-out',
  },
}

export const Container1200 = styled.div`
  position: relative;
  margin: 0 auto;
  width: 1200px;
`

export const TextTooltip = styled.div`
  width: 342px;
  padding: 8px;
  line-height: 21px;

  p {
    color: ${palette.white};

    & + p {
      margin-top: 8px;
    }
  }
`

export const Truncate = css`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

const REG = /^[mp][trblxy]?$/
const properties = {
  m: 'margin',
  p: 'padding',
}
const directions = {
  t: 'top',
  r: 'right',
  b: 'bottom',
  l: 'left',
  x: ['left', 'right'],
  y: ['top', 'bottom'],
}

const getProperties = (key) => {
  const [a, b] = key.split('')
  const property = properties[a]
  const direction = directions[b] || ''
  return Array.isArray(direction) ? direction.map((dir) => `${property}-${dir}`) : [`${property}-${direction}`]
}

export const space = (props) => {
  return Object.keys(props)
    .filter((key) => REG.test(key))
    .sort()
    .map((key) => getProperties(key).map((p) => `${p}: ${props[key]}px;`))
}

export interface Spacing {
  mt?: number
  mr?: number
  mb?: number
  ml?: number
  px?: number
  py?: number
  mx?: number
  my?: number
}

export const Box = styled.section<
  Spacing & {
    br?: number
    background?: string
    align?: string
  }
>`
  position: relative;
  background: ${({ background }) => background || palette.white};
  text-align: ${({ align }) => align || 'left'};
  border-radius: ${({ br }) => br || 0}px;
  ${space};
`

export const Text = styled.span<
  {
    className?: string
    title?: string
    style?: React.CSSProperties
    width?: number | string
    color?: string
    hoverColor?: string
    size?: number | string
    block?: boolean
    lineHeight?: number | string
    letterSpacing?: string
    weight?: string
    truncate?: number | string
    onClick?: any
    align?: string
    children: React.ReactNode
    decoration?: string
    cursor?: string
    borderBottom?: string
  } & Spacing
>`
  width: ${(props) => (props.width ? `${props.width}px` : '')};
  color: ${(props) => palette[props.color] || props.color};
  border-bottom: ${(props) => props.borderBottom};
  font-size: ${(props) => props.size || 14}px;
  display: ${(props) => (props.block ? 'block' : 'inline-block')};
  line-height: ${(props) => props.lineHeight};
  font-weight: ${(props) => font.weight[props.weight]};
  text-align: ${(props) => props.align};
  text-decoration: ${(props) => props.decoration};
  letter-spacing: ${(props) => props.letterSpacing || 'normal'};
  cursor: ${(props) => props.cursor};
  ${space};
  ${(props) =>
    props.truncate
      ? css`
          max-width: ${props.truncate};
          ${Truncate};
        `
      : null}

  &[data-button] {
    cursor: pointer;
  }

  & > [data-icon] {
    margin: 0 ${spacing.xs}px;
  }

  :hover {
    color: ${(props) => palette[props.hoverColor] || props.hoverColor};
  }
`

export const FeatureTag = styled.span<{
  size?: number
  lineHeight?: number
}>`
  color: ${palette.white};
  background-image: linear-gradient(90deg, #ff3135 14%, #f91962 90%);
  border-radius: 2px 0 2px 2px;
  padding: 0 8px;
  font-size: ${(props) => props.size || 12}px;
  line-height: ${(props) => props.lineHeight || 18}px;
`
