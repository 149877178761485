import API from './index'
import { getDownloadTimeoutInterval } from './util'
import { API_DOWNLOAD_IMAGE, API_COMP_PURCHASE, API_EDITORIAL_DOWNLOAD_SAMPLE } from './endpoints'

/**
 * 下载图片
 * @param id 购买id
 */
export function downloadImageByPurchaseId(id: number, headers: PLUS.Headers, purchaseType: number = 1) {
  return API.get(`${API_DOWNLOAD_IMAGE}/${id}`, { headers, params: { purchase_type: purchaseType } }).catch((err) => {
    console.error('获取图片详情失败', err)
    return err
  })
}

/**
 * 小样下载记录
 */
export function getSampleDownloads({
  options,
  headers,
  page = 1,
  perPage = 100,
}: {
  page?: number
  perPage?: number
  options?: PLUS.PurchasedSearchOption
  headers?: PLUS.Headers
}) {
  return API.get(API_COMP_PURCHASE, {
    params: {
      page,
      per_page: perPage,
      ...options,
    },
    headers,
  }).catch((err) => {
    console.error('获取小样记录失败：', err)
    return err
  })
}

/**
 * 编辑图批量下载小样
 * @param ids 	素材ID
 * @param permissionId 权限ID
 * @param groupId 图组id
 * @param toDownload 返回部分下架图片后，想继续下载传true
 */
export function batchDownloadSample({
  ids,
  permissionId,
  groupId,
  toDownload,
}: {
  ids: Array<number>
  permissionId?: number
  groupId?: number | string
  toDownload?: boolean
}) {
  return API.post(
    API_EDITORIAL_DOWNLOAD_SAMPLE,
    {
      image_ids: ids.join(','),
      permission_id: permissionId,
      group_id: groupId,
      to_download: toDownload,
    },
    {
      timeout: getDownloadTimeoutInterval(),
    }
  ).catch((err) => {
    console.error('error in batch download sample', err)
    return err
  })
}
