import API from './index'
import {
  API_LOGOUT,
  API_THIRD_LOGIN_LIST,
  API_CUSTOM_SETTINGS,
  API_LOGIN_TEMPORARY,
  API_GET_MEITU_TOKEN,
} from './endpoints'

/**
 * 退出登录
 */
export function logout() {
  return API.get(API_LOGOUT, {
    baseURL: '/',
  })
}

/**
 * @description 获取三方登录列表
 * @param {{ domain: string }} params 当前域名传给后端 后端判断返回可以使用的第三方登录方式
 * @return { name: string; icon_url: string; url: string; desc: string }[] 第三方登录图标数组
 */
export function getThirdLoginList(params: { url: string }) {
  return API.get(API_THIRD_LOGIN_LIST, { params }).catch(() => ({ result: true, data: [] }))
}

/**
 * 专项配置
 */
export function getLoginSettings(domain: string) {
  return API.post(API_CUSTOM_SETTINGS, {
    domain,
  }).catch(() => ({ result: true }))
}

/**
 * 中间页登录信息
 */
export function getLoginParam(params: { code: string }) {
  return API.post(API_LOGIN_TEMPORARY, params).catch(() => ({ result: true, data: [] }))
}

export function getMeituToken() {
  return API.post(API_GET_MEITU_TOKEN)
}
