import React from 'react'
import { FormattedMessage } from 'react-intl'
import { ContentType } from 'helpers/enums'
import { toast } from 'shared/actions'
import { deleteMaterialFromCollection, addMaterialIntoCollection } from 'api/collection'

export const cancelCollected = async (id: string, contentType: ContentType, callback: () => void) => {
  const { result, message } = await deleteMaterialFromCollection(id, contentType)
  if (result) {
    callback()
    toast(<FormattedMessage id="toast.removed-from-favorites" />)
  } else {
    toast(message)
  }
}

export const addCollected = async ({
  id,
  contentType,
  collectionId,
  collectionName,
  callback,
}: {
  id: string
  contentType: ContentType
  collectionId?: number
  collectionName: string
  callback?: () => void
}) => {
  const { result, message } = await addMaterialIntoCollection({
    id,
    contentType,
    collectionId,
  })

  if (result) {
    toast(<FormattedMessage id="toast.added-to-lightbox" values={{ name: collectionName }} />)
    callback()
  } else {
    toast(message)
  }
}
