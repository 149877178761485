import React from 'react'
import styled from 'styled-components'
import Flex from '@zcool/flex'
import Icon from 'components/ui/icon'

export type Size = 'default' | 'large'

export const StyledMusicCover = styled(Flex)<{ size: Size }>`
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;

  .music-cover-title {
    white-space: pre-wrap;
    text-align: center;
    font-size: ${(props) => (props.size === 'large' ? 16 : 12)}px;
    max-width: 100%;
    word-break: break-all;
    padding: 0 10px;
  }

  .normal-title {
    position: absolute;
    color: #fff;
    margin-top: ${(props) => (props.size === 'large' ? '30%' : '22px')};
  }

  > img {
    height: 80%;
    width: auto;

    &.normal-img {
      width: 100%;
      height: auto;
    }
  }
`

const NormalMusicCoverStyle = styled.div`
  background: linear-gradient(#e6c1a1, #b38c6b);
  width: 100%;
  height: 100%;
  padding-top: 14px;
`

function NormalMusicCover() {
  return (
    <NormalMusicCoverStyle>
      <Icon glyph="music-cover" />
    </NormalMusicCoverStyle>
  )
}

const CoverTitlePreview = ({
  title,
  preview,
  size,
  id,
  dam,
}: {
  title: string
  id?: string
  size?: Size
  dam?: boolean
  preview?: string
}) => {
  let child = (
    <StyledMusicCover direction="column" valign="center" halign="center" size={size}>
      {preview ? <img className={dam ? '' : 'normal-img'} src={preview} /> : <NormalMusicCover />}
      <div className={`music-cover-title ${!dam ? 'normal-title' : ''}`}>
        <span className="two-line-break">{title}</span>
      </div>
    </StyledMusicCover>
  )

  if (id) {
    let link = '/music/show'
    if (dam) {
      link = '/dam/show'
    }
    child = (
      <a className="center" href={`${link}/${id}`} target="_blank" rel="noreferrer">
        {child}
      </a>
    )
  }

  return child
}

export default CoverTitlePreview
