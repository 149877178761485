import React, { ReactElement } from 'react'
import { noop } from 'helpers/util'

type DraggableHandler = (ev: MouseEvent, data: DraggableData) => void

interface Props {
  onDrag?: DraggableHandler
  onStart?: DraggableHandler
  onStop?: DraggableHandler
  children: ReactElement<any>
}

export type DraggableData = {
  x: number
  y: number
  deltaX: number
  deltaY: number
}

draggable.defaultProps = {
  onDrag: noop,
  onStart: noop,
  onStop: noop,
}

export default function draggable({ children, onStart, onDrag, onStop }: Props) {
  let initX = 0
  let initY = 0
  let lastX = 0
  let lastY = 0

  const addSelectNone = () => (document.body.style['user-select'] = 'none')
  const removeSelectNone = () => (document.body.style['user-select'] = 'auto')

  const handleMouseMove = (ev: MouseEvent) => {
    const data: DraggableData = {
      x: ev.clientX - initX,
      y: ev.clientY - initY,
      deltaX: ev.clientX - lastX,
      deltaY: ev.clientY - lastY,
    }

    onDrag(ev, data)

    lastX = ev.clientX
    lastY = ev.clientY
  }

  const handleMouseUp = (ev: MouseEvent) => {
    const data: DraggableData = {
      x: ev.clientX - initX,
      y: ev.clientY - initY,
      deltaX: ev.clientX - lastX,
      deltaY: ev.clientY - lastY,
    }

    removeSelectNone()
    onStop(ev, data)
    document.removeEventListener('mousemove', handleMouseMove)
    document.removeEventListener('mouseup', handleMouseUp)
  }

  const handleMouseDown = (ev: MouseEvent) => {
    initX = ev.clientX
    lastX = ev.clientX
    initY = ev.clientY
    lastY = ev.clientY

    const data: DraggableData = {
      x: 0,
      y: 0,
      deltaX: 0,
      deltaY: 0,
    }
    addSelectNone()
    onStart(ev, data)

    document.addEventListener('mousemove', handleMouseMove)
    document.addEventListener('mouseup', handleMouseUp)
  }

  return React.cloneElement(React.Children.only(children), {
    onMouseDown: handleMouseDown,
  })
}
