import { removeEmpty } from 'helpers/util'
import API from './index'
import {
  API_ACCOUNT_MEMBERS,
  API_ACCOUNT_PERMISSION_DETAIL,
  API_ACCOUNT_COMBO_PERMISSION_LIST,
  API_ACCOUNT_MEMBERS_EXPORT,
  API_ACCOUNT_SUBSIDIARY_DETAIL,
  API_ACCOUNT_SUBSIDIARY_CREATE,
  API_ACCOUNT_SUBSIDIARY_ACTIVE,
  API_ACCOUNT_SUBSIDIARY_DELETE,
  API_ACCOUNT_SUBSIDIARY_FREEZE,
  API_ACCOUNT_SUBSIDIARY_MODIFY,
  API_ACCOUNT_SUBSIDIARY_INVITE,
  API_ACCOUNT_SUBSIDIARY_RECOVER,
  API_ACCOUNT_MEMBER_PERMISSIONS,
  API_ACCOUNT_UNDER_MEMBER,
  API_ACCOUNT_UPDATE_PARENT,
  API_ACCOUNT_BATCH_UPDATE_PARENT,
  API_ACCOUNT_DAM_PERMISSIONS,
  API_ACCOUNT_PERMISSION_BATCH_OPEN,
  API_ACCOUNT_COMBO_PERMISSION_BATCH_OPEN,
  API_ACCOUNT_PERMISSION_BATCH_CLOSE,
  API_ACCOUNT_COMBO_PERMISSION_BATCH_CLOSE,
  API_ACCOUNT_PERMISSION_BATCH_SET_LIMIT,
  API_ACCOUNT_COMBO_PERMISSION_BATCH_SET_LIMIT,
  API_ACCOUNT_PERMISSION_BATCH_CANCEL_LIMIT,
  API_ACCOUNT_COMBO_PERMISSION_BATCH_CANCEL_LIMIT,
  API_ACCOUNT_MEMBER_BATCH_EDIT_BASIC_INFO,
  API_ACCOUNT_MEMBER_PERMISSIONS_POLLING,
} from './endpoints'
// import { getDownloadTimeoutInterval } from './util'

/**
 * 成员列表
 */
export function getSubsidiaries({
  name,
  email,
  subsidiary,
  department,
  position,
  memberStatus,
  orderBy,
  order,
  page,
  perPage,
  headers,
}: PLUS.SubsidiarySearchParams & { headers?: PLUS.Headers }) {
  return API.get(API_ACCOUNT_MEMBERS, {
    params: {
      name,
      sub_company_name: subsidiary,
      department,
      position,
      email,
      status: memberStatus,
      order,
      by: orderBy,
      page,
      per_page: perPage,
    },
    headers,
  })
}

/**
 * 权限明细
 */
export function getPermissionDetail({
  permissionId,
  name,
  email,
  subsidiary,
  department,
  position,
  memberStatus,
  permissionStatus,
  limit,
  orderBy,
  order,
  page,
  perPage,
}: PLUS.SubsidiarySearchParams) {
  return API.get(API_ACCOUNT_PERMISSION_DETAIL, {
    params: {
      permission_id: permissionId,
      name,
      email,
      sub_company_name: subsidiary,
      department,
      position,
      member_status: memberStatus,
      permission_status: permissionStatus,
      limit,
      order,
      by: orderBy,
      page,
      per_page: perPage,
    },
  })
}

/**
 * plus微利套餐权限明细
 */
export function getComboPermissionList({
  permissionId,
  name,
  email,
  subsidiary,
  department,
  position,
  memberStatus,
  permissionStatus,
  limit,
  orderBy,
  order,
  page,
  perPage,
}: PLUS.SubsidiarySearchParams) {
  return API.get(API_ACCOUNT_COMBO_PERMISSION_LIST, {
    params: {
      permission_id: permissionId,
      name,
      email,
      sub_company_name: subsidiary,
      department,
      position,
      member_status: memberStatus,
      permission_status: permissionStatus,
      limit,
      order,
      by: orderBy,
      page,
      per_page: perPage,
    },
  })
}

/**
 * 子帐号详情
 * @param id 帐号id
 */
export function getSubsidiaryById(id: number, headers?: PLUS.Headers) {
  return API.get(`${API_ACCOUNT_SUBSIDIARY_DETAIL}/${id}`, {
    headers,
  })
}

/**
 * 获取成员权限
 * @param id 上级id
 */
export function getMemberPermissions(id: number, headers?: PLUS.Headers) {
  return API.get(`${API_ACCOUNT_MEMBER_PERMISSIONS}/${id}`, {
    headers,
  })
}

/**
 * 权限状态轮询
 * @param id 子成员id
 */
export function pollingMemberPermissions(id: number) {
  return API.get(`${API_ACCOUNT_MEMBER_PERMISSIONS_POLLING}/${id}`)
}

/**
 * 获取成员DAM权限
 * @param plusUserId 大客户账户ID
 */
export function getDAMPermissions({
  plusUserId,
  invitate,
  headers,
}: {
  plusUserId: number
  invitate?: boolean
  headers?: PLUS.Headers
}) {
  return API.get(`${API_ACCOUNT_DAM_PERMISSIONS}/${plusUserId}`, {
    params: {
      invitate,
    },
    headers,
  })
}

interface Permissions {
  permission_id: number
  status: number
  month_status: boolean
  month_cnt: number
  total_status: boolean
  total_cnt: number
}

export interface SubsidiaryParams {
  id?: number
  /** 批量邀请成员数组 */
  users?: Array<PLUS.Members>
  /** 父级账号id */
  parentId?: number
  /** 姓名 */
  name?: string
  /** 子公司 */
  sub_company_name?: string
  /** 部门 */
  department?: string
  /** 职位 */
  position?: string
  /** 邮箱 */
  email?: string
  /** 购买权限 */
  permissions?: Array<Permissions>
  /** AI权限 */
  aigc_permissions?: Array<Permissions>
  /** 套餐权限开关 */
  package_permission?: boolean
  /** 套餐权限列表 */
  package_permissions?: Array<Permissions>
  /** 套餐权限 */
  package_permission_data?: Array<Permissions>
  /** 海洛数字资产 */
  nodes?: Array<PLUS.ResourcePermission>
  /** 备注 */
  remark?: string
  /** 购买权限开关 */
  permission?: boolean
  /** 数字资产开关 */
  is_dam?: boolean
  /** 邀请开关 */
  invitation?: boolean
  /** 分类 */
  dam_category?: boolean
  /** 用途 */
  dam_license?: boolean
  /** 来源 */
  dam_source?: boolean

  /** 数据统计 */
  dam_capacity_statistics?: boolean

  /** media用户传 media */
  platform?: string
  /** 显示价格权限 */
  show_resource_price?: boolean
  /** 查看权限 */
  select_permission?: boolean
  /** 预算查看权限 */
  limit_cash_permission?: boolean
  /** 预算总金额 */
  limit_cashes?: number
  /** 审核权限 */
  dam_audit_resource?: boolean
}

/**
 * 添加成员
 */
export function createSubsidiary({
  users,
  parentId,
  permissions,
  nodes,
  permission,
  package_permission,
  package_permission_data,
  is_dam,
  invitation,
  dam_category,
  dam_source,
  dam_license,
  dam_capacity_statistics,
  dam_audit_resource,
  platform,
  show_resource_price,
  select_permission,
  limit_cash_permission,
  limit_cashes,
}: SubsidiaryParams) {
  return API.post(
    API_ACCOUNT_SUBSIDIARY_CREATE,
    {
      basic_data: JSON.stringify(users),
      permission_data: permissions.length > 0 ? jsonPermission(permissions) : undefined,
      node_data: nodes.length > 0 ? jsonNodes(nodes) : undefined,
      parent_id: parentId,
      permission,
      is_dam,
      invitation,
      dam_category,
      dam_source,
      dam_license,
      dam_capacity_statistics,
      dam_audit_resource,
      platform,
      show_resource_price,
      select_permission,
      package_permission,
      package_permission_data: package_permission_data?.length ? jsonPermission(package_permission_data) : undefined,
      limit_cash_permission,
      limit_cashes,
    },
    { timeout: 60 * 1000 }
  )
}

function jsonPermission(permissions: Array<Permissions>) {
  return JSON.stringify(
    permissions.map((p) => ({
      status: p.status,
      permission_id: p.permission_id,
      month_status: p.month_status,
      month_cnt: p.month_cnt,
      total_status: p.total_status,
      total_cnt: p.total_cnt,
    }))
  )
}

export function jsonNodes(nodes: Array<PLUS.ResourcePermission>) {
  return JSON.stringify(
    nodes.map((n) => ({
      node_id: n.node_id,
      visit: n.visit,
      push: n.push,
      download: n.download,
      delete: n.delete,
    }))
  )
}

/**
 * 修改成员
 */
export function modifySubsidiary({
  id,
  name,
  sub_company_name,
  department,
  position,
  remark,
  permission,
  is_dam,
  invitation,
  dam_category,
  dam_license,
  dam_source,
  permissions,
  show_resource_price,
  select_permission,
  dam_capacity_statistics,
  dam_audit_resource,
  nodes,
  package_permission,
  package_permissions,
  limit_cash_permission,
  limit_cashes,
}: SubsidiaryParams) {
  return API.post(
    `${API_ACCOUNT_SUBSIDIARY_MODIFY}/${id}`,
    {
      name,
      sub_company_name,
      department,
      position,
      remark,
      permission,
      is_dam,
      invitation,
      dam_category,
      dam_license,
      dam_source,
      dam_capacity_statistics,
      dam_audit_resource,
      show_resource_price,
      select_permission,
      permission_data: permissions.length > 0 ? jsonPermission(permissions) : undefined,
      node_data: nodes.length > 0 ? jsonNodes(nodes) : undefined,
      package_permission,
      package_permission_data: package_permissions.length > 0 ? jsonPermission(package_permissions) : undefined,
      limit_cash_permission,
      limit_cashes,
    },
    // { timeout: getDownloadTimeoutInterval() }
    { timeout: 2 * 60 * 1000 }
  )
}

/**
 * 删除成员
 * @param id 帐号id
 */
export function deleteSubsidiary(id: number) {
  return API.post(`${API_ACCOUNT_SUBSIDIARY_DELETE}/${id}`)
}

/**
 * 冻结子帐号
 * @param id 帐号id
 */
export function freezeSubsidiary(id: number) {
  return API.post(`${API_ACCOUNT_SUBSIDIARY_FREEZE}/${id}`)
}

/**
 * 恢复成员
 * @param id 帐号id
 */
export function recoverSubsidiary(id: number) {
  return API.post(`${API_ACCOUNT_SUBSIDIARY_RECOVER}/${id}`)
}

/**
 * 通知登录
 * @param id 帐号id
 */
export function inviteSubsidiary(id: number) {
  return API.post(`${API_ACCOUNT_SUBSIDIARY_INVITE}/${id}`)
}

/**
 * 激活子帐号
 * @param key 加密key
 */
export function activeSubsidiary(key: number, headers: PLUS.Headers) {
  return API.post(
    `${API_ACCOUNT_SUBSIDIARY_ACTIVE}`,
    { key },
    {
      headers,
    }
  )
}

export function getUnderMembers(id: number, headers?: PLUS.Headers) {
  return API.get(API_ACCOUNT_UNDER_MEMBER, {
    headers,
    params: {
      id,
    },
  })
}

// 修改上级
export function updateParent(id: number, parentId: number, headers?: PLUS.Headers) {
  return API.post(
    `${API_ACCOUNT_UPDATE_PARENT}/${id}`,
    {
      parent_id: parentId,
    },
    { headers }
  )
}

// 批量修改上级
export function batchUpdateParent(ids: number[], parentId: number, headers?: PLUS.Headers) {
  return API.post(
    `${API_ACCOUNT_BATCH_UPDATE_PARENT}`,
    {
      ids: ids.join(','),
      parent_id: parentId,
    },
    { headers }
  )
}

// 成员列表导出
export function exportSubsidiaryList({
  name,
  department,
  position,
  email,
  status,
}: {
  name?: string
  department?: string
  position?: string
  email?: string
  status?: string
}) {
  return API.get(API_ACCOUNT_MEMBERS_EXPORT, {
    params: {
      name,
      department,
      position,
      email,
      status,
    },
  })
}

/**
 * 批量开启权限
 * @param ids 帐号ids
 * @param force 是否同步开启上级权限
 */
export function batchOpenPermission(
  ids: number[],
  plusPermissionId: number,
  force?: boolean,
  isCombo: boolean = false // 是否为批量开启套餐权限
) {
  return API.post(isCombo ? API_ACCOUNT_COMBO_PERMISSION_BATCH_OPEN : API_ACCOUNT_PERMISSION_BATCH_OPEN, {
    ids,
    plus_permission_id: plusPermissionId,
    force,
  })
}

/**
 * 批量关闭权限
 * @param ids 帐号ids
 */
export function batchClosePermission(ids: number[], plusPermissionId: number, _, isCombo: boolean = false) {
  return API.post(isCombo ? API_ACCOUNT_COMBO_PERMISSION_BATCH_CLOSE : API_ACCOUNT_PERMISSION_BATCH_CLOSE, {
    ids,
    plus_permission_id: plusPermissionId,
  })
}

/**
 * 批量设置数量限制
 * @param ids 帐号ids
 * @param monthCnt 月限制
 * @param totalCnt 总限制
 * @param continue 是否继续执行
 */
export function batchSetLimit({
  ids,
  plusPermissionId,
  monthCnt,
  totalCnt,
  isContinue,
  isCombo = false,
}: {
  ids: number[]
  plusPermissionId?: number
  monthCnt?: number
  totalCnt?: number
  isContinue?: boolean
  isCombo: boolean
}) {
  const params = removeEmpty({
    ids,
    plus_permission_id: plusPermissionId,
    month_cnt: monthCnt,
    limit_cnt: totalCnt,
    continue: isContinue,
  })
  return API.post(
    isCombo ? API_ACCOUNT_COMBO_PERMISSION_BATCH_SET_LIMIT : API_ACCOUNT_PERMISSION_BATCH_SET_LIMIT,
    params
  )
}

/**
 * 批量取消数量限制
 * @param ids 帐号ids
 */
export function batchCancelLimit(ids: number[], plusPermissionId: number, _, isCombo: boolean) {
  return API.post(
    isCombo ? API_ACCOUNT_COMBO_PERMISSION_BATCH_CANCEL_LIMIT : API_ACCOUNT_PERMISSION_BATCH_CANCEL_LIMIT,
    {
      ids,
      plus_permission_id: plusPermissionId,
    }
  )
}

/**
 * 批量修改基本信息
 *
 * @param ids 帐号ids
 * @param data 修改项键值对
 */
export function batchEditBasicInfo(ids: number[], data: { [key: string]: string }) {
  return API.post(API_ACCOUNT_MEMBER_BATCH_EDIT_BASIC_INFO, {
    plus_user_ids: ids.join(','),
    edit_data: JSON.stringify(data),
  })
}
