import React from 'react'
import styled, { keyframes } from 'styled-components'
import { palette } from '@zcool/theme'

interface DanceProps {
  playState?: 'running' | 'paused'
  bgColor?: string
  mr?: number
}

const dance = keyframes`
  from {
    height: 4px;
  }

  to {
    height: 20px;
  }
`

const Bar = styled.span<DanceProps>`
  width: 12px;
  min-width: 12px;
  height: 12px;
  margin-right: ${(props) => props.mr}px;
  position: relative;
  overflow: hidden;

  > .bar {
    height: 2px;
    min-width: 2px;
    background-color: ${(props) => props.bgColor};
    animation: none;
    display: inline-block;
    animation-name: ${dance};
    animation-play-state: ${(props) => props.playState};
    animation-direction: alternate;
    animation-timing-function: linear;
    animation-iteration-count: infinite;

    &.one {
      animation-duration: 425ms;
      animation-delay: 250ms;
      margin-right: 3px;
    }

    &.two {
      animation-duration: 275ms;
      animation-delay: 715ms;
      margin-right: 3px;
    }

    &.three {
      animation-duration: 350ms;
      animation-delay: 475ms;
    }
  }
`

const DanceBar: React.FC<DanceProps> = ({ playState, bgColor, mr }) =>
  React.useMemo(
    () => (
      <Bar playState={playState} bgColor={bgColor} mr={mr}>
        <span className="bar one" />
        <span className="bar two" />
        <span className="bar three" />
      </Bar>
    ),
    [playState]
  )

DanceBar.defaultProps = {
  playState: 'running',
  bgColor: palette.primary,
  mr: 8,
}

export default DanceBar
