import React from 'react'
import styled from 'styled-components'
import { Text } from 'components/ui/base'

const Messages = styled.div`
  text-align: left;
`

interface Props {
  messages: Array<string>
  color: string
}

// eslint-disable-next-line react/display-name
export default React.memo(({ messages, color }: Props) => {
  return (
    <Messages>
      {messages &&
        messages.map((m, index) => (
          <Text key={`error-msg-${index}`} block={true} lineHeight={1.7} color={color} size={14}>
            {m}
          </Text>
        ))}
    </Messages>
  )
})
