import React, { useState, useRef, useEffect, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { Waypoint } from 'react-waypoint'
import Link from 'next/link'
import classNames from 'classnames'
import qs from 'qs'
import max from 'lodash.max'
import shallow from 'zustand/shallow'
import styled, { keyframes } from 'styled-components'
import Button from '@zcool/button'
import Flex from '@zcool/flex'
import Tooltip from '@zcool/tooltip'
import { palette, font, spacing } from '@zcool/theme'
import Icon from 'components/ui/icon'
import { Box, Text, Truncate, Spacing } from 'components/ui/base'
import NoResult from 'components/ui/no-result'
import Collect from 'components/ui/actions/collect'
import { AddToCart } from 'components/ui/actions/index'
import { formatSecondNumber, download, noop } from 'helpers/util'
import { ContentType, MeituAnalyzeSearchContentType } from 'helpers/enums'
import { events } from 'helpers/event-emitter'
import axios from 'axios'
import { ACTIONS } from 'shared/constants'
import { downloadMusicSampleById } from 'api/music'
import { musicStore, MusicStore, cachedWaveFormMap, cachedStatusMap } from './store'
import DanceBar from './dance-bar'
import WaveForm from './waveform'
import ComExpose from 'components/comexpose'
import { meituSearchResultsCardClick, meituSearchResultsCardShow } from 'helpers/analyze-meitu'
import Cookies from 'js-cookie'

const loading = keyframes`
  0% {
    transform: rotate(-45deg);
  }

  to {
    transform: rotate(315deg);
  }
`

const MusicListContainer = styled(Box)`
  .music-row {
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${palette.pearl};

    &.body {
      height: 72px;

      &:hover {
        background-color: #fafcfc;
      }

      .bpm,
      .duration,
      .tags {
        font-size: ${font.size.sm}px;
      }
    }

    &.head {
      height: 56px;
      font-size: ${font.size.sm}px;
      color: ${palette.pewter};
    }

    .music-col {
      padding: 0 12px;

      .music-icon {
        border: none;
        line-height: 0;
        padding: 0;
      }

      .music-tag {
        font-size: ${font.size.sm}px;
        color: ${palette.spruce};

        & + .music-tag {
          margin-left: ${spacing.xs}px;
        }
      }
    }

    .play {
      width: 80px;
      margin-right: -12px;
      text-align: center;
    }

    .info {
      width: 20%;
      max-width: 320px;
    }

    .track {
      width: 24%;
      flex: 1;
      position: relative;
    }

    .bpm,
    .duration {
      width: 7%;
    }

    .tags {
      width: 24%;
      flex: 1;
      ${Truncate};

      a + a {
        margin-left: ${spacing.sm}px;
      }
    }

    .operations {
      width: 140px;
      margin-right: 12px;
    }
  }

  .loading {
    width: 32px;
    height: 32px;

    &::before {
      content: '';
      position: absolute;
      width: 32px;
      height: 32px;
      border: 1px solid transparent;
      border-top-color: inherit;
      border-radius: 100%;
      animation: ${loading} 0.65s ease infinite;
    }
  }
`

const MusicInfoContainer = styled(Flex)`
  .music-name {
    font-size: 18px;
    font-weight: bold;
    color: ${palette.black3};
    margin-bottom: 4px;
    max-width: 100%;

    > a {
      ${Truncate};
    }
  }

  &.active.with-dance-bar > .music-name {
    color: ${palette.primary};
  }

  .music-artist-name {
    font-size: ${font.size.sm}px;

    .form-item-value {
      max-width: 200px;
      ${Truncate}
    }

    .form-item-label {
      color: ${palette.pewter};
      flex-shrink: 0;
    }

    .link__primary {
      color: ${palette.spruce};
      margin-right: 8px;
    }
  }

  .status-tag {
    width: 48px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    margin-left: ${spacing.xs}px;
    font-size: ${font.size.xs}px;
  }

  &.purchased .status-tag {
    background-color: #f8914b;
    color: ${palette.white};
  }

  &.pulled-out {
    .status-tag {
      border: 1px solid ${palette.gray};
    }

    .music-artist-name,
    .music-name,
    .status-tag {
      color: ${palette.pewter} !important;
    }
  }
`

export const MusicInfo: React.FC<{
  id: string
  index?: number
  title: string
  artist: string
  isPurchased?: boolean // 已购买
  isPulledOut?: boolean // 已下架
  showDanceBar?: boolean // 是否显示跳动的音符
  className?: string
  onClick?: () => void
  searchStatistic?: PLUS.SearchStatistic
  handleMeituAnalyzeClick?: () => void
}> = ({
  id,
  title,
  artist,
  isPulledOut,
  isPurchased,
  showDanceBar = true,
  className,
  onClick = noop,
  searchStatistic,
  handleMeituAnalyzeClick,
}) => {
  // 跳动的音符有播放和暂停两种状态
  const { file, isPlaying } = musicStore<Pick<MusicStore, 'file' | 'isPlaying'>>(
    (state) => ({
      file: state.file,
      isPlaying: state.isPlaying,
    }),
    shallow
  )

  const isActive = file && file.id === id
  const artists = artist.split(',')
  let linkHref = `/music/show?id=${id}`
  let linkAs = `/music/show/${id}`

  if (searchStatistic) {
    linkHref += `&${qs.stringify(searchStatistic)}`
    linkAs += `?${qs.stringify(searchStatistic)}`
  }

  return useMemo(
    () => (
      <MusicInfoContainer
        direction="column"
        valign="center"
        className={classNames({
          'music-col': true,
          'pulled-out': isPulledOut,
          'with-dance-bar': showDanceBar,
          purchased: isPurchased,
          active: isActive,
          [className]: !!className,
        })}
        onClick={() => {
          if (!isPulledOut) {
            onClick()
          }
        }}
      >
        <Flex className="music-name" valign="center">
          {isActive && showDanceBar && <DanceBar playState={isPlaying ? 'running' : 'paused'} />}
          {isPulledOut ? (
            title
          ) : (
            <Link href={linkHref} as={linkAs}>
              <a onClick={() => handleMeituAnalyzeClick?.()} className="link__primary" title={title}>
                {title}
              </a>
            </Link>
          )}
          {isPulledOut && <span className="status-tag">已下架</span>}
          {isPurchased && <span className="status-tag">已购买</span>}
        </Flex>
        <Flex className="music-artist-name" valign="center">
          <span className="form-item-label">
            <FormattedMessage id="page.music.info.composer" />
          </span>
          <Text className="form-item-value" title={artist}>
            {artists?.map((item, index) => (
              <Link key={item} href={`/music/contributor?id=${item}`} as={`/music/contributor/${item}`}>
                <a className="link__primary">
                  {item}
                  {index !== artists.length - 1 && ','}
                </a>
              </Link>
            ))}
          </Text>
        </Flex>
      </MusicInfoContainer>
    ),
    [id, isActive, isPlaying, isPulledOut]
  )
}

export const MusicTrack: React.FC<{
  duration?: number
  currentTime?: number
  waveformUrl: string
  type: string
  onSeekTo?: (progress: number) => void
}> = ({ duration, currentTime, type, onSeekTo, waveformUrl }) => {
  const [waveform, setWaveform] = useState([])
  const enterFlag = useRef(null)

  function getWaveform() {
    if (!enterFlag.current) {
      enterFlag.current = true

      if (!waveformUrl) {
        // use default array fallback
        setWaveform(Array(500).fill(0.2))
        return
      }

      const cachedWaveForm = cachedWaveFormMap.get(waveformUrl)
      if (cachedWaveForm) {
        setWaveform(cachedWaveForm)
      } else {
        const url = waveformUrl.split('/').slice(3).join('^')
        axios
          .get(`/api/proxy/${url}`)
          .then(({ status, data }) => {
            if (status === 200 && type) {
              let finalWaveform = data
              if (type.toLowerCase() !== 'vifine') {
                finalWaveform = normalizedWaveform(data)
              }
              setWaveform(finalWaveform)
              // 缓存该波形数据
              cachedWaveFormMap.set(waveformUrl, finalWaveform)
            }
          })
          .catch((e) => console.error(`/api/proxy/${url}`, e))
      }
    }
  }

  useEffect(() => {
    // 同步底部的播放器
    const cachedWaveForm = cachedWaveFormMap.get(waveformUrl)
    if (cachedWaveForm) {
      setWaveform(cachedWaveForm)
    }
  }, [waveformUrl])

  function normalizedWaveform(data: Array<number>) {
    const maxValue = max(data.map((i) => Math.abs(i)))
    return data.map((i) => i / maxValue)
  }

  return useMemo(
    () => (
      <Waypoint onEnter={getWaveform}>
        <div className="music-col track">
          <WaveForm progress={currentTime / duration} duration={duration} onMouseUp={onSeekTo} data={waveform} />
        </div>
      </Waypoint>
    ),
    [currentTime, waveform, waveformUrl]
  )
}

const MusicOperationContainer = styled(Flex)`
  .tooltip__reference {
    line-height: 0;
  }

  .tooltip__reference + .tooltip__reference {
    margin-left: ${spacing.sm}px;
  }

  .music-icon {
    border: none;
    line-height: 0;
    padding: 0;
  }
`

export const MusicOperation: React.FC<PLUS.Music & { index?: number }> = (value) => {
  const { id, has_favorite, artist, index } = value

  /** 列表下载 */
  const handleDownload = () => {
    downloadMusicSampleById(id).then(({ result, data }) => {
      if (result && data && data.url) {
        download(data.url)
      }
    })
  }

  return useMemo(
    () => (
      <MusicOperationContainer halign="flex-end" className="music-col operations">
        <Tooltip title={<FormattedMessage id="actions.music.comps" />} placement="top" overlayStyle={{ fontSize: 12 }}>
          <Icon glyph="download" size={20} className="music-icon link__primary" onClick={handleDownload} />
        </Tooltip>
        <Collect
          key={id}
          id={id} //  素材ID
          index={index}
          contributorId={artist}
          isCollected={has_favorite} // 是否已经收藏过
          contentType={ContentType.Music} // 素材类型
          canChangeColor={true}
          onToggleCollect={(v) => {
            cachedStatusMap.set(id, v) // 用户点击播放时同步，场景：列表中选择一项先收藏再播放
            setTimeout(() => {
              events.emit(ACTIONS.SYNC_MUSIC_STATUS, { id, collected: v })
            }, 0)
          }}
        />
        <AddToCart id={id} contentType={ContentType.Music} />
      </MusicOperationContainer>
    ),
    [id, has_favorite]
  )
}

export const MusicItem: React.FC<
  PLUS.Music & {
    searchStatistic?: PLUS.SearchStatistic
    source: PLUS.Music[]
    index: number
    handleMeituAnalyzeClick?: () => void
  }
> = (value) => {
  const {
    id,
    artist,
    title,
    bpm,
    moods,
    genres,
    instruments,
    is_purchased,
    duration,
    origin_waveform_url,
    type,
    searchStatistic,
    source,
    handleMeituAnalyzeClick,
    index,
  } = value

  const { file, play, pause, isPlaying, loadFileList } = musicStore<
    Pick<MusicStore, 'file' | 'play' | 'pause' | 'isPlaying' | 'loadFileList'>
  >(
    (state) => ({
      file: state.file,
      play: state.play,
      pause: state.pause,
      isPlaying: state.isPlaying,
      loadFileList: state.loadFileList,
    }),
    shallow
  )

  const isActive = file && file.id === id

  return useMemo(
    () => (
      <div className="music-row body">
        <div className="music-col play ">
          {isActive && isPlaying ? (
            <Button type="secondary" size="small" className="music-icon link__primary" onClick={pause}>
              <Icon glyph="music-controls-pause" size={32} hoverTitle={<FormattedMessage id="actions.pause" />} />
            </Button>
          ) : (
            <Button
              type="secondary"
              size="small"
              className="music-icon link__primary"
              onClick={() => {
                play(value)
                loadFileList(source)
                setTimeout(() => {
                  events.emit(ACTIONS.SYNC_MUSIC_STATUS, {
                    id,
                    collected: cachedStatusMap.get(id),
                  })
                }, 100)
              }}
            >
              <Icon glyph="music-controls-play" size={32} hoverTitle={<FormattedMessage id="actions.play" />} />
            </Button>
          )}
        </div>
        <MusicInfo
          className="info"
          id={id}
          index={index}
          title={title}
          artist={artist}
          isPurchased={Boolean(is_purchased)}
          searchStatistic={searchStatistic}
          handleMeituAnalyzeClick={handleMeituAnalyzeClick}
        />
        <MusicTrack
          key={origin_waveform_url}
          currentTime={0}
          duration={duration}
          waveformUrl={origin_waveform_url}
          type={type}
        />
        <div className="music-col bpm">{bpm === 0 ? '-' : `${bpm}bpm`}</div>
        <div className="music-col duration">{formatSecondNumber(duration)}</div>
        <div className="music-col tags">
          {Array.isArray(moods) &&
            moods.map(({ id: moodId, name }) => (
              <Link href={`/music/search?moodId=${moodId}`} key={moodId}>
                <a className="music-tag link__primary">{name}</a>
              </Link>
            ))}
          {Array.isArray(genres) &&
            genres.map(({ id: genreId, name }) => (
              <Link href={`/music/search?genreId=${genreId}`} key={genreId}>
                <a className="music-tag link__primary">{name}</a>
              </Link>
            ))}
          {Array.isArray(instruments) &&
            instruments.map(({ id: instrumentId, name }) => (
              <Link href={`/music/search?instrumentId=${instrumentId}`} key={instrumentId}>
                <a className="music-tag link__primary">{name}</a>
              </Link>
            ))}
        </div>
        <MusicOperation {...value} />
      </div>
    ),
    [isPlaying, isActive, id]
  )
}

export const MusicList: React.FC<
  {
    source: Array<PLUS.Music>
    searchStatistic?: PLUS.SearchStatistic
  } & Spacing
> = ({ source, searchStatistic, ...rest }) => {
  /** 搜索列表项曝光 */
  const handleExpose = (item, index) => {
    if (!searchStatistic) return
    const { id, artist, title, duration } = item
    const { term, searchid } = searchStatistic || {}

    meituSearchResultsCardShow({
      key_word: term,
      postion_id: index,
      title,
      dur: duration,
      material_type: MeituAnalyzeSearchContentType.music,
      card_id: id,
      author_id: artist,
      request_id: searchid,
    })
  }

  /** 搜索列表项点击 */
  const handleMeituAnalyzeClick = (item, index) => {
    if (!searchStatistic) return
    const { id, artist, title, duration } = item
    const { term, searchid } = searchStatistic || {}

    meituSearchResultsCardClick({
      key_word: term,
      postion_id: index,
      title,
      dur: duration,
      material_type: MeituAnalyzeSearchContentType.music,
      card_id: id,
      author_id: artist,
      request_id: searchid,
    })
    Cookies.set('searchid', searchid)
  }

  return useMemo(
    () =>
      Array.isArray(source) && source.length > 0 ? (
        <MusicListContainer {...rest}>
          <div className="music-row head">
            <div className="music-col play" />
            <div className="music-col info">
              <FormattedMessage id="nav.music" />
              /
              <FormattedMessage id="page.music.info.composer" />
            </div>
            <div className="music-col track">
              <FormattedMessage id="page.music.info.track" />
            </div>
            <div className="music-col bpm">
              <FormattedMessage id="page.music.info.bpm" />
            </div>
            <div className="music-col duration">
              <FormattedMessage id="page.music.info.duration" />
            </div>
            <div className="music-col tags">
              <FormattedMessage id="table.cart.title.tag" />
            </div>
            <div className="music-col operations" />
          </div>
          {source.map((s, index) => {
            cachedStatusMap.set(s.id, s.has_favorite)
            return (
              <ComExpose onExpose={() => handleExpose(s, index)} key={`music-list-item-${index}`}>
                <MusicItem
                  searchStatistic={searchStatistic}
                  {...s}
                  source={source}
                  index={index}
                  handleMeituAnalyzeClick={() => handleMeituAnalyzeClick(s, index)}
                />
              </ComExpose>
            )
          })}
        </MusicListContainer>
      ) : (
        <NoResult />
      ),
    [source]
  )
}
