import React from 'react'
import { FormattedMessage } from 'react-intl'
import Router from 'next/router'
import { PurchaseStatus, ContentType, AddToCartStatus } from 'helpers/enums'
import { addToCart, purchase } from 'api/cart'
import { showModal, updateCartCounts } from 'shared/actions'
import { addSuccess, getCartCount } from 'helpers/util'
import Messages from './messages'

function gotoCart() {
  Router.push({ pathname: '/cart' })
}

function gotoPurchased(id: string, contentType: ContentType) {
  window.open(`/account/purchased?able_ids=${id}&type=${contentType}`)
}

export function showAlert(msg: any) {
  if (!msg) {
    return
  }
  showModal('ALERT', {
    ...msg,
  })
}

export function getPermissionStatus({
  manager,
  permission,
  isProduct = true,
  isCombo = false,
}: {
  manager: PLUS.Manager
  permission: PLUS.Permission
  isProduct?: boolean
  isCombo?: boolean
}): PLUS.PermissionStatus {
  const { status, is_expired, expired_at, start_at, end_at, left_cnt, is_child, able_name, purchase_id } = permission

  // 前人写的太啰嗦了，而且有几处重复的地方
  // 如果是已购买，则权限置灰不可勾选    canWarrant为转授权
  // 需要与改行数据的被授权方判断是否相等，如果相等 ，权限置灰不可勾选
  // purchase_id 为购买ID
  if (isCombo) {
    // plus套餐的sku可用状态判断区分
    if (status && !is_expired) {
      if (purchase_id) {
        return { active: false, messages: [] }
      }
      return { active: true, messages: [] }
    }
  } else if (status && !is_expired && left_cnt > 0) {
    if (purchase_id) {
      return { active: false, messages: [] }
    }
    return { active: true, messages: [] }
  }

  const messages: Array<any> = [
    <FormattedMessage key="form.apply.alert.title" id="form.apply.alert.title">
      {(msg) => msg}
    </FormattedMessage>,
  ]

  const manager400 = (
    <FormattedMessage key="from.apply.feedback-400" id="from.apply.feedback-400">
      {(msg) => msg}
    </FormattedMessage>
  )

  const contactManager = manager ? (
    <FormattedMessage
      key="err-contact"
      id="common.contact-cc-with-name"
      values={{
        name: `${manager.realname} ${manager.phone}`,
      }}
    >
      {(msg) => msg}
    </FormattedMessage>
  ) : (
    manager400
  )

  const contactSuperior = (
    <FormattedMessage key="err-contact" id="common.contact-parent-account">
      {(msg) => msg}
    </FormattedMessage>
  )

  if (is_expired) {
    let common: any = ''
    if (start_at && end_at) {
      common = (
        <React.Fragment key="err-common">
          <FormattedMessage id="errors.contract-expired-with-range" values={{ start: start_at, end: end_at }}>
            {(msg) => msg}
          </FormattedMessage>
          <FormattedMessage
            id={`errors.unable-${isProduct ? 'license' : 'download'}-with-name`}
            values={{
              name: `“${able_name}-${permission.name}”`,
            }}
          >
            {(msg) => msg}
          </FormattedMessage>
        </React.Fragment>
      )
    } else if (expired_at) {
      common = (
        <React.Fragment key="err-common">
          <FormattedMessage id="errors.license-rights-expired-with-range" values={{ end: expired_at }}>
            {(msg) => msg}
          </FormattedMessage>
          <FormattedMessage
            id={isProduct ? `errors.unable-license-with-name` : 'errors.unable-download-with-name'}
            values={{
              name: `“${able_name}-${permission.name}”`,
            }}
          >
            {(msg) => msg}
          </FormattedMessage>
        </React.Fragment>
      )
    }
    messages.push(common)
    messages.push(is_child ? contactSuperior : contactManager)
  } else if (!status) {
    let formattedId = ''
    if (isProduct) {
      formattedId = isCombo ? 'errors.purchase-access-closed-with-combo' : 'errors.purchase-access-closed-with-name'
    } else {
      formattedId = 'errors.download-access-closed-with-name'
    }
    messages.push(
      <FormattedMessage
        id={formattedId}
        values={{
          name: `“${able_name}-${permission.name}”`,
        }}
      >
        {(msg) => msg}
      </FormattedMessage>
    )
    messages.push(is_child ? contactSuperior : contactManager)
  } else if (left_cnt <= 0) {
    messages.push(
      <FormattedMessage
        id={isProduct ? 'errors.purchase-comps-reached-maximum' : 'errors.download-comps-reached-maximum'}
        values={{ name: able_name }}
      >
        {(msg) => msg}
      </FormattedMessage>
    )
    messages.push(is_child ? contactSuperior : contactManager)
  }

  return { active: false, messages }
}

function formatMessagesFromData(data?: PLUS.PurchaseErrData): Array<string> {
  if (!data) {
    return
  }
  const {
    able_id,
    able_name,
    name,
    purchase: { warrant_name, id_number, username, created_at },
  } = data

  const messages = []
  messages.push(
    <FormattedMessage key="msg-asset-id" id="common.asset-id">
      {(msg) => (
        <>
          <span className="form-item-label">{msg}</span>
          {able_id}
        </>
      )}
    </FormattedMessage>
  )
  messages.push(
    <FormattedMessage key="msg-usage" id="table.cart.title.usage">
      {(msg) => (
        <>
          <span className="form-item-label">{msg}</span>
          {`${able_name}-${name}`}
        </>
      )}
    </FormattedMessage>
  )
  messages.push(
    <FormattedMessage key="msg-licensee" id="table.cart.title.licensee">
      {(msg) => (
        <>
          <span className="form-item-label">{msg}</span>
          {`${warrant_name} ${id_number}`}
        </>
      )}
    </FormattedMessage>
  )
  messages.push(
    <FormattedMessage key="msg-purchaser" id="table.cart.title.purchaser">
      {(msg) => (
        <>
          <span className="form-item-label">{msg}</span>
          {username}
        </>
      )}
    </FormattedMessage>
  )
  messages.push(
    <FormattedMessage key="msg-purchase-time" id="table.cart.title.purchase-time">
      {(msg) => (
        <>
          <span className="form-item-label">{msg}</span>
          {created_at}
        </>
      )}
    </FormattedMessage>
  )

  return messages
}

export function getSampleErrMsg(
  status: PurchaseStatus,
  manager: PLUS.Manager,
  data?: PLUS.PurchaseErrData,
  isChildren?: boolean
) {
  let isChild

  if (data) {
    isChild = data.is_child
  }
  if (isChildren) {
    // status 为5的时候 不返回data 需要传进来 isChildren
    isChild = isChildren
  }

  const title = (
    <FormattedMessage key="err-title" id="form.apply.alert.title">
      {(msg) => msg}
    </FormattedMessage>
  )
  const contactManager = manager ? (
    <FormattedMessage
      key="err-contact"
      id="common.contact-cc-with-name"
      values={{ name: `${manager.realname} ${manager.phone}` }}
    >
      {(msg) => msg}
    </FormattedMessage>
  ) : (
    <FormattedMessage key="err-contact" id="from.apply.feedback-400">
      {(msg) => msg}
    </FormattedMessage>
  )
  const contactSuperior = (
    <FormattedMessage key="err-contact" id="common.contact-parent-account">
      {(msg) => msg}
    </FormattedMessage>
  )

  const errMap = {
    [PurchaseStatus.Expired]: ({ start_at, end_at, expired_at }: PLUS.PurchaseErrData) => {
      // 合同到期 || 权限到期

      let result = <FormattedMessage id="errors.contract-expired-download">{(msg) => msg}</FormattedMessage>

      if (start_at && end_at) {
        result = (
          <React.Fragment key="err-common">
            <FormattedMessage id="errors.contract-expired-with-range" values={{ start: start_at, end: end_at }}>
              {(msg) => msg}
            </FormattedMessage>
            <FormattedMessage id="errors.unable-download">{(msg) => msg}</FormattedMessage>
          </React.Fragment>
        )
      } else if (expired_at) {
        result = (
          <React.Fragment key="err-common">
            <FormattedMessage id="errors.license-rights-expired-with-range" values={{ end: expired_at }}>
              {(msg) => msg}
            </FormattedMessage>
            <FormattedMessage id="errors.unable-download">{(msg) => msg}</FormattedMessage>
          </React.Fragment>
        )
      }

      return result
    },
    [PurchaseStatus.PermissionClosed]: function render() {
      return (
        <FormattedMessage key="err-common" id="errors.comps-deactivated">
          {(msg) => msg}
        </FormattedMessage>
      )
    },
    [PurchaseStatus.NotEnough]: function render(params) {
      if (params) {
        return (
          <FormattedMessage key="err-common" id="errors.comps-reached-maximum">
            {(msg) => msg}
          </FormattedMessage>
        )
      }
      return (
        <FormattedMessage key="err-common" id="errors.comps-insufficient-balance">
          {(msg) => msg}
        </FormattedMessage>
      )
    },
    [PurchaseStatus.Removed]: function render() {
      return (
        <FormattedMessage key="err-common" id="errors.comps-pulled-out">
          {(msg) => msg}
        </FormattedMessage>
      )
    },
  }

  const common = errMap[status](data)

  const messages = isChild ? [title, common, contactSuperior] : [title, common, contactManager]

  const Content = (
    <div>
      <Messages messages={messages} color="spruce" />
    </div>
  )

  return {
    title: <FormattedMessage id="modal.download-prompting.title">{(msg) => msg}</FormattedMessage>,
    content: Content,
    okText: <FormattedMessage id="actions.i.see">{(msg) => msg}</FormattedMessage>,
  }
}

export function getPurchaseErrMsg(status: PurchaseStatus, data: PLUS.PurchaseErrData, contentType: ContentType) {
  const infoMap = {
    [PurchaseStatus.Removed]: 'errors.asset-pulled-out',
    [PurchaseStatus.Expired]: 'errors.contract-expired',
    [PurchaseStatus.PermissionClosed]: 'errors.access-closed',
    [PurchaseStatus.Purchased]: 'errors.already-purchased-no-need-purchase',
    [PurchaseStatus.NotEnough]: 'errors.insufficient-balance',
    [PurchaseStatus.Existed]: 'errors.already-in-cart-buy',
  }

  const messages = formatMessagesFromData(data)

  const Content = (
    <div>
      <FormattedMessage id={infoMap[status]} tagName="div" />
      <Messages messages={messages} color="pewter" />
    </div>
  )

  return {
    title: <FormattedMessage id="modal.cart-tip.title">{(msg) => msg}</FormattedMessage>,
    okText: <FormattedMessage id="actions.check">{(msg) => msg}</FormattedMessage>,
    content: Content,
    onConfirm: status === PurchaseStatus.Purchased ? () => gotoPurchased(data.able_id, contentType) : gotoCart,
  }
}

export async function purchaseAction({
  contentType = ContentType.Creative,
  id,
  warrantId,
  permissionId,
}: {
  contentType?: ContentType
  id: string
  permissionId: number
  warrantId?: number
  isSample?: boolean
  manager: PLUS.Manager
}) {
  const { status, data, result } = await purchase({
    contentType,
    id,
    permissionId,
    userWarrantId: warrantId,
  })
  if (!result) {
    return Promise.reject()
  }
  if (status === PurchaseStatus.Normal) {
    Router.push({
      pathname: '/cart/purchased/single',
      query: { id: data.purchase.id, content_type: contentType, purchase_type: data.purchase.purchase_type },
    })
  } else {
    return Promise.reject(getPurchaseErrMsg(status, data, contentType))
  }
}

export async function downloadSample({
  contentType = ContentType.Creative,
  id,
  warrantId,
  permissionId,
  manager,
}: {
  contentType?: ContentType
  id: string
  permissionId?: number
  warrantId?: number
  manager: PLUS.Manager
}) {
  // 下载小样使用购买接口
  const { result, status, data } = await purchase({
    id,
    contentType,
    permissionId,
    userWarrantId: warrantId,
  })

  if (!result) {
    return Promise.reject()
  }

  if (status === PurchaseStatus.Normal) {
    Router.push({
      pathname: '/cart/downloading',
      query: { id: data.purchase.id, purchase_type: data.purchase.purchase_type },
    })
  } else if (status !== PurchaseStatus.Normal) {
    // 购买出错流程
    return Promise.reject(getSampleErrMsg(status, manager, data))
  }
}

function getAddToCartErrMsg(id: string, status: AddToCartStatus, contentType: ContentType) {
  const infoMap = {
    [AddToCartStatus.NotEnough]: 'page.cart.maximum-200',
    [AddToCartStatus.Existed]: 'errors.already-in-cart-buy',
    [AddToCartStatus.Purchased]: 'errors.already-purchased',
  }

  return {
    title: <FormattedMessage id="modal.cart-tip.title">{(msg) => msg}</FormattedMessage>,
    okText: <FormattedMessage id="actions.check">{(msg) => msg}</FormattedMessage>,
    content: <FormattedMessage id={infoMap[status]} tagName="div" />,
    onConfirm: status === AddToCartStatus.Purchased ? () => gotoPurchased(id, contentType) : gotoCart,
  }
}

interface AddCartProps {
  event?: any
  contentType?: ContentType
  id: string
  warrantId?: number
  permissionId?: number
  packagePermissionId?: number
  callback?: () => void
}

export async function addToCartAction({
  contentType = ContentType.Creative,
  id,
  warrantId,
  permissionId,
  packagePermissionId,
}: AddCartProps) {
  const { status, result } = await addToCart({
    contentType: contentType === ContentType.Editorial ? ContentType.Creative : contentType,
    id,
    permissionId,
    packagePermissionId,
    userWarrantId: warrantId,
  })

  if (!result) {
    return Promise.reject()
  }
  if (result && status === undefined) {
    return Promise.resolve(null)
  }
  return Promise.reject(getAddToCartErrMsg(id, status, contentType))
}

export function addCartResult({ event, contentType, id, warrantId, permissionId, packagePermissionId }: AddCartProps) {
  addToCartAction({
    id,
    contentType,
    permissionId,
    packagePermissionId,
    warrantId,
  })
    .then(() => addSuccess(event, () => updateCartCounts(getCartCount() + 1)))
    .catch(showAlert)
}

export function getProcessedPermissions(manager: PLUS.Manager, permissions: Array<PLUS.Permission>, isCombo?: boolean) {
  if (!permissions) {
    return []
  }

  return permissions.map((permission) => {
    const extra = getPermissionStatus({
      manager,
      permission,
      isCombo,
    })

    return {
      ...permission,
      extra,
    }
  })
}
