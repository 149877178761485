import React, { useEffect } from 'react'
import styled from 'styled-components'

const InputDropDownContainer = styled.div`
  position: relative;
  display: flex;
  flex: 1;
`

const InputDropDownMenu = styled.ul`
  position: absolute;
  margin: 0;
  overflow: hidden;
  top: 35px;
  left: 8px;
  right: 8px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.palette.white};
  color: ${({ theme }) => theme.palette.black};
`

const InputDropDownMenuItem = styled.li`
  width: 100%;
  height: 44px;
  cursor: pointer;
  padding: 12px;

  :hover {
    background-color: ${({ theme }) => theme.palette.daisy};
  }
`

function InputDropDown({
  visible,
  overlay,
  checkedIndex,
  menuClick,
  menuKeyDown,
  menuMouseOver,
  children,
}: {
  visible: boolean // 是否展示下拉框
  overlay: Array<any> // 下拉框数据
  checkedIndex: number // 当前选中的index
  menuClick: (opt?: { value: string; index: number }) => void // 点击下拉列表触发
  menuKeyDown: (eventType: string) => void // 键盘上下键触发
  menuMouseOver: (index: number) => void // 鼠标hover 上触发
  children: any
}) {
  useEffect(() => {
    document.addEventListener('keydown', handleKeydown)
    return () => {
      document.removeEventListener('keydown', handleKeydown)
    }
  }, [visible, menuKeyDown])

  const handleKeydown = (e) => {
    const event = e || window.event
    const key = event.which || event.keyCode || event.charCode
    if (visible) {
      if (key === 40) {
        // 下键
        menuKeyDown('pageDown')
      }
      if (key === 38) {
        // 上键
        menuKeyDown('pageUp')
        event.preventDefault()
      }
      if (key === 13) {
        // enter
        menuKeyDown('enter')
      }
    }
  }

  return (
    <InputDropDownContainer onBlur={() => menuClick()}>
      {children}
      {visible && (
        <InputDropDownMenu>
          {overlay &&
            overlay.map(({ value, index }) => (
              <InputDropDownMenuItem
                onMouseDown={() => {
                  menuClick({ value, index })
                }}
                onMouseOver={() => menuMouseOver(index)}
                key={value}
                style={{
                  background: checkedIndex === index ? '#F6F8FA' : '#fff',
                }}
              >
                {value}
              </InputDropDownMenuItem>
            ))}
        </InputDropDownMenu>
      )}
    </InputDropDownContainer>
  )
}

export default React.memo(InputDropDown)
