import React, { Suspense, lazy } from 'react'
import { events } from 'helpers/event-emitter'
import { canUseDOM } from 'helpers/util'
import { ACTIONS } from 'shared/constants'

const MODAL_COMPONENTS = {
  ALERT: lazy(() => import(/* webpackChunkName: "alert-modal" */ './alert-modal')),
  AUTH: lazy(() => import(/* webpackChunkName: "auth-modal" */ './auth-modal')),
  CART_TIP: lazy(() => import(/* webpackChunkName: "cart-tip-modal" */ './cart-tip-modal')),
  CHOOSE_PERMISSION: lazy(() => import(/* webpackChunkName: "choose-permission-modal" */ './choose-permission-modal')),
  CONFIRM: lazy(() => import(/* webpackChunkName: "confirm-modal" */ './confirm-modal')),
  IMAGE_CROP: lazy(() => import(/* webpackChunkName: "image-crop-modal" */ './image-crop-modal')),
  IMAGE_SEARCH: lazy(() => import(/* webpackChunkName: "image-search-modal" */ './image-search-modal')),
  EXPIRE_REMINDER: lazy(() => import(/* webpackChunkName: "expire-reminder-modal" */ './expire-reminder-modal')),
  PURCHASE_CONFIRM: lazy(() => import(/* webpackChunkName: "purchase-confirm-modal" */ './purchase-confirm-modal')),
  Audit_Confirm_Modal: lazy(() => import('./audit-status-modal')),
  SAMPLE_DOWNLOAD: lazy(() => import(/* webpackChunkName: "sample-download-modal" */ './sample-download-modal')),
  UPDATE_TAG: lazy(() => import(/* webpackChunkName: "update-tag-modal" */ './update-tag-modal')),
  NEW_COLLECTION: lazy(() => import(/* webpackChunkName: "new-collection-modal" */ './new-collection-modal')),
  MOVE_COLLECTION: lazy(() => import(/* webpackChunkName: "move-collection-modal" */ './move-collection-modal')),
  DOWNLOAD_CERTIFICATE_MODAL: lazy(
    () => import(/* webpackChunkName: "download-certificate-modal" */ './download-certificate-modal')
  ),
  PERFECTION_WARRANT: lazy(
    () => import(/* webpackChunkName: "perfection-warrant-modal" */ './perfection-warrant-modal')
  ),
  CHOOSE_EDITORIAL_PERMISSION: lazy(
    () => import(/* webpackChunkName: "choose-editorial-permission-modal" */ './choose-editorial-permission-modal')
  ),
  QUICKLY_DOWNLOAD: lazy(() => import(/* webpackChunkName: "quickly-download-modal" */ './quickly-download-modal')),
  ACCOUNT_INFO: lazy(() => import(/* webpackChunkName: "account-info-modal" */ './account-info-modal')),
  EDIT_PERSONAL_INFO: lazy(
    () => import(/* webpackChunkName: "edit-personal-info-modal" */ './edit-personal-info-modal')
  ),

  UPLOAD_PANEL_CLOSE: lazy(
    () => import(/* webpackChunkName: "upload-dialog-close-modal" */ './upload-panel-close-modal')
  ),
  DAM_EDIT_FILES: lazy(() => import(/* webpackChunkName: "dam-edit-files-modal" */ './dam-edit-files-modal')),
  DAM_HELPS_FEEDBACK: lazy(() => import(/* webpackChunkName: "dam-faq-feedback-modal" */ './dam-faq-feedback-modal')),
  DAM_SETTINGS: lazy(() => import(/* webpackChunkName: "dam-settings-modal" */ './dam-settings-modal')),
  DAM_SHOW: lazy(() => import(/* webpackChunkName: "dam-show-modal" */ './dam-show-modal')),
  DAM_LIMIT_SETTING: lazy(() => import(/* webpackChunkName: "dam-limit-setting-modal" */ './dam-limit-setting-modal')),
  DAM_EDIT_RECORDS: lazy(() => import(/* webpackChunkName: "dam-edit-records-modal" */ './dam-edit-records-modal')),
  DAM_DOWNLOAD_RECORDS: lazy(
    () => import(/* webpackChunkName: "dam-download-records-modal" */ './dam-download-records-modal')
  ),

  DAM_SELECT_FOLDER: lazy(() => import(/* webpackChunkName: "dam-select-folder" */ './dam-select-folder')),
  DAM_RENAME: lazy(() => import(/* webpackChunkName: "dam-rename-modal" */ './dam-rename-modal')),
  PREFERENCE_SETTING: lazy(
    () => import(/* webpackChunkName: "preference-setting-modal" */ './preference-setting-modal')
  ),
  PREFERENCE_SETTING_FROM_SHOW: lazy(
    () => import(/* webpackChunkName: "preference-setting-from-show-modal" */ './preference-setting-from-show-modal')
  ),
  DOWNLOAD_PROMPTING: lazy(
    () => import(/* webpackChunkName: "download-prompting-modal" */ './download-prompting-modal')
  ),
  REPORT: lazy(() => import(/* webpackChunkName: "report-modal" */ './report-modal')),
  SHOW_DOWN: lazy(() => import(/* webpackChunkName: "show-down-modal" */ './show-down-modal')),
  CRM_AGREE: lazy(() => import(/* webpackChunkName: "crm-agree-modal" */ './crm-agree-modal')),
  CRM_DISAGREE: lazy(() => import(/* webpackChunkName: "crm-disagree-modal" */ './crm-disagree-modal')),
  DAM_DOWNLOAD_RISK_WARNING: lazy(
    () => import(/* webpackChunkName: "dam-download-risk-warning-modal" */ './dam-download-risk-warning-modal')
  ),
  DAM_LEAD_HELLORF: lazy(() => import(/* webpackChunkName: "dam-lead-hellorf-modal" */ './dam-lead-hellorf-modal')),
  SET_AMOUNT_LIMIT: lazy(() => import(/* webpackChunkName: "set-amount-limit-modal" */ './set-amount-limit-modal')),
  ACCOUNT_BATCH_PERMISSION: lazy(
    () => import(/* webpackChunkName: "account-batch-permission-modal" */ './account-batch-permission-modal')
  ),
  DAM_INTRODUCTION_APPLY: lazy(
    () => import(/* webpackChunkName: "dam-introduction-apply-modal" */ './dam-introduction-apply-modal')
  ),
  DAM_INTRODUCTION_APPLY_PROTOCOL: lazy(
    () =>
      import(/* webpackChunkName: "dam-introduction-apply-protocol-modal" */ './dam-introduction-apply-protocol-modal')
  ),
  DAM_INTRODUCTION_APPLY_CONFIRM: lazy(
    () =>
      import(/* webpackChunkName: "dam-introduction-apply-confirm-modal" */ './dam-introduction-apply-confirm-modal')
  ),
  FEATURES: lazy(() => import(/* webpackChunkName: "features-modal" */ './features-modal')),
  LICENSE: lazy(() => import(/* webpackChunkName: "license-modal" */ './license-modal')),
  EDIT_BASIC_INFO: lazy(() => import(/* webpackChunkName: "edit-basic-info-modal" */ './edit-basic-info-modal')),
  EDIT_DIRECT_LEADER: lazy(
    () => import(/* webpackChunkName: "edit-direct-leader-modal" */ './edit-direct-leader-modal')
  ),
  EDIT_DELETE_EXPIRES: lazy(
    () => import(/* webpackChunkName: "edit-delete-expires-modal" */ './edit-delete-expires-modal')
  ),
  EDIT_DELETE_EXPIRES_INFO: lazy(
    () => import(/* webpackChunkName: "edit-delete-expires-info-modal" */ './edit-delete-expires-modal/info')
  ),
  BUSINESS_NEGOTIATE: lazy(
    () => import(/* webpackChunkName: "business-negotiate-modal" */ './business-negotiate-modal')
  ),
  BATCH_DOWNLOAD_AUTHORIZATION: lazy(
    () => import(/* webpackChunkName: "download-authorization-modal" */ './download-authorization-modal')
  ),
  SHEIN_TOPUP: lazy(() => import(/* webpackChunkName: "shein-topup-modal" */ './shein-topup-modal')),
  APPLY_INVALID: lazy(() => import(/* webpackChunkName: "apply-invoice-modal" */ './apply-invoice-modal')),
  CHANGE_EMAIL: lazy(() => import(/* webpackChunkName: "change-email-modal" */ './change-email-modal')),
  SEND_EMAIL_USER: lazy(() => import(/* webpackChunkName: "send-email-user-modal" */ './send-email-user-modal')),
  APPLY: lazy(() => import(/* webpackChunkName: "apply-modal" */ './apply-modal')),
  Loading: lazy(() => import(/* webpackChunkName: "loading-modal" */ './loading-modal')),
}

const initialModals = []

type State = {
  type: string
  props?: any
}

export default class ModalContainer extends React.Component {
  state = {
    modals: initialModals,
  }

  componentDidMount() {
    events.on(ACTIONS.SHOW_MODAL, this.showModal)
    events.on(ACTIONS.HIDE_MODAL, this.hideModal)

    this.showModal({
      type: 'FEATURES',
    })
  }

  componentWillUnmount() {
    events.off(ACTIONS.SHOW_MODAL, this.showModal)
    events.off(ACTIONS.HIDE_MODAL, this.hideModal)
  }

  showModal = (payload: State) => {
    this.setState({
      modals: this.state.modals.concat(payload),
    })
  }

  hideModal = (type?: string) => {
    this.setState({
      modals: type ? this.state.modals.filter((modal) => modal.type !== type) : initialModals,
    })
  }

  render() {
    const Targets = this.state.modals.map(({ type, props }) => ({
      SpecificModal: MODAL_COMPONENTS[type],
      props,
      type,
    }))

    if (!canUseDOM() || !Targets.length) {
      return null
    }

    return (
      <Suspense fallback={<div>Loading...</div>}>
        {Targets.map(({ SpecificModal, type, props }) => (
          <SpecificModal key={type} isOpen={true} hide={() => this.hideModal(type)} modalProps={props} />
        ))}
      </Suspense>
    )
  }
}
