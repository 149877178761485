import React from 'react'
import { FormattedMessage } from 'react-intl'
import { renameCollection } from 'api/collection'
import { showModal, toast } from 'shared/actions'

export const showCreateModal = (
  remoteRequest: (name: string) => Promise<any>,
  callback: (name?: string, id?: number) => void
) => {
  showModal('NEW_COLLECTION', {
    callback,
    remoteRequest,
    title: <FormattedMessage id="modal.favorites.new" />,
    placeholder: 'modal.favorites.placeholder',
  })
}

export const showRenameModal = (
  id: number | string,
  defaultName: string,
  callback: (name?: string, id?: number) => void
) => {
  showModal('NEW_COLLECTION', {
    callback: () => {
      toast(<FormattedMessage id="toast.renamed" />)
      callback()
    },
    remoteRequest: (name) => renameCollection(id, name),
    title: <FormattedMessage id="modal.favorites.rename" />,
    placeholder: 'modal.favorites.placeholder',
    defaultName,
  })
}
