import React from 'react'
import styled from 'styled-components'
import { noop } from 'helpers/util'
import { space, Spacing } from 'components/ui/base'

const IconStyled = styled.svg<{ hoverColor: string }>`
  fill: currentColor;
  cursor: ${(props) => props.cursor};
  color: ${(props) => props.color};
  ${space};

  &:hover {
    color: ${(props) => props.hoverColor} !important;
  }
`

export interface Props {
  className?: string
  glyph: string
  size?: number | string
  width?: number | string
  height?: number | string
  cursor?: string
  color?: string
  hoverColor?: string
  onClick?: any
  hoverTitle?: React.ReactNode
}

export default function Icon({
  className,
  glyph,
  size = 24,
  width,
  height,
  cursor = 'pointer',
  onClick = noop,
  color,
  hoverColor,
  hoverTitle,
  ...rest
}: Props & Spacing) {
  return (
    <IconStyled
      className={className}
      width={width || size}
      height={height || size}
      cursor={cursor}
      onClick={onClick}
      color={color}
      hoverColor={hoverColor}
      {...rest}
    >
      {hoverTitle && <title>{hoverTitle}</title>}
      <use xlinkHref={`#icon-${glyph}`} />
    </IconStyled>
  )
}
