import React, { FC, useMemo, useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import shallow from 'zustand/shallow'
import classNames from 'classnames'
import styled from 'styled-components'
import { palette, font, spacing } from '@zcool/theme'
import { zIndex } from '@zcool/util'
import Flex from '@zcool/flex'
import Button from '@zcool/button'
import Tooltip from '@zcool/tooltip'
import Icon from 'components/ui/icon'
import Slider from 'components/video/player/slider'
import IconArea from 'components/video/player/icon-area'
import { formatSecondNumber } from 'helpers/util'
import { MusicInfo, MusicTrack, MusicOperation } from './index'
import ReactAudio from './react-audio'
import { musicStore, MusicStore, cachedWaveFormMap } from './store'

const PlayerContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: ${palette.white};
  box-shadow: rgba(0, 0, 0, 0.1) 0 -15px 20px 0;
  z-index: ${zIndex.nav - 10};
  transition: transform 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);

  &.hide {
    transform: translateY(72px);

    .music-toggle > svg {
      transform: rotate(180deg);
    }
  }

  &.open {
    transform: translateY(0);
  }

  #music-player {
    padding: 0 50px;
    height: 80px;
    position: relative;

    .music-controls {
      flex-shrink: 1;
      line-height: 0;
    }

    .music-info {
      overflow: hidden;
      width: 10%;

      .music-name {
        font-size: ${font.size.md}px;
      }

      .music-artist-name {
        font-size: ${font.size.xs}px;

        .form-item-value {
          max-width: 100px;
        }
      }
    }

    .music-duration {
      margin-left: ${spacing.xs}px;
      margin-right: ${spacing.sm}px;
      width: 110px;
      font-size: ${font.size.sm}px;

      &-total {
        color: ${palette.pewter};
      }
    }

    .music-controls,
    .music-info,
    .music-track,
    .music-volume {
      margin-right: 40px;
    }

    .track {
      flex: 1;
      position: relative;
    }

    .music-volume {
      line-height: 0;

      .tooltip__content {
        box-shadow: rgba(0, 0, 0, 0.2) 0 2px 10px 0;

        .slider--default {
          background-color: ${palette.gray};
        }

        .slider--active > i {
          background-color: ${palette.primary};
        }
      }

      .tooltip__straw {
        height: ${spacing.xs}px;
      }
    }

    .music-queue {
      margin-left: ${spacing.sm}px;

      &-button {
        border: none;
        line-height: 0;
        padding: 0;
      }

      &.visible .music-queue-detail {
        opacity: 1;
        transform: translateY(-100%);
      }

      &-detail {
        position: absolute;
        opacity: 0;
        transform: translateY(4px);
        z-index: ${zIndex.hide};
        top: -4px;
        width: 320px;
        right: 50px;
        background-color: ${palette.white};
        box-shadow: rgba(0, 0, 0, 0.2) 0 2px 10px 0;

        &-head {
          padding: ${spacing.sm}px;
        }

        &-body {
          height: 280px;
          overflow: auto;

          > li {
            padding: ${spacing.xs}px ${spacing.sm}px;
            border-top: 1px solid ${palette.pearl};

            .music-col {
              width: 70%;
              padding-right: 12px;
              cursor: pointer;

              &.pulled-out {
                cursor: initial;
              }

              .music-artist-name {
                font-size: ${font.size.xs}px;
              }

              .music-name {
                font-size: ${font.size.sm}px;
              }
            }

            &:last-child {
              border-bottom: 1px solid ${palette.pearl};
            }

            &:hover {
              background-color: #fafcfc;

              .music-queue-item-operation {
                display: initial;
              }

              .music-queue-item-duration {
                display: none;
              }
            }

            .music-queue-item-operation {
              display: none;
              line-height: 0;
              color: ${palette.spruce};

              > svg:last-child {
                margin-left: ${spacing.xs}px;
              }
            }

            .music-queue-item-duration {
              font-size: ${font.size.xs}px;
              color: ${palette.pewter};
            }
          }
        }
      }

      &-count {
        font-size: ${font.size.md}px;
        font-weight: bold;
        flex: 1;
      }

      &-clear {
        margin-right: ${spacing.md}px;
        font-size: ${font.size.sm}px;
        color: ${palette.spruce};
        cursor: pointer;
      }
    }

    .music-toggle {
      cursor: pointer;
      width: 36px;
      height: 16px;
      position: absolute;
      right: 8px;
      top: 0;
      transform: translateY(-100%);
      border-radius: 2px 2px 0 0;
      background-color: ${palette.white};
      box-shadow: rgba(0, 0, 0, 0.1) 0 -10px 20px 0;
    }
  }
`

export const ControlBtn = styled.button`
  background: 0 0;
  border: 1px solid transparent;
  width: 56px;

  :hover {
    color: ${(props: { disabled: boolean }) => props.disabled && 'rgba(16, 16, 16, 0.3)'} !important;
  }

  > svg {
    cursor: ${(props: { disabled: boolean }) => (props.disabled ? 'not-allowed' : 'pointer')};
  }
`

// 播放历史
const MusicQueue: FC<any> = () => {
  const [visible, setVisible] = useState(false)
  const { history, play, clearHistory, removeHistoryById } = musicStore<
    Pick<MusicStore, 'history' | 'play' | 'clearHistory' | 'removeHistoryById'>
  >(
    (state) => ({
      history: state.history,
      play: state.play,
      clearHistory: state.clearHistory,
      removeHistoryById: state.removeHistoryById,
    }),
    shallow
  )

  return useMemo(
    () => (
      <div
        className={classNames('music-queue', {
          visible,
        })}
      >
        <Tooltip
          title={<FormattedMessage id="page.music.play-history" />}
          placement="top"
          overlayStyle={{ fontSize: 12 }}
        >
          <Button
            type="secondary"
            size="small"
            className="music-queue-button link__primary"
            onClick={() => setVisible(!visible)}
          >
            <Icon glyph="music-play-history" size={20} />
          </Button>
        </Tooltip>
        <div className="music-queue-detail">
          <Flex valign="center" className="music-queue-detail-head">
            <span className="music-queue-count">
              <FormattedMessage id="page.music.play-history" />
              <span>{` (${history.length})`}</span>
            </span>
            <span className="music-queue-clear link__primary" onClick={clearHistory}>
              <FormattedMessage id="actions.clear" />
            </span>
            <Icon
              className="link__primary"
              glyph="close"
              size={16}
              onClick={() => setVisible(false)}
              hoverTitle={<FormattedMessage id="status.close" />}
            />
          </Flex>
          <ul className="music-queue-detail-body">
            {history.map((h, index) => {
              const { id, title, artist, isPulledOut, duration } = h
              return (
                <Flex key={`${id}-music-queue`} halign="space-between" valign="center" as="li">
                  <MusicInfo
                    id={id}
                    index={index}
                    title={title}
                    artist={artist}
                    isPulledOut={isPulledOut}
                    onClick={() => play(h)}
                  />
                  <span className="music-queue-item-duration">{formatSecondNumber(duration)}</span>
                  <span className="music-queue-item-operation">
                    <Icon
                      className="link__primary"
                      glyph="delete"
                      size={20}
                      hoverTitle={<FormattedMessage id="actions.delete" />}
                      onClick={() => removeHistoryById(id)}
                    />
                  </span>
                </Flex>
              )
            })}
          </ul>
        </div>
      </div>
    ),
    [visible, history]
  )
}

// 音量控制
const MusicVolume: FC<{
  volume: number
  isMuted: boolean
  onVolumeChange: (value: number) => void
  onMuteChange: (value: boolean) => void
}> = ({ volume, isMuted, onVolumeChange, onMuteChange }) => {
  return useMemo(
    () => (
      <div className="music-volume">
        <IconArea
          py={12}
          background="white"
          tip={
            <Slider
              value={isMuted ? 0 : volume * 100}
              onChange={onVolumeChange}
              max={100}
              step={1}
              direction="ver"
              style={{ height: 88 }}
            />
          }
        >
          <Icon
            className="link__primary"
            glyph={`music-volume-${isMuted ? 'off' : 'on'}`}
            onClick={() => onMuteChange(!isMuted)}
            hoverTitle={<FormattedMessage id="page.music.controls.volume" />}
          />
        </IconArea>
      </div>
    ),
    [volume, isMuted]
  )
}

const Player: FC<{ paddingLeft: number; id?: string; message?: string }> = ({ paddingLeft, id, message }) => {
  const {
    file,
    play,
    pause,
    volume,
    setVolume,
    currentTime,
    setCurrentTime,
    seekIntent,
    seekTo,
    isMuted,
    toggleMute,
    getHistoryFromStorage,
    updateHistoryById,
    isPlaying,
    preable,
    nextable,
    pre,
    next,
  } = musicStore<
    Pick<
      MusicStore,
      | 'file'
      | 'play'
      | 'pause'
      | 'volume'
      | 'setVolume'
      | 'setCurrentTime'
      | 'currentTime'
      | 'seekIntent'
      | 'seekTo'
      | 'isMuted'
      | 'toggleMute'
      | 'getHistoryFromStorage'
      | 'updateHistoryById'
      | 'isPlaying'
      | 'preable'
      | 'nextable'
      | 'pre'
      | 'next'
    >
  >(
    (state) => ({
      file: state.file,
      play: state.play,
      pause: state.pause,
      volume: state.volume,
      setVolume: state.setVolume,
      setCurrentTime: state.setCurrentTime,
      currentTime: state.currentTime,
      seekIntent: state.seekIntent,
      seekTo: state.seekTo,
      isMuted: state.isMuted,
      toggleMute: state.toggleMute,
      getHistoryFromStorage: state.getHistoryFromStorage,
      updateHistoryById: state.updateHistoryById,
      isPlaying: state.isPlaying,
      preable: state.preable,
      nextable: state.nextable,
      pre: state.pre,
      next: state.next,
    }),
    shallow
  )

  const [isHide, setHide] = useState(false)
  const [flag, setFlag] = useState(0)

  useEffect(() => {
    // 同步本地存储的`播放历史`到 store 中
    getHistoryFromStorage()

    return () => {
      setTimeout(() => {
        cachedWaveFormMap.clear()
      }, 8000)

      pause()
    }
  }, [])

  useEffect(() => {
    if (id) {
      updateHistoryById(id, { isPulledOut: message === '该音频不存在或已下架' })
      setFlag(flag + 1)
    }
  }, [id])

  const handleVolumeChange = (v: number) => {
    setVolume(v / 100)
  }

  const handleTimeUpdate = (e: React.SyntheticEvent<HTMLAudioElement, Event>) => {
    // avoid shadowed name
    const { currentTime: time } = e.currentTarget

    setCurrentTime(time)
  }

  const handleSeekTo = (progress) => {
    if (!isPlaying) {
      play(file)
    }
    seekTo(progress * file.duration)
  }

  const handleClickPrev = (e) => {
    e.stopPropagation()
    pre()
  }

  const handleClickNext = (e) => {
    e.stopPropagation()
    next()
  }

  return useMemo(
    () =>
      file ? (
        <PlayerContainer className={isHide ? 'hide' : 'open'} style={{ paddingLeft }}>
          <ReactAudio
            isPlaying={isPlaying}
            isMuted={isMuted}
            volume={volume}
            seekIntent={seekIntent}
            onTimeUpdate={handleTimeUpdate}
            onEnded={() => {
              setCurrentTime(0)
              pause()
            }}
            src={file.preview_mp3_url}
          />
          <Flex valign="center" id="music-player">
            <Flex valign="center" halign="space-around" className="music-controls">
              <ControlBtn disabled={!preable()} onClick={handleClickPrev} className="link__primary">
                <Icon glyph="music-prev" />
              </ControlBtn>
              <div className="music-controls-play link__primary">
                {isPlaying ? (
                  <Icon
                    glyph="music-controls-pause"
                    size={48}
                    onClick={pause}
                    hoverTitle={<FormattedMessage id="actions.pause" />}
                  />
                ) : (
                  <Icon
                    glyph="music-controls-play"
                    size={48}
                    hoverTitle={<FormattedMessage id="actions.play" />}
                    onClick={() => play(file)}
                  />
                )}
              </div>
              <ControlBtn disabled={!nextable()} onClick={handleClickNext} className="link__primary">
                <Icon glyph="music-next" />
              </ControlBtn>
            </Flex>
            <MusicInfo className="music-info" title={file.title} showDanceBar={false} {...file} />
            <MusicTrack
              key={file.origin_waveform_url}
              currentTime={currentTime}
              duration={file.duration}
              waveformUrl={file.origin_waveform_url}
              type={file.type}
              onSeekTo={handleSeekTo}
            />
            <div className="music-duration">
              <span>{formatSecondNumber(currentTime)}</span>
              <span className="music-duration-total">{` / ${formatSecondNumber(file.duration)}`}</span>
            </div>
            <MusicVolume
              volume={volume}
              isMuted={isMuted}
              onVolumeChange={handleVolumeChange}
              onMuteChange={toggleMute}
            />
            <MusicOperation {...file} />
            <MusicQueue />
            <Flex valign="center" halign="center" className="music-toggle" onClick={() => setHide(!isHide)}>
              <Icon
                glyph="music-hide"
                size={16}
                hoverTitle={
                  <FormattedMessage id={isHide ? 'actions.expand.music.player' : 'actions.collapse.music.player'} />
                }
              />
            </Flex>
          </Flex>
        </PlayerContainer>
      ) : null,
    [file, isPlaying, isMuted, isHide, currentTime, volume, flag]
  )
}

export default Player
