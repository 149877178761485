import create from 'zustand'
import { musicStatic } from 'api/music'
const storageKey = 'plus-music-history'
const MAX_HISTORY_COUNT = 100
// 缓存的波形数据
export const cachedWaveFormMap = new Map()

// 缓存的 id: has_favorite
export const cachedStatusMap = new Map()

export interface MusicStore {
  file: PLUS.Music
  fileList: PLUS.Music[]
  isMuted: boolean
  currentTime: number
  seekIntent: number
  volume: number
  isPlaying: boolean
  history: Array<PLUS.Music & { isPulledOut?: boolean }>
  play: (file: PLUS.Music) => void
  pause: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void
  seekTo: (seekIntent: number) => void
  setVolume: (volume: number) => void
  setCurrentTime: (currentTime: number) => void
  toggleMute: (isMute: boolean) => void
  clearHistory: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void
  removeHistoryById: (id: string) => void
  getHistoryFromStorage: () => void
  updateHistoryById: (id: string, payload: { [key: string]: any }) => void
  loadFileList: (data: Array<PLUS.Music>) => void
  getCurrentPlayingIndex: () => number
  preable: () => boolean
  nextable: () => boolean
  pre: () => void
  next: () => void
}

const [musicStore, api] = create<MusicStore>((set, get) => ({
  file: null,
  fileList: [],
  isMuted: false,
  currentTime: 0,
  seekIntent: 0,
  volume: 0.5,
  isPlaying: false,
  history: [],
  play: (file) => {
    // 同步播放历史 store & storage
    const history = get().history.slice()
    if (history.every((h) => h.id.toString() !== file.id.toString())) {
      history.unshift(file)
      // 超出历史记录最大条数，移除最后一首
      if (history.length > MAX_HISTORY_COUNT) {
        history.pop()
      }
      localStorage.setItem(storageKey, JSON.stringify(history))
    }

    musicStatic(file.id)
    return set(() => ({ file, history, isPlaying: true }))
  },
  pause: () => set(() => ({ isPlaying: false })),
  seekTo: (seekIntent) => set(() => ({ seekIntent })),
  setVolume: (volume) => set(() => ({ volume })),
  setCurrentTime: (currentTime) => set(() => ({ currentTime })),
  toggleMute: () => set((state) => ({ isMuted: !state.isMuted })),
  clearHistory: () => {
    localStorage.removeItem(storageKey)

    return set(() => ({ history: [], file: null }))
  },
  removeHistoryById: (id: string) => {
    let { isPlaying } = get()
    const { history, file } = get()

    const nextHistory = history.filter((h) => h.id.toString() !== id.toString())
    let nextFile = null

    if (nextHistory.length > 0) {
      // 如果删除的是当前正在播放的音乐，需要停止播放并找到下一首
      if (file && file.id.toString() === id.toString()) {
        const index = history.findIndex((h) => h.id.toString() === id.toString())
        const nextIndex = index === history.length - 1 ? 0 : index
        isPlaying = false
        nextFile = nextHistory[nextIndex]
      } else {
        nextFile = file
      }
    }

    localStorage.setItem(storageKey, JSON.stringify(nextHistory))
    return set(() => ({ history: nextHistory, file: nextFile, isPlaying }))
  },
  updateHistoryById: (id: string, payload) => {
    const { history } = get()
    const targetIndex = history.findIndex((h) => h.id.toString() === id.toString())
    if (targetIndex > -1) {
      Object.keys(payload).forEach((key) => {
        history[targetIndex][key] = payload[key]
      })

      localStorage.setItem(storageKey, JSON.stringify(history))
      return set(() => ({ history }))
    }
  },
  getHistoryFromStorage: () => {
    const value = localStorage.getItem(storageKey)
    let history
    try {
      history = JSON.parse(value)
    } catch (error) {
      history = []
    }

    if (Array.isArray(history) && history.length > 0) {
      return set(() => ({ history, file: history[0], fileList: history }))
    }
  },

  /**
   * 加载音乐
   */
  loadFileList: (data: Array<PLUS.Music>) => {
    if (data.length < 1) {
      return null
    }
    set(() => ({ fileList: data }))
  },
  /**
   * 当前播放的index
   */
  getCurrentPlayingIndex: () => {
    const { fileList, file } = get()
    return fileList.findIndex((obj) => obj.id === file.id)
  },

  preable: () => {
    const { getCurrentPlayingIndex } = get()
    return getCurrentPlayingIndex() > 0
  },

  nextable: () => {
    const { fileList, getCurrentPlayingIndex } = get()
    return getCurrentPlayingIndex() < fileList.length - 1
  },
  /**
   * 上一首
   */
  pre: () => {
    const { fileList, preable, getCurrentPlayingIndex, play } = get()
    if (!preable()) {
      return false
    }
    const nextMusic = fileList[getCurrentPlayingIndex() - 1]
    play(nextMusic)
  },
  /**
   * 下一首
   */
  next: () => {
    const { fileList, nextable, getCurrentPlayingIndex, play } = get()
    if (!nextable()) {
      return false
    }
    const nextMusic = fileList[getCurrentPlayingIndex() + 1]
    play(nextMusic)
  },
}))

export { musicStore, api }
