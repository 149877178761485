/* eslint-disable */
/* eslint-disable max-classes-per-file */
import React from 'react'
import Router from 'next/router'
import App from 'next/app'
import Head from 'next/head'
import { ThemeProvider } from 'styled-components'
import { IntlProvider } from 'react-intl'
import * as Sentry from '@sentry/browser'
import NProgress from 'nprogress'
import Header from 'components/header'
import Footer from 'components/footer'
import Toasts from 'components/toasts'
import Modals from 'components/modals'
import Player from 'components/music/player'
import BackToTop from 'components/drawer/back-top'
import ViewReminder from 'components/ui/view-reminder'
import { GlobalContext } from 'shared/context'
import {
  PLUS_NORMAL_TYPE,
  PLUS_PURE_TYPE,
  PLUS_HIDE_HAF_TYPE,
  PLUS_LIMIT_INVITE_TYPE,
  PLUS_LIMIT_BILLING_TYPE,
  PLUS_CLIENT_TYPE_ALL,
  PLUS_CLIENT_TYPE_PC,
} from 'shared/symbols'
import { HTML_TITLE } from 'shared/constants'
import { generateHeaders, getIn, isCustomDomain, checkIsWebPSupported, isShowSheinModal } from 'helpers/util'
import { events } from 'helpers/event-emitter'
import configSentry from 'helpers/sentry'
import { analyzeSensors } from 'helpers/sensors'
import { meituAnalyzeInit } from 'helpers/analyze-meitu'
import { getUserInfo } from 'api/user'
import { logout, getMeituToken } from 'api/auth'
import theme from '@zcool/theme'
import Error from './_error'
import '@zcool/reboot.css'
import '../assets/css/global.css'
import '../assets/css/slick-theme.css'
import '../assets/css/whee-sdk-theme.css'
import '@meitu/whee-sdk/style.css'
import '@meitu/whee-editor-sdk/style.css'

// 解决 FormattedRelativeTime 在safari环境下兼容问题
import '@formatjs/intl-relativetimeformat/polyfill'
import '@formatjs/intl-relativetimeformat/dist/locale-data/zh'
import '@formatjs/intl-relativetimeformat/dist/locale-data/en'
import '@formatjs/intl-pluralrules/polyfill'
import '@formatjs/intl-pluralrules/dist/locale-data/zh'
import '@formatjs/intl-pluralrules/dist/locale-data/en'

import Cookies from 'js-cookie'
import API from 'api/index'
// 维护公告
// import MaintenanceAlert from 'components/maintenance-alert'

// 1. import all svg files in webpack context
const request = require.context('../assets/svg', false, /\.svg$/)
request.keys().forEach(request)

interface Props {
  locale: PLUS.Locale
  mode: PLUS.Mode // 登录后可访问的页面 footer 背景色为白底黑字
  currentUser: PLUS.User
  messages: Record<string, string>
  title: string
  domain: string
  visible: boolean
  hidePhoneTips: boolean
  isWebPSupported: boolean
  showFooter: boolean
}

class Plus extends App<
  Props,
  unknown,
  {
    currentUser: PLUS.User
    plusDomain: string
    settings: any
    showFooter: boolean
  }
> {
  static async getInitialProps({ Component, ctx }: { Component: any; ctx: any }) {
    const { req, res } = ctx
    const { getInitialProps, $$type, $$clientType = PLUS_CLIENT_TYPE_PC, title } = Component
    if (ctx?.req && ctx?.req?.locale !== 'zh' && ctx?.req?.locale !== 'en') {
      ctx.req.locale = 'zh'
    }
    // 调用该页面的 getInitialProps
    let pageProps: any = {}

    // 判断该页面是否需要权限校验
    // 没有声明 $$type 的页面默认都需要权限校验
    let currentUser: PLUS.User = null
    let domain = ''
    let path = ''
    const isServer = !!req

    if (isServer) {
      ;``
      currentUser = req.session ? req.session.currentUser : null

      domain = `https://${req.get('host')}`
      path = req.url

      const status = getIn(['status'], currentUser)

      switch ($$type) {
        case PLUS_NORMAL_TYPE:
          if (status === 1) {
            res.redirect('/')
          }

          break
        case PLUS_PURE_TYPE:
        case PLUS_HIDE_HAF_TYPE:
          break

        default:
          // 其他页面默认不登录不允许访问
          // 当且仅当 currentUser.status === 1 时才允许
          if (status === 3) {
            return res.redirect('/apply')
          }

          if (status === 2) {
            return res.redirect('/freeze')
          }
          // login-temporary 页面不需要登录校验
          if (status !== 1 && !path.includes('/login-temporary')) {
            // TODO: 重定向
            return res.redirect('/welcome')
          }

          // 限制邀请
          if ($$type === PLUS_LIMIT_INVITE_TYPE && !currentUser.is_create_child) {
            pageProps.statusCode = 404
          }

          // 限制资金明细
          if ($$type === PLUS_LIMIT_BILLING_TYPE && (currentUser.parent_id !== 0 || currentUser.type !== 1)) {
            pageProps.statusCode = 404
          }

          break
      }
    } else {
      currentUser = window.__NEXT_DATA__.props.currentUser
      domain = window.location.origin
      if (currentUser && currentUser.status === 2 && ctx.asPath !== '/freeze') {
        Router.replace('/freeze')
      }
    }

    // FIXME: Error [ERR_HTTP_HEADERS_SENT]: Cannot set headers after they are sent to the client
    /* eslint-disable no-param-reassign */
    ctx.headers = generateHeaders(req)
    ctx.currentUser = currentUser
    ctx.domain = domain
    /* eslint-enable no-param-reassign */

    if (getInitialProps) {
      // query[] => string
      Object.keys(ctx.query).forEach((key) => {
        const value = ctx.query[key]

        if (Array.isArray(value)) {
          // eslint-disable-next-line no-param-reassign
          ctx.query[key] = value[0]
        }
      })

      pageProps = await getInitialProps(ctx)
    }

    if (pageProps.redirectUrl) {
      if (isServer) {
        return res.redirect(pageProps.redirectUrl)
      }
      Router.replace(pageProps.redirectUrl)
    }

    //  处理开了两个tab，在新开的 tab 页中登录其他帐号产生的问题

    if (pageProps.statusCode) {
      if (pageProps.message === '未完善资料') {
        if (isServer) {
          const { data, result } = await getUserInfo(ctx.headers)
          if (result) {
            req.session.currentUser = data
          }

          res.redirect('/apply')
        }
      }
    }

    const { locale, messages } = req || window.__NEXT_DATA__.props

    const isWebPSupported = checkIsWebPSupported(req)

    return {
      pageProps,
      currentUser,
      locale,
      messages,
      title,
      domain,
      mode: $$type === PLUS_NORMAL_TYPE ? 'dark' : 'light',
      visible: $$type !== PLUS_HIDE_HAF_TYPE,
      hidePhoneTips: $$clientType === PLUS_CLIENT_TYPE_ALL,
      isWebPSupported,
      showFooter: true,
    }
  }

  constructor(props: any) {
    super(props)
    events.on('LOGOUT', this.handleLogout)

    let plusSettings: any = {}

    if (isCustomDomain(props.domain)) {
      plusSettings = {
        ...(props.pageProps.settings || {}),
        ...getIn(['plus_domain', 'settings'], this.props.currentUser, {}),
      }
    }

    // 已登录用户未开启定制
    if (this.props.currentUser && this.status === 0) {
      plusSettings = {}
    }

    this.state = {
      currentUser: this.props.currentUser,
      showFooter: this.props.showFooter,
      plusDomain: getIn(['plus_domain', 'domain'], this.props.currentUser),
      settings: {
        name: plusSettings.name,
        login_background_image:
          plusSettings.background_image || '//static.zcool.cn/passport4.0/images/login-hellorfplus-ground.jpg',
        login_left_top_logo:
          plusSettings.left_top_logo || '//static.zcool.cn/v1.1.61/passport4.0/images/svg/zcool_logo.svg',
        login_middle_logo: plusSettings.middle_logo || '//static.zcool.cn/passport4.0/images/svg/logo-plus.svg',
        login_name: plusSettings.name || '通行证',
        login_slogan: plusSettings.slogan || '一站式正版视觉内容提供商',
        logo_url: plusSettings.logo || '//static.hellorf.com/plus/images/logo-plus-w.svg',
        icon_url: plusSettings.icon || '//static.hellorf.com/favicon.ico',
        header_bg_color: plusSettings.navbar_color || '#282828',
        header_search_btn_color: plusSettings.search_key_color || theme.palette.primary,
        header_search_btn_hover_color: plusSettings.search_key_color || '#d6372a',
        aside_bg_color: plusSettings.sidebar_color || theme.palette.black2,
        aside_font_color: plusSettings.menu_font_color || theme.palette.gray,
        aside_font_hover_color: plusSettings.menu_font_hover_color || theme.palette.white,
        aside_font_active_color: plusSettings.menu_font_highlight_color || theme.palette.primary,
        header_title_color: plusSettings.name_color || theme.palette.white,
        header_slogan_color: plusSettings.slogan_color || theme.palette.white,
        newNavbar: plusSettings.menu === 'left_top',
        menuType: plusSettings.menu_index_type || '2',
      },
    }
  }

  async componentDidMount() {
    // eslint-disable-next-line global-require
    require('lazysizes')
    const { currentUser, isWebPSupported } = this.props
    configSentry(currentUser)

    if (isWebPSupported) {
      window.localStorage.setItem('webp', '1')
    }

    Router.events.on('routeChangeStart', () => NProgress.start())
    Router.events.on('routeChangeComplete', () => NProgress.done())
    Router.events.on('routeChangeError', () => NProgress.done())

    isShowSheinModal(currentUser)

    analyzeSensors(currentUser)
    // if (!!currentUser && !Cookies.get('zcool_mt')) {
    //   await getMeituToken()
    // }

    // 本地开发关闭神策初始化
    if (process.env.NODE_ENV === 'production' || process.env.ENV === 'production') {
      analyzeSensors(currentUser)
    }

    !Cookies.get('device_id') && (await API.get('/index/suggest')) // 无用接口，服务端种cookie用 device_id
    meituAnalyzeInit(currentUser)
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    super.componentDidCatch(error, errorInfo)
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key])
      })
      Sentry.captureException(error)
    })
  }

  handleLogout = () => {
    logout().then(() => {
      window.location.href = '/welcome'

      setTimeout(() => {
        this.updateCurrentUser(null)
      }, 1000)
    })
  }

  updateCurrentUser = (user: PLUS.User) => {
    this.setState({ currentUser: user })
  }

  updateShowFooter = (showFooter: boolean) => {
    this.setState({ showFooter })
  }

  get status() {
    return getIn(['plus_domain', 'status'], this.props.currentUser) || 0
  }

  render() {
    const {
      Component,
      pageProps,
      locale,
      messages,
      router,
      title,
      domain,
      mode,
      visible,
      hidePhoneTips,
      isWebPSupported,
    } = this.props
    const { statusCode, title: errorTitle, result = true } = pageProps
    const { currentUser, settings, showFooter } = this.state
    const pageTitle = []
    // const isProd = process.env.ENV === 'production'
    if (statusCode) {
      pageTitle.push(`${errorTitle || (statusCode === 410 ? '素材已下架' : statusCode)}`)
    } else if (title) {
      pageTitle.push(title)
    }

    const customTitle = getIn(['plus_domain', 'settings', 'name'], currentUser) || getIn(['name'], settings)

    if (isCustomDomain(domain) && customTitle) {
      pageTitle.push(customTitle)
    } else {
      pageTitle.push(HTML_TITLE)
    }

    /** 全局变量 */
    const GlobalProps = {
      locale,
      settings,
      domain,
      currentUser,
      isWebPSupported,
      showFooter,
      updateShowFooter: this.updateShowFooter,
    }

    return (
      <IntlProvider locale={locale} messages={messages}>
        <GlobalProvider {...GlobalProps}>
          <ThemeProvider
            theme={{
              ...theme,
              customize: settings,
            }}
          >
            <>
              <Head>
                <title>{pageTitle.join(' - ')}</title>
                <link rel="Shortcut Icon" href={settings.icon_url} />
                <script src="//public.static.meitudata.com/meitu/mtstat-sdk/mtstat-sdk.min.js"></script>
              </Head>

              {visible && <Header />}
              {!result || statusCode ? (
                <Error {...pageProps} />
              ) : (
                <Component {...pageProps} query={router.query} isWebPSupported={isWebPSupported} />
              )}
              {!hidePhoneTips && <ViewReminder />}
              {this.status === 0 && visible && showFooter ? <Footer mode={mode} /> : null}
              <Toasts />
              <Modals />
              {visible && <BackToTop />}
              {/^\/music/.test(router.pathname) && <Player paddingLeft={settings.newNavbar ? 0 : 140} {...pageProps} />}
              {/* <MaintenanceAlert /> */}
            </>
          </ThemeProvider>
        </GlobalProvider>
      </IntlProvider>
    )
  }
}

export default Plus

class GlobalProvider extends React.Component<PLUS.GlobalContext> {
  state = {
    currentUser: this.props.currentUser,
  }

  updateCurrentUser = (user) => {
    this.setState({ currentUser: user })
  }

  render() {
    const { children, ...rest } = this.props
    const { currentUser } = this.state

    return (
      <GlobalContext.Provider
        value={{
          ...rest,
          currentUser,
          updateCurrentUser: this.updateCurrentUser,
        }}
      >
        {children}
      </GlobalContext.Provider>
    )
  }
}
