import React, { memo } from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import { palette, font } from '@zcool/theme'
import Icon from 'components/ui/icon'
import { Text } from 'components/ui/base'

interface Props {
  text?: React.ReactNode
  secondText?: React.ReactNode
  extra?: React.ReactNode
  glyph?: string
  style?: React.CSSProperties
}

const Container = styled.div`
  min-height: 582px;
  height: 60vh;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: ${palette.pewter};
  line-height: 24px;
  margin-top: 8px;
  background-color: white;

  .no-result-msg {
    max-width: 550px;
    word-break: break-all;
  }
`

export default memo(function NoResult(props: Props) {
  let { text } = props
  const { glyph = 'no-result', secondText, extra, style } = props

  if (!text) {
    text = (
      <FormattedMessage id="common.no-result">
        {(msg) => (
          <Text className="no-result-msg" size={font.size.md}>
            {msg}
          </Text>
        )}
      </FormattedMessage>
    )
  } else {
    text = (
      <Text className="no-result-msg" size={font.size.md}>
        {text}
      </Text>
    )
  }

  return (
    <Container style={style}>
      <Icon glyph={glyph} width={300} height={200} />
      {text}
      {secondText ? (
        <Text size={font.size.md} mb={32}>
          {secondText}
        </Text>
      ) : null}
      {extra || null}
    </Container>
  )
})

export const searchNull = (keyword) => (
  <>
    <FormattedMessage id="common.no-result-with-keyword" values={{ keyword: keyword && `"${keyword}"` }}>
      {(msg) => msg}
    </FormattedMessage>
    <br />
    <FormattedMessage id="common.no-result-remind-text">{(msg) => msg}</FormattedMessage>
  </>
)
