import React from 'react'
import { FormattedMessage } from 'react-intl'
import { showModal } from 'shared/actions'
import { PurchaseStatus, ContentType } from 'helpers/enums'
import Checkbox from '@zcool/checkbox'

export function getErrMsg(status: PurchaseStatus, data: any, manager: PLUS.Manager): React.ReactNode {
  //  2合同到期 3权限关闭 4数量不足 5已购买 ,
  const {
    able_id: id,
    able_name: ableName, // 素材名称
    start_at: startAt,
    end_at: endAt,
    expired_at: expiredAt,
    is_child: isChild,
    name: pName, // 权限名称
  } = data

  const contactCommon = manager ? (
    <FormattedMessage
      key="err-contact"
      id="common.contact-cc-with-name"
      values={{ name: `${manager.realname} ${manager.phone}` }}
    >
      {(msg) => msg}
    </FormattedMessage>
  ) : (
    <FormattedMessage key="err-contact" id="from.apply.feedback-400">
      {(msg) => msg}
    </FormattedMessage>
  )

  const contactSuperior = <FormattedMessage id="common.contact-parent-account">{(msg) => msg}</FormattedMessage>

  const contact400 = <FormattedMessage id="from.apply.feedback-400">{(msg) => msg}</FormattedMessage>

  if (status === PurchaseStatus.Removed) {
    // 1素材下架

    return (
      <>
        <FormattedMessage id="errors.asset-pulled-out-with-id" values={{ id }}>
          {(msg) => msg}
        </FormattedMessage>
        {contactCommon}
      </>
    )
  }
  if (status === PurchaseStatus.Expired) {
    // 2 合同到期 || 权限到期
    let common = <FormattedMessage id="errors.contract-expired">{(msg) => msg}</FormattedMessage>

    if (startAt && endAt) {
      common = (
        <>
          <FormattedMessage
            id="errors.contract-expired-with-range"
            values={{
              start: startAt,
              end: endAt,
            }}
          >
            {(msg) => msg}
          </FormattedMessage>
          <FormattedMessage id="errors.unable-license-with-name" values={{ name: `“${ableName}-${pName}”` }}>
            {(msg) => msg}
          </FormattedMessage>
        </>
      )
    } else if (expiredAt) {
      common = (
        <>
          <FormattedMessage
            id="errors.license-rights-expired-with-range"
            values={{
              end: expiredAt,
            }}
          >
            {(msg) => msg}
          </FormattedMessage>
          <FormattedMessage id="errors.unable-license-with-name" values={{ name: `“${ableName}-${pName}”` }}>
            {(msg) => msg}
          </FormattedMessage>
        </>
      )
    }

    return (
      <>
        {common}
        {isChild ? contactSuperior : contactCommon}
      </>
    )
  }
  if (status === PurchaseStatus.PermissionClosed) {
    // 3 权限关闭

    return (
      <>
        <FormattedMessage id="errors.access-closed">{(msg) => msg}</FormattedMessage>
        {isChild ? contactSuperior : contactCommon}
      </>
    )
  }

  if (status === PurchaseStatus.NotEnough) {
    // 5数量不足

    return (
      <>
        <FormattedMessage id="errors.insufficient-balance-can-buy-1" values={{ name: `“${ableName}-${pName}”` }}>
          {(msg) => msg}
        </FormattedMessage>
        {isChild ? (
          <FormattedMessage id="errors.insufficient-balance-can-buy-2">{(msg) => msg}</FormattedMessage>
        ) : manager ? (
          <FormattedMessage id="errors.insufficient-balance-can-buy-3">{(msg) => msg}</FormattedMessage>
        ) : (
          <>！{contact400}</>
        )}
      </>
    )
  }
  if (status === PurchaseStatus.Purchased) {
    // 4已购买
    return (
      <FormattedMessage id="errors.already-purchased-no-need-purchase-with-id" values={{ id }}>
        {(msg) => msg}
      </FormattedMessage>
    )
  }
  return (
    <FormattedMessage id="errors.purchased-failed" values={{ id }}>
      {(msg) => msg}
    </FormattedMessage>
  )
}

export function showChoosePermissionModal({
  id,
  ids,
  contentType,
  canWarrant,
  onConfirm,
  queryPackagePermission,
}: {
  id?: string
  ids?: Array<string>
  contentType: ContentType
  canWarrant?: boolean
  onConfirm?: any
  queryPackagePermission?: boolean
}) {
  showModal('CHOOSE_PERMISSION', {
    ids,
    contentId: id,
    canWarrant,
    contentType,
    onConfirm,
    queryPackagePermission,
  })
}

export function WithEventCheckBox(props: { checked: boolean; index: number; onChange: (isChecked: boolean) => void }) {
  const { onChange } = props

  function handleChange({ isChecked }: { isChecked: boolean; fromAll?: boolean; onToggle?: boolean }) {
    onChange(isChecked)
  }

  return (
    <Checkbox
      size={24}
      value="check-item"
      className="check-item"
      checked={props.checked}
      onChange={(_, isChecked) => handleChange({ isChecked })}
    />
  )
}
