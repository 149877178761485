import React, { ReactNode } from 'react'
import { toast, showModal, hideModal } from 'shared/actions'
import { FormattedMessage } from 'react-intl'
import {
  AddCartError,
  PurchaseStatus,
  ContentType,
  MeituAnalyzeSearchContentType,
  MeituAnalyzeContentType,
  MeituAnalyzeSearchPageName,
} from 'helpers/enums'
import { Text } from 'components/ui/base'
import FailedList from 'components/ui/failed-list'
import { batchDownloadSample } from 'api/download'

import { getSampleErrMsg, getPermissionStatus } from 'components/show/common/actions'
import { download, handleLocationToPageName, noop } from 'helpers/util'
import { getPermissionLists } from 'api/permission'

import { addBatchMaterialIntoCollection, addBatchCollectionByShare } from 'api/collection'
import { meituCollectConfirmClick } from 'helpers/analyze-meitu'

export function countCartNumber(checkedCount: number, cartCount: number) {
  let err

  if (cartCount >= 200) {
    err = AddCartError.HasFull
  } else if (checkedCount > 200 || checkedCount + cartCount > 200) {
    err = AddCartError.WillFull
  }
  return err
}

export function showDownloadFailedModal(resp: PLUS.DownloadFailedInfo) {
  showResultModal({
    title: <FormattedMessage id="page.cart.download-notice">{(msg) => msg}</FormattedMessage>,
    errData: resp.errors,
    successText: (
      <FormattedMessage id="page.cart.download-succeed-text" values={{ count: resp.total - resp.error_cnt }}>
        {(msg) => msg}
      </FormattedMessage>
    ),
    failedText: (
      <FormattedMessage
        id="page.cart.download-failed-text"
        values={{
          count: (
            <Text color="primary" size={16}>
              {resp.error_cnt}
            </Text>
          ),
        }}
      />
    ),
    hasReDownload: true,
  })
}

export function showResultModal({
  title,
  errData,
  successText,
  failedText,
  hasReDownload,
}: {
  title: ReactNode
  errData: Array<PLUS.FailedImage>
  successText: ReactNode
  failedText: ReactNode
  hasReDownload: boolean
}) {
  showModal('ALERT', {
    title,
    width: 550,
    content: (
      <>
        <Text color="spruce" mb={16}>
          {successText}
        </Text>
        {errData.length > 0 && (
          <>
            <Text color="black3" size={16} block={true}>
              {failedText}
            </Text>
            <FailedList errData={errData} hasReDownload={hasReDownload} />
          </>
        )}
      </>
    ),
    okText: <FormattedMessage id="actions.i.see">{(msg) => msg}</FormattedMessage>,
  })
}

export function showCartCountFullModal({ cartCount }: { cartCount: number }) {
  showModal('ALERT', {
    title: <FormattedMessage id="page.cart.add-failed">{(msg) => msg}</FormattedMessage>,
    content: (
      <>
        <FormattedMessage id="page.cart.unable-to-add">
          {(msg) => (
            <Text color="black3" size={16} mb={16}>
              {msg}
            </Text>
          )}
        </FormattedMessage>
        <FormattedMessage id="page.cart.maximum200-with-current-count" values={{ count: cartCount }}>
          {(msg) => (
            <Text color="spruce" block={true}>
              {msg}
            </Text>
          )}
        </FormattedMessage>
      </>
    ),
    okText: <FormattedMessage id="actions.i.see">{(msg) => msg}</FormattedMessage>,
  })
}

export function showChooseEditorialPermissionModal(
  checkedImages: Array<PLUS.EditorialGroupListImage | PLUS.EditorialImage>,
  onConfirm: (localPermissionId: number, rexPermissionId: number, permissions: Array<PLUS.Permission>) => void
) {
  showModal('CHOOSE_EDITORIAL_PERMISSION', {
    images: checkedImages,
    onConfirm,
  })
}

export function handleCheckedImages(checkedImages: Array<PLUS.EditorialGroupListImage | PLUS.EditorialImage>) {
  const rexImages = checkedImages.filter((_) => _.is_rex)
  const localImages = checkedImages.filter((_) => !_.is_rex)
  const rexCheckedIds = rexImages.map((_) => {
    return _.id
  })
  const localCheckedIds = localImages.map((_) => {
    return _.id
  })
  return { localImages, rexImages, localCheckedIds, rexCheckedIds }
}

export function transformDataToPurchase({
  images,
  permissions,
  permissionId,
  userWarrant,
}: {
  images: Array<PLUS.EditorialGroupListImage | PLUS.EditorialImage>
  permissions: Array<PLUS.Permission>
  permissionId: number
  userWarrant: PLUS.UserWarrant
}) {
  return images.map((item) => ({
    id: item.id,
    contentType: ContentType.Editorial,
    coverUrl: item.preview260_url,
    aspect: item.aspect,
    isPurchased: item.tag === 1,
    permission: permissions.find((_) => _.id === permissionId),
    warrant: userWarrant,
    isVector: false,
  }))
}

export function batchCollectionByShare(ids: Array<string>, onConfirm: () => void) {
  showModal('MOVE_COLLECTION', {
    type: 'add',
    addIntoCollection: (id, name) => addIntoCollection({ resourceIds: ids, id, name, from: 'share', onConfirm }),
  })
}

export function batchCollection(
  ids: Array<string>,
  onConfirm: any = noop,
  contentType?: ContentType,
  searchStatistics?: Record<string, any>
) {
  showModal('MOVE_COLLECTION', {
    type: 'add',
    addIntoCollection: (id, name) =>
      addIntoCollection({ resourceIds: ids, id, name, contentType, onConfirm, searchStatistics: searchStatistics }),
  })
}

async function addIntoCollection({
  resourceIds,
  id,
  name,
  onConfirm,
  contentType = 1,
  from,
  searchStatistics,
}: {
  resourceIds: Array<string>
  id: string
  name: string
  onConfirm?: () => void
  contentType?: ContentType
  from?: string
  searchStatistics?: Record<string, any>
}) {
  const promise = from === 'share' ? addBatchCollectionByShare : addBatchMaterialIntoCollection
  const params: any =
    from === 'share'
      ? { ids: resourceIds.join(','), collectionId: id }
      : { ids: resourceIds.join(','), collectionId: id, contentType }

  const { result, message } = await promise(params)

  if (result) {
    toast(
      <FormattedMessage id="toast.added-to-lightbox" values={{ name }}>
        {(msg) => msg}
      </FormattedMessage>
    )
    if (onConfirm) {
      onConfirm()
    }
    hideModal('MOVE_COLLECTION')

    try {
      const material_type =
        MeituAnalyzeSearchContentType[
          MeituAnalyzeContentType[contentType] as keyof typeof MeituAnalyzeSearchContentType
        ]

      const href = handleLocationToPageName(MeituAnalyzeContentType[contentType])
      const page_source = MeituAnalyzeSearchPageName[href as keyof typeof MeituAnalyzeSearchPageName]
      resourceIds.forEach((item) => {
        const itemData = searchStatistics.data.find((i) => i.id === item) || {}
        const itemIndex = searchStatistics.data.findIndex((i) => i.id === item) || 0
        meituCollectConfirmClick({
          key_word: searchStatistics.term,
          request_id: searchStatistics.searchid,
          card_id: item,
          author_id: itemData?.contributor_id,
          package_id: id,
          page_source,
          material_type,
          postion_id: itemIndex,
        })
      })
    } catch (error) {
      console.error(error)
    }
  } else {
    toast(message)
  }
}

// 批量下载小样
export async function editorialBatchDownloadSample({
  ids,
  groupId,
  manager,
  permissionId,
  toDownload,
  onConfirm,
  isChild,
}: {
  ids: Array<number>
  manager: PLUS.Manager
  permissionId?: number
  groupId?: number | string
  // 返回部分下架图片后，想继续下载toDownload传true
  toDownload?: boolean
  onConfirm?: () => void
  isChild?: boolean
}) {
  const { status, data, result } = await batchDownloadSample({
    ids,
    permissionId,
    groupId,
    toDownload,
  })
  if (!result) {
    return Promise.reject()
  }
  if (status === PurchaseStatus.Normal) {
    // 正常下载流程
    const { url, error_cnt } = data
    if (url) {
      download(url)
    }
    if (error_cnt) {
      showDownloadFailedModal(data)
    }
  } else if (status === PurchaseStatus.Removed) {
    // 已下架
    showModal('DOWNLOAD_PROMPTING', {
      tipTxt: <FormattedMessage id="errors.several-assets-pulled-out">{(msg) => msg}</FormattedMessage>,
      canContinue: true,
      onConfirm,
    })
  } else {
    return Promise.reject(getSampleErrMsg(status, manager, data, isChild))
  }
}

export async function getPermissions({
  isSample = 0,
  manager,
}: {
  // 1 小样 | 0 不是小样
  isSample?: 0 | 1
  canWarrant?: boolean
  manager: PLUS.Manager
}) {
  const { data }: any = await getPermissionLists({
    comp: isSample,
    license: ContentType.Editorial,
  })

  let parsedPermissions = []
  if (data && data.length) {
    parsedPermissions = data.map((item) => {
      const pStatus = getPermissionStatus({
        manager,
        permission: item,
      })

      return {
        ...item,
        extra: pStatus,
      }
    })
  }
  return parsedPermissions
}
