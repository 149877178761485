import React, { memo, useState, useContext, useEffect, useRef, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link as ScrollLink } from 'react-scroll'
import Link from 'next/link'
import Router, { useRouter } from 'next/router'
import Button from '@zcool/button'
import Flex from '@zcool/flex'
import Dropdown from '@zcool/dropdown'
import { List, ListItem } from '@zcool/list'
// import Badge from '@zcool/badge'
import Tooltip from '@zcool/tooltip'
import Icon from 'components/ui/icon'
import { Text, LeftListItem } from 'components/ui/base'
import { GlobalContext } from 'shared/context'
import { PLUS_CONTENTS, DAM_CONTENTS, ACTIONS, sharedValues } from 'shared/constants'
import { toast, showModal } from 'shared/actions'
import { useWindowScrollPosition, useDebounce } from 'shared/hooks'
import { logout } from 'api/auth'
import { getActiveMenuId, getIn, isCustomDomain, handleLocationToPageName } from 'helpers/util'
import { events } from 'helpers/event-emitter'
import {
  CustomMenuType,
  HellorfType,
  MeituAnalyzeSearchContentType,
  MeituAnalyzeSearchPageName,
  MeituAnalyzeSearchType,
} from 'helpers/enums'
import { getImageSearchTips } from 'api/search'
import {
  HeaderContainer,
  SearchButton,
  StyledDropdown,
  WhiteDropdown,
  Consultant,
  PositionWhiteDropdown,
  QRCode,
} from './style'
import Cart from './cart'
import Language from './language'
import InputDropDown from '../filters/search/input-dropdown'
import { meituSearchClick } from 'helpers/analyze-meitu'
import Cookies from 'js-cookie'
import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig()

const LEFT_TABS = [
  {
    href: '/welcome',
    id: 'header.overview',
  },
  {
    href: '/contents',
    id: 'header.contents',
  },
  {
    href: '/partner',
    id: 'header.partner',
  },
]

const CONTENTS_TABS = [{ href: '/contents/editorial' }, { href: '/contents/video' }, { href: '/contents/font' }]

interface Props {
  loginCallbackUrl?: string
}

export default memo(function Header({ loginCallbackUrl }: Props) {
  const { currentUser, domain, settings, updateCurrentUser } = useContext(GlobalContext)

  const { query } = useRouter()
  let { q = '' } = query
  if (Array.isArray(q)) {
    q = q[0]
  }

  const { pathname } = useRouter()
  const showMenu = settings.menuType !== CustomMenuType.TypeOne
  const damHomePage = settings.menuType !== CustomMenuType.TypeTwo
  const currentChannelId = getActiveMenuId(pathname, damHomePage)

  const $input = useRef(null)
  const isSearchPage = /^\/\w+\/search$/.test(pathname)
  const [keyword, setKeyword] = useState(isSearchPage ? q : '')
  const [channelId, setChannelId] = useState(currentChannelId)
  // 中文输入法输入英文字符问题 PLUS-5451
  const [isOnComposition, setIsOnComposition] = useState(false)
  const isDam = getIn(['is_dam'], currentUser)
  const isDamIntroduction = pathname.indexOf('/dam/introduction') > -1
  const isValidUser = [1, 2].includes(getIn(['status'], currentUser)) && !isDamIntroduction

  const [visible, setVisible] = useState(false)
  const [checkedIndex, setCheckedIndex] = useState(-1)
  const [inputDropDownItems, setInputDropDownItems] = useState([])
  const [initKeyword, setInitkeyword] = useState('')

  const { run } = useDebounce((val) => getAssociativeWords(val), 200)

  // 创意图片用途默认是商业用途
  const isCommercial = useMemo(() => channelId === PLUS_CONTENTS[0].id, [channelId])
  const routerOptions = channelId === PLUS_CONTENTS[0].id ? '&license=commercial' : ''

  // 搜索下拉弹窗部分不显示IP授权
  let CONTENTS = [...PLUS_CONTENTS]

  if (isDam) {
    CONTENTS = CONTENTS.concat(DAM_CONTENTS)
  }

  // useWindowScrollPosition 会一直监听页面滚动 导致header一直render
  const preHeaderTransparent =
    [...LEFT_TABS, ...CONTENTS_TABS].some((tab) => pathname.indexOf(tab.href) > -1) || isDamIntroduction
  const position = useWindowScrollPosition({ use: preHeaderTransparent })

  const isHeaderTransparent = position && position.y === 0

  useEffect(() => {
    const newChannelId = getActiveMenuId(pathname, damHomePage)

    if (newChannelId !== channelId) {
      setChannelId(newChannelId)
    }
  }, [pathname])

  useEffect(() => {
    if (q) {
      if (q !== keyword && isSearchPage) {
        setKeyword(q.toString())
      }
    } else {
      setKeyword('')
    }
  }, [q])

  useEffect(() => {
    handleChangeVisible(keyword)
  }, [keyword])

  useEffect(() => {
    Router.events.on('beforeHistoryChange', handleRouteChange)
    return () => {
      Router.events.off('beforeHistoryChange', handleRouteChange)
    }
  }, [])

  function handleRouteChange() {
    setInputDropDownItems([])
  }

  function handleComposition(e: React.CompositionEvent<HTMLInputElement>) {
    if (e.type === 'compositionend') {
      setTimeout(() => {
        setIsOnComposition(false)
      }, 100)
    } else {
      setIsOnComposition(true)
    }
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    let { value } = e.currentTarget

    if (value.indexOf('，')) {
      value = value.replace(/，/g, ',')
    }

    if (channelId === 'nav.creative' && value) {
      run(value)
    }

    setInputDropDownItems([])
    setKeyword(value)
    setInitkeyword(value)
  }

  /** 美图搜索点击埋点
   * @param k string 搜索词
   * @param pathPrefix string 当前搜索类型
   */
  function meituSearchClickFn(k, pathPrefix = 'creative') {
    const href = handleLocationToPageName(pathPrefix)
    try {
      meituSearchClick({
        key_word: k,
        page_name: MeituAnalyzeSearchPageName[href as keyof typeof MeituAnalyzeSearchPageName],
        search_type: MeituAnalyzeSearchType.SearchButtom,
        search_content_type: MeituAnalyzeSearchContentType[pathPrefix as keyof typeof MeituAnalyzeSearchContentType],
      })
    } catch (error) {
      console.error(error)
    }
  }

  function handleSearch() {
    const k = keyword && keyword.trim()
    if (!k) {
      toast(<FormattedMessage id="toast.empty">{(msg) => msg}</FormattedMessage>)
      return
    }

    // channel: nav.creative => creative
    const pathPrefix = channelId.replace('nav.', '')

    if (pathPrefix !== 'dam') {
      // dam不上报
      meituSearchClickFn(k, pathPrefix)
    }

    if (pathPrefix === HellorfType.Music) {
      const queryOption: Record<string, any> = {
        ...query,
        q: k,
      }

      if (isCommercial) queryOption.license = 'commercial'

      Router.push({ pathname: '/music/search', query: queryOption })
      return
    }

    Router.push(`/${pathPrefix}/search?q=${encodeURIComponent(k)}${routerOptions}`)
  }

  function handleInputKeyup(e: React.KeyboardEvent<HTMLInputElement>) {
    if (!isOnComposition && e.keyCode === 13) {
      handleSearch()
    }
  }

  function handleLogout(e: React.MouseEvent<HTMLLIElement>) {
    e.preventDefault()

    logout().then(() => {
      if (isValidUser) {
        // 利用 nginx 的自动跳转 (定制域名访问/welcome会自动跳转到登录页)，省去 login 里的逻辑判断
        window.location.href = process.env.ENV === 'development' || isCustomDomain(domain) ? '/welcome' : `/login`
      }

      setTimeout(() => {
        updateCurrentUser(null)
      }, 1000)

      Cookies.remove('zcool_mt', {
        domain: publicRuntimeConfig.COOKIE_DOMAIN,
      })
    })
  }

  // 搜索联想下拉框处理逻辑
  function handleChangeVisible(value: string) {
    if (channelId === 'nav.creative' && value) {
      setVisible(true)
    } else {
      setVisible(false)
      setCheckedIndex(-1)
    }
  }

  function menuCallback(obj: { index: number; value: string }) {
    if (obj) {
      const pathPrefix = channelId.replace('nav.', '')

      setCheckedIndex(!obj.index ? 0 : obj.index)
      setInputDropDownItems([])
      setKeyword(obj.value)

      if (pathPrefix === HellorfType.Music) {
        const queryOption: Record<string, any> = {
          ...query,
          q: obj.value,
        }

        if (isCommercial) queryOption.license = 'commercial'

        Router.push({ pathname: '/music/search', query: queryOption })
        return
      }

      Router.push(`/${pathPrefix}/search?q=${encodeURIComponent(obj.value)}${routerOptions}`)
    }
    setVisible(false)
    setCheckedIndex(-1)
  }

  function menuKeyDownCallback(value: string) {
    let nextIndex = -1

    if (!inputDropDownItems.length) {
      return
    }

    if (value === 'pageDown') {
      nextIndex = checkedIndex === inputDropDownItems.length - 1 ? -1 : checkedIndex + 1
    }
    if (value === 'pageUp') {
      nextIndex = checkedIndex >= 0 ? checkedIndex - 1 : inputDropDownItems.length - 1
    }
    if (value === 'enter') {
      nextIndex = checkedIndex
      setVisible(false)
      handleSearch()
    }
    setCheckedIndex(nextIndex)

    if (nextIndex === -1) {
      setKeyword(initKeyword)
    } else if (value !== 'enter' && inputDropDownItems[nextIndex]) {
      setKeyword(inputDropDownItems[nextIndex].value)
    }
  }

  function menuMouseOverCallback(index: number) {
    setCheckedIndex(index)
  }

  async function getAssociativeWords(value: string) {
    const res = await getImageSearchTips({ keyword: value })

    let result = []
    if (res.data) {
      result = res.data.map((item, index) => {
        return { value: item.keyword, index }
      })
    }

    setInputDropDownItems(result)
  }

  return (
    <HeaderContainer transparent={isHeaderTransparent}>
      {isValidUser ? (
        <>
          <Flex valign="center">
            {showMenu && settings.newNavbar ? (
              <ToggleDrawer>
                <Icon className="icon" glyph="layout-1" />
              </ToggleDrawer>
            ) : null}
            <Link href={damHomePage ? '/dam' : '/'}>
              <a className="logo" />
            </Link>
          </Flex>
          <div className="search__box">
            {showMenu ? (
              <StyledDropdown text={<FormattedMessage id={channelId} />}>
                <List>
                  {CONTENTS.map((nav) => (
                    <LeftListItem key={nav.id} selected={nav.id === channelId} onClick={() => setChannelId(nav.id)}>
                      <FormattedMessage id={nav.id} />
                    </LeftListItem>
                  ))}
                </List>
              </StyledDropdown>
            ) : null}
            <FormattedMessage id="placeholder.search">
              {(msg: string) => (
                <InputDropDown
                  visible={visible}
                  overlay={inputDropDownItems}
                  checkedIndex={checkedIndex}
                  menuClick={menuCallback}
                  menuKeyDown={menuKeyDownCallback}
                  menuMouseOver={menuMouseOverCallback}
                >
                  <input
                    ref={$input}
                    type="text"
                    value={keyword}
                    onChange={handleChange}
                    onFocus={() => handleChangeVisible(keyword)}
                    onKeyUp={handleInputKeyup}
                    placeholder={msg}
                    onCompositionStart={handleComposition}
                    onCompositionEnd={handleComposition}
                    style={{ flex: 1 }}
                  />
                </InputDropDown>
              )}
            </FormattedMessage>

            {channelId === 'nav.creative' ? (
              <FormattedMessage id="modal.image-search.title">
                {(msg) => (
                  <Tooltip placement="left" title={msg}>
                    <Icon glyph="creative-photo" onClick={() => showModal('IMAGE_SEARCH')} />
                  </Tooltip>
                )}
              </FormattedMessage>
            ) : null}
            <SearchButton type="primary" size="large" onClick={handleSearch}>
              <Icon glyph="search" />
            </SearchButton>
          </div>
          <nav className="buttons">
            {/* 充值入口 */}
            {currentUser.is_shein && (
              <Button className="shein-topup" onClick={() => showModal('SHEIN_TOPUP', {})}>
                <FormattedMessage id="nav.top-up" />
              </Button>
            )}
            {showMenu || !isCustomDomain(domain) ? <Cart /> : null}
            {/* <Link href="/notifications">
              <a>
                <Badge dot={true} offset={[3, 3]}>
                  <Icon
                    glyph="bell"
                    size={20}
                    className="icon"
                    hoverTitle="消息"
                  />
                </Badge>
              </a>
            </Link> */}

            <PositionWhiteDropdown
              // isOpen={true}
              icon="none"
              text={
                <FormattedMessage id="common.cs">
                  {(msg: string) => (
                    <Icon className="icon" glyph="consultant" size={20} color="white" hoverTitle={msg} />
                  )}
                </FormattedMessage>
              }
              placement="bottom"
            >
              <Consultant>
                <QRCode src={currentUser?.manager?.qr_code_url}></QRCode>
                <div>
                  {currentUser.manager ? (
                    <Text mb={8} color="spruce">
                      <FormattedMessage id={currentUser?.is_zhrmt ? 'header.manager' : 'header.copyright-consultant'}>
                        {(msg) => <span className="form-item-label">{msg}</span>}
                      </FormattedMessage>
                      {`${currentUser.manager.realname} ${currentUser.manager.phone}`}
                    </Text>
                  ) : null}
                  <Text color="spruce" block={true}>
                    <FormattedMessage id="header.call-us">
                      {(msg) => <span className="form-item-label">{msg}</span>}
                    </FormattedMessage>
                    400-6066-100
                  </Text>
                </div>
              </Consultant>
            </PositionWhiteDropdown>
            <WhiteDropdown
              text={
                <Link href="/account">
                  <a>
                    <Text color="white" truncate="90px">
                      {currentUser.username}
                    </Text>
                  </a>
                </Link>
              }
              placement="bottom"
            >
              <List>
                <FormattedMessage id="header.my-account">
                  {(msg) => (
                    <Link href="/account">
                      <LeftListItem>
                        <a>{msg}</a>
                      </LeftListItem>
                    </Link>
                  )}
                </FormattedMessage>
                <FormattedMessage id="nav.account.lightbox">
                  {(msg) => (
                    <Link href="/account/collection">
                      <LeftListItem>
                        <a>{msg}</a>
                      </LeftListItem>
                    </Link>
                  )}
                </FormattedMessage>
                <FormattedMessage id="header.log-out">
                  {(msg) => <LeftListItem onClick={handleLogout}>{msg}</LeftListItem>}
                </FormattedMessage>
              </List>
            </WhiteDropdown>
          </nav>
        </>
      ) : (
        <NotValid
          pathname={pathname}
          isDamIntroduction={isDamIntroduction}
          loginCallbackUrl={loginCallbackUrl}
          onLogout={handleLogout}
        />
      )}
    </HeaderContainer>
  )
})

function ToggleDrawer({ children }: { children: React.ReactNode }) {
  let isOpen = false

  function handleEnter(e: React.MouseEvent<HTMLSpanElement>) {
    e.stopPropagation()

    if (sharedValues.timer) {
      clearTimeout(sharedValues.timer)
    }
    sharedValues.timer = setTimeout(() => {
      isOpen = true
      events.emit(ACTIONS.TOGGLE_DRAWER, isOpen)
    }, 200)
  }

  function handleLeave(e: React.MouseEvent<HTMLSpanElement>) {
    e.stopPropagation()

    if (sharedValues.timer) {
      clearTimeout(sharedValues.timer)
    }
    sharedValues.timer = setTimeout(() => {
      isOpen = false
      events.emit(ACTIONS.TOGGLE_DRAWER, isOpen)
    }, 200)
  }

  return (
    <span onMouseEnter={handleEnter} onMouseLeave={handleLeave} className="drawer">
      {children}
    </span>
  )
}

function NotValid({
  pathname,
  isDamIntroduction,
  loginCallbackUrl,
  onLogout,
}: {
  pathname: string
  isDamIntroduction: boolean
  loginCallbackUrl: string
  onLogout: (e: React.MouseEvent<HTMLLIElement>) => void
}) {
  const { currentUser } = useContext(GlobalContext)

  const hasApplied = (currentUser && currentUser.status === 0) || isDamIntroduction

  function handleLogin() {
    Router.push(`/login${loginCallbackUrl ? `?callback=${encodeURIComponent(loginCallbackUrl)}` : ''}`)
  }

  return (
    <>
      <Link href="/welcome">
        <a className="logo" />
      </Link>
      <nav className="left__tabs">
        {LEFT_TABS.map((tab) => (
          <Link key={tab.href} href={tab.href}>
            <a className={`item ${pathname.indexOf(tab.href) > -1 ? 'active' : ''}`}>
              <FormattedMessage id={tab.id} />
            </a>
          </Link>
        ))}
      </nav>
      <Language />
      {hasApplied ? null : (
        <ScrollLink to="apply-form" duration={300} offset={-80} smooth={true}>
          <Button size="small" type="primary" className="try">
            <FormattedMessage id="actions.apply">{(msg) => msg}</FormattedMessage>
          </Button>
        </ScrollLink>
      )}
      {currentUser && !currentUser.anonymous ? (
        <Dropdown
          text={
            <Text color="white" truncate="90px">
              {currentUser.username}
            </Text>
          }
          modifiers={{
            computeStyle: {
              enabled: false,
            },
          }}
        >
          <List>
            <FormattedMessage id="header.log-out">
              {(msg) => <ListItem onClick={onLogout}>{msg}</ListItem>}
            </FormattedMessage>
          </List>
        </Dropdown>
      ) : (
        <Button type="secondary" size="small" className="login" onClick={handleLogin}>
          <FormattedMessage id="header.log-in">{(msg) => msg}</FormattedMessage>
        </Button>
      )}
    </>
  )
}
