import { ContentType } from 'helpers/enums'
import API from './index'
import {
  API_PRODUCT_PERMISSIONS,
  API_SAMPLE_PERMISSION,
  API_PERMISSIONS_LIST,
  API_DEFAULT_PERMISSION,
  API_COMBO_PERMISSION,
  API_ALL_COMBO_PERMISSION,
} from './endpoints'

interface PermissionParams {
  contentType: ContentType
  id: string
  warrantId?: number
  headers?: PLUS.Headers
}

interface EditorialPermissionParams {
  comp?: number
  license?: ContentType
  headers?: PLUS.Headers
}

export function getProductPermissions({ contentType, id, warrantId, headers }: PermissionParams) {
  return API.get(API_PRODUCT_PERMISSIONS, {
    params: {
      able_type: contentType,
      able_id: id,
      user_warrant_id: warrantId,
    },
    headers,
  }).catch((err) => {
    console.error('获取作品权限失败', err)
    return { result: false, data: [] }
  })
}

export function getProductComboPermissions({ id, warrantId, headers }: any) {
  return API.get(API_COMBO_PERMISSION, {
    params: {
      able_id: id,
      user_warrant_id: warrantId,
    },
    headers,
  }).catch((err) => {
    console.error('获取作品权限失败', err)
    return { result: false, data: [] }
  })
}

export function getAllProductComboPermissions() {
  return API.get(API_ALL_COMBO_PERMISSION).catch((err) => {
    console.error('获取作品权限失败', err)
    return { result: false, data: [] }
  })
}

export function getSamplePermission({ contentType, id, headers }: PermissionParams) {
  return API.get(API_SAMPLE_PERMISSION, {
    params: { able_type: contentType, able_id: id },
    headers,
  }).catch((err) => {
    console.error('获取小样权限失败', err)
    return { result: false, data: [] }
  })
}

/**
 * 编辑图权限列表
 * @param comp 1小样类型，0或者不传不是小样
 * @param license
 */
export function getPermissionLists({ comp, license, headers }: EditorialPermissionParams) {
  return API.get(API_PERMISSIONS_LIST, {
    params: {
      comp,
      license,
    },
    headers,
  }).catch((err) => {
    console.error('获取编辑图权限列表失败', err)
    return { result: false, data: [] }
  })
}

// 获取默认权限
export function getDefaultPermission(headers: PLUS.Headers) {
  return API.get(API_DEFAULT_PERMISSION, { headers }).catch((err) => {
    console.error('获取默认权限失败', err)
    return {}
  })
}
