import React, { ReactNode } from 'react'
import Flex from '@zcool/flex'
import { spacing } from '@zcool/theme'
import styled from 'styled-components'

interface Props {
  children: ReactNode
  tip: ReactNode
  background?: string
  px?: number
  py?: number
}

const IconAreaStyle = styled(Flex)<{
  px?: number
  py?: number
  background?: string
}>`
  position: relative;

  &:hover .tooltip {
    visibility: visible;
  }

  .tooltip {
    visibility: hidden;
    position: absolute;
    transform: translate(-50%, -100%);
    top: 0;
    left: 50%;

    &__content {
      border-radius: 2px;
      background: ${(props) => props.background};
      white-space: nowrap;
      padding: ${({ px, py }) => `${py}px ${px}px`};
    }

    &__straw {
      width: 100%;
      height: ${spacing.sm}px;
    }
  }
`

export default function IconArea({ children, tip, px, py, background }: Props) {
  return (
    <IconAreaStyle px={px} py={py} background={background} valign="center">
      <div className="tooltip">
        <div className="tooltip__content">{tip}</div>
        <div className="tooltip__straw" />
      </div>
      {children}
    </IconAreaStyle>
  )
}

IconArea.defaultProps = {
  px: 12,
  py: 8,
  background: 'rgba(0, 0, 0, 0.45)',
}
