import React, { FC, useMemo, useEffect, useRef } from 'react'
import { noop } from 'helpers/util'

const ReactAudio: FC<{
  src: string
  isPlaying: boolean
  seekIntent?: number
  isMuted?: boolean
  volume: number
  onTimeUpdate?: (event: React.SyntheticEvent<HTMLAudioElement, Event>) => void
  onEnded?: (event: React.SyntheticEvent<HTMLAudioElement, Event>) => void
  onCanPlayThrough?: (event: React.SyntheticEvent<HTMLAudioElement, Event>) => void
}> = ({ src, isPlaying, isMuted, seekIntent, volume, onTimeUpdate, onEnded, onCanPlayThrough }) => {
  const audioRef = useRef(null)

  // 音量
  useEffect(() => {
    const audio = audioRef.current
    audio.volume = volume
  }, [volume])

  // 是否静音
  useEffect(() => {
    if (isMuted !== undefined) {
      const audio = audioRef.current
      audio.muted = isMuted
    }
  }, [isMuted])

  useEffect(() => {
    if (seekIntent !== undefined && !Number.isNaN(seekIntent)) {
      const audio = audioRef.current
      audio.currentTime = seekIntent
    }
  }, [seekIntent])

  // 播放/暂停 切换音乐
  useEffect(() => {
    const audio = audioRef.current

    if (isPlaying) {
      audio.play().catch((e) => console.error('error in ReactAudio', src, e))
    } else {
      audio.pause()
    }
  }, [isPlaying, src])

  if (!onTimeUpdate) {
    // eslint-disable-next-line no-param-reassign
    onTimeUpdate = noop
  }

  if (!onEnded) {
    // eslint-disable-next-line no-param-reassign
    onEnded = noop
  }

  if (!onCanPlayThrough) {
    // eslint-disable-next-line no-param-reassign
    onCanPlayThrough = noop
  }

  return useMemo(
    () => (
      <audio
        ref={audioRef}
        src={src}
        onTimeUpdate={onTimeUpdate}
        onEnded={onEnded}
        onCanPlayThrough={onCanPlayThrough}
      />
    ),
    [src, volume, isMuted, seekIntent]
  )
}

export default ReactAudio
