import React, { memo } from 'react'
import { FormattedMessage } from 'react-intl'
import Router from 'next/router'
import styled from 'styled-components'
import Flex from '@zcool/flex'
import { LayoutImage } from 'components/ui/image'
import { Text } from 'components/ui/base'
import MusicCover from 'components/music/cover'

import { palette } from '@zcool/theme'
import { ContentType } from 'helpers/enums'

const Container = styled(Flex)`
  width: 100%;
  max-height: 360px;
  padding: 1px;
  overflow-y: auto;
  margin: 24px 0 0 0;

  .item {
    width: 130px;
    margin: 0 30px 16px 0;

    &:nth-child(3n) {
      margin-right: 0;
    }

    .text {
      width: 100%;
      font-size: 12px;
      color: ${palette.spruce};
      text-align: left;
    }
  }
`

interface Props {
  errData: Array<PLUS.FailedImage & { resource_type?: ContentType }>
  hasReDownload?: boolean
  contentType?: ContentType
}

function FailedList({ errData, hasReDownload }: Props) {
  let timer = null
  let style

  function handleReDownload(url: string) {
    if (timer) {
      return
    }
    Router.push(url)
    // 5秒内不可重复点击下载
    timer = setTimeout(() => {
      clearTimeout(timer)
      timer = null
    }, 5 * 1000)
  }

  if (!errData.length) {
    return null
  }

  if (errData.length < 3) {
    style = { margin: (errData.length - 1) * 15 }
  }

  return (
    <Container wrap="wrap" halign={errData.length < 3 ? 'center' : 'flex-start'}>
      {errData.map(({ id, cover, aspect, title, reason, resource_type, url }) => (
        <div key={id} className="item" style={style}>
          <LayoutImage
            contentType={resource_type}
            layoutHeight={130}
            layoutWidth={130}
            src={cover}
            aspect={resource_type === ContentType.Music ? 1.35 : aspect}
            position="static"
          >
            {resource_type === ContentType.Music ? <MusicCover title={title} id={id} /> : null}
          </LayoutImage>
          <Text size={12} color="pewter" mt={4} mb={16}>
            ID：{id}
          </Text>

          <div className="text">
            <span className="form-item-label">
              <FormattedMessage id="common.failed-reason" />
            </span>

            {reason}
          </div>
          {hasReDownload && !!url && (
            <span className="link__underline" onClick={() => handleReDownload(url)}>
              <FormattedMessage id="actions.re-download" />
            </span>
          )}
        </div>
      ))}
    </Container>
  )
}

export default memo(FailedList)
