import React from 'react'

export const GlobalContext = React.createContext<PLUS.GlobalContext>(null)

export const CreativeContext = React.createContext<PLUS.CreativeImageShow>(null)

export const EditorialContext = React.createContext<PLUS.EditorialImageShow>(null)

export const FontContext = React.createContext<PLUS.FontShow>(null)

export const VideoContext = React.createContext<PLUS.VideoShow>(null)

export const DamEditContext = React.createContext<DAM.DAMEditFilesContext>(null)

export const HandleBatchContext = React.createContext<PLUS.HandleBatchOperation>(null)
