import React, { FC, useMemo } from 'react'
import styled from 'styled-components'
import { formatSecondNumber } from 'helpers/util'
import Waveform from '@zcool/waveform'

const WaveFormContainer = styled.div<{ visible: boolean }>`
  position: relative;
  transition: opacity 0.2s ease-in;
  opacity: ${(props) => (props.visible ? 1 : 0)};

  canvas {
    height: 44px;
    width: 100%;
    image-rendering: pixelated;
    display: block;
    overflow: visible;
  }

  .ghost-progress-label {
    position: absolute;
    text-align: center;
    line-height: 24px;
    width: 48px;
    height: 24px;
    font-size: 12px;
    color: white;
    background-color: black;
    top: 10px;
    pointer-events: none;
    transform: translateX(-100%);
  }
`

const WaveForm: FC<{
  progress: number // 音轨的播放进度
  duration: number // 音轨时长
  data: Array<number> // 波形数据
  onMouseUp?: (progress: number) => void // 音轨点击时触发
}> = ({ progress, duration, data, onMouseUp }) => {
  return useMemo(
    () => (
      <WaveFormContainer visible={data.length > 0}>
        <Waveform
          progress={progress}
          data={data}
          onMouseUp={onMouseUp}
          showGhostProgress={!!onMouseUp}
          renderGhostProgress={(x, p) => (
            <span className="ghost-progress-label" style={{ left: x }}>
              {formatSecondNumber(Math.round(p * duration))}
            </span>
          )}
        />
      </WaveFormContainer>
    ),
    [progress, data]
  )
}

export default WaveForm
