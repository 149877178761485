import { ContentType } from 'helpers/enums'
import API from './index'
import {
  API_CART,
  API_CART_UPDATE,
  API_CART_UPDATE_BATCH,
  API_CART_DESTROY,
  API_PURCHASE_BATCH,
  API_PURCHASE,
  API_CART_UPDATE_AUTH,
  API_CART_UPDATE_PERMISSION,
  API_CART_ADD_BATCH,
  API_CART_ADD_INTO_COLLECTION,
  API_CART_COUNT,
  API_COMBO_PERMISSION,
} from './endpoints'
import { getDownloadTimeoutInterval } from './util'

export function getCart(headers?: PLUS.Headers) {
  return API.get(API_CART, { headers, timeout: 30 * 1000 }).catch((err) => {
    console.error('error in API_CART ', err)
    return { result: false, data: [] }
  })
}

export function updateCart({
  id,
  permissionId,
  userWarrantId,
  packagePermissionId = undefined,
}: {
  id: string
  permissionId?: number
  userWarrantId?: number
  packagePermissionId?: number
}) {
  return API.post(`${API_CART_UPDATE}/${id}`, {
    id,
    permission_id: permissionId,
    user_warrant_id: userWarrantId,
    package_permission_id: packagePermissionId,
  }).catch((err) => {
    console.error('error in API_CART_UPDATE ', err)
    return { result: false, status: 'something went wrong' }
  })
}

export function updateCartBatch({ ids, userWarrantId }: { ids: Array<string>; userWarrantId?: number }) {
  return API.post(API_CART_UPDATE_BATCH, {
    ids: ids.join(','),
    user_warrant_id: userWarrantId,
  }).catch((err) => {
    console.error('error in API_CART_UPDATE_BATCH ', err)
    return { result: false }
  })
}

export function destroy(ids: Array<string>) {
  return API.post(API_CART_DESTROY, { ids: ids.join(',') }).catch((err) => {
    console.error('error in API_CART_DESTROY ', err)
    return err
  })
}

export function updateAuth(ids: Array<number>, warrentId: number) {
  return API.post(API_CART_UPDATE_AUTH, {
    ids: ids.join(','),
    user_warrant_id: warrentId,
  }).catch((err) => {
    console.error('error in API_CART_UPDATE_AUTH ', err)
    return err
  })
}

export function updatePermission(ids: Array<number>, permissionId: number) {
  return API.post(API_CART_UPDATE_PERMISSION, {
    ids: ids.join(','),
    permission_id: permissionId,
  }).catch((err) => {
    console.error('error in API_CART_UPDATE_PERMISSION ', err)
    return err
  })
}

interface CartProps {
  id: string
  contentType: ContentType
  permissionId?: number
  packagePermissionId?: number
  userWarrantId?: number
  tags?: string
}

export function addToCart({
  id,
  contentType,
  permissionId,
  userWarrantId,
  packagePermissionId = undefined,
}: CartProps) {
  return API.post(API_CART, {
    able_id: id,
    able_type: contentType === ContentType.Editorial ? ContentType.Creative : contentType,
    permission_id: permissionId,
    package_permission_id: packagePermissionId,
    user_warrant_id: userWarrantId,
  }).catch((err) => {
    console.error('error in addToCart ', err)
    return { result: false }
  })
}

/**
 *
 * @param id 	素材ID
 * @param type 素材类型 1创意图片 2视频 3字体
 * @param permissionId 权限ID
 * @param warrantId 用户授权ID
 */
export function purchase({
  id,
  contentType,
  permissionId,
  packagePermissionId = undefined,
  userWarrantId,
  tags,
}: CartProps) {
  return API.post(API_PURCHASE, {
    able_id: id,
    able_type: contentType,
    permission_id: permissionId,
    package_permission_id: packagePermissionId,
    user_warrant_id: userWarrantId,
    tags,
  }).catch((err) => {
    console.error('error in purchase ', err)
    return err
  })
}

/**
 *
 * @param ids 购物车id
 */
export function purchaseBatch(ids: Array<number>, tags?: string) {
  return API.post(
    API_PURCHASE_BATCH,
    {
      cart_ids: ids.join(','),
      tags,
    },
    {
      timeout: getDownloadTimeoutInterval(),
    }
  ).catch((err) => {
    console.error('error in API_PURCHASE_BATCH ', err)
    return err
  })
}

export function purchaseBatchDetail(id: number, headers: PLUS.Headers) {
  return API.get(`${API_PURCHASE_BATCH}/${id}`, {
    headers,
  }).catch((err) => {
    console.error('error in API_PURCHASE_BATCH_DETAIL ', err)
    return err
  })
}

/**
 *
 * @param ids 	素材ID
 * @param contentType 素材类型  1图片 2视频 3字体 4音频
 * @param permissionId 权限ID
 * @param userWarrantId 用户授权ID
 */
export function addCartBatch({
  ids,
  contentType = ContentType.Creative,
  permissionId,
  userWarrantId,
  packagePermissionId = undefined,
}: {
  ids: Array<string>
  contentType?: ContentType
  permissionId: number
  packagePermissionId?: number
  userWarrantId?: number
}) {
  return API.post(API_CART_ADD_BATCH, {
    able_type: contentType === ContentType.Editorial ? ContentType.Creative : contentType,
    ids: ids.join(','),
    permission_id: permissionId,
    package_permission_id: packagePermissionId,
    user_warrant_id: userWarrantId,
  }).catch((err) => {
    console.error('error in API_CART_ADD_BATCH ', err)
    return { result: false }
  })
}

/**
 * @param ids 购物车订单ID数组
 * @param collectionId 收藏夹ID
 */
export function addCartRecordIntoCollection({ ids, collectionId }: { ids: string; collectionId: number }) {
  return API.post(API_CART_ADD_INTO_COLLECTION, {
    ids,
    favorite_group_id: collectionId,
  })
}

// 购物车数量
export function getCartCount(headers?: PLUS.Headers) {
  return API.get(API_CART_COUNT, {
    headers,
  }).catch((err) => {
    console.error('error in API_CART_COUNT', err)
    return err
  })
}

// 套餐权限列表
export function getComboPermissions() {
  return API.get(API_COMBO_PERMISSION)
}
