import { canUseDOM } from './util'
import cookie from 'js-cookie'

// 神策统计
export const analyzeSensors = async (user) => {
  const env = process.env.ENV

  let SENSORS_SERVER_URL = ''
  if (env === 'production') {
    SENSORS_SERVER_URL = 'https://data.zcool.com.cn/sa?project=production&site=hellorfplus&from=pc'
  } else {
    SENSORS_SERVER_URL = 'https://data-test.in.zcool.cn/sa?project=test&site=hellorf'
  }

  if (canUseDOM() && !window.zcoolSensors) {
    const sensors = (await import('@zcool/data-track-web')).default

    sensors.init({
      server_url: SENSORS_SERVER_URL,
      heatmap: {
        scroll_notice_map: 'default',
      },
      // 开启单页面自动采集 $pageview
      is_track_single_page: true,
      // 是否控制台输出logger
      show_log: process.env.ENV !== 'production',
      send_type: 'beacon',
      // 开启cookie加密
      encrypt_cookie: true,
    })

    if (user) {
      sensors.login(user.passport_id)
    } else {
      sensors.login(user?.passport_id)
      sensors.logout()
    }

    sensors.registerPage({
      // 通行证id
      zc_user_id: user?.passport_id || 0,
      // 海洛id
      zc_plus_hellorf_user_id: user?.id || 0,
      zc_user_type: user ? 'people' : 'guest',
      zc_client_id: cookie?.get('device_id'),
    })
    sensors.quick('autoTrack')
  }
}
