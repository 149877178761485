import React, { memo } from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import Flex from '@zcool/flex'
import Tooltip from '@zcool/tooltip'
import Icon from 'components/ui/icon'
import { animateScroll as scroll } from 'react-scroll'

const StyledBackToTop = styled(Flex)`
  position: fixed;
  right: 8px;
  bottom: 100px;
  width: 36px;
  height: 36px;
  border-radius: 4px;
  background-color: rgba(56, 56, 56, 0.5);

  svg {
    color: #fff;
  }
`

export default memo(function BackToTop() {
  function scrollToTop() {
    scroll.scrollToTop({ duration: 300 })
  }

  return (
    <StyledBackToTop halign="center" valign="center">
      <Tooltip
        title={<FormattedMessage id="tooltip.icon.back-to-top">{(msg) => msg}</FormattedMessage>}
        placement="left"
        overlayStyle={{ margin: 14 }}
      >
        <Icon glyph="arrow-up" size={24} onClick={scrollToTop} />
      </Tooltip>
    </StyledBackToTop>
  )
})
