import React, { useEffect, useState } from 'react'
import { Portal } from 'react-portal'
import Toast from '@zcool/toast'
import { events } from 'helpers/event-emitter'
import { getUid } from 'helpers/util'
import { ACTIONS } from 'shared/constants'

export default function Toasts() {
  const [toasts, setToasts] = useState([])

  useEffect(() => {
    events.on(ACTIONS.ADD_TOAST, addToast)

    // 组件卸载时注销
    return () => {
      events.off(ACTIONS.ADD_TOAST, addToast)
    }
  }, [])

  function addToast({ message, timeout }: { message: React.ReactNode; timeout: number }) {
    const id = getUid()

    setToasts(toasts.concat({ message, id }))

    setTimeout(() => {
      removeToast(id)
    }, timeout)
  }

  function removeToast(id: string) {
    setToasts((t) => t.filter((_) => _.id !== id))
  }

  return (
    <Portal>
      {toasts.map((t) => (
        <Toast key={t.id}>{t.message}</Toast>
      ))}
    </Portal>
  )
}
