import { ContentType } from 'helpers/enums'
import API from './index'
import {
  API_COLLECTION_LIST,
  API_COLLECTION_LAST,
  API_COLLECTION_COUNT,
  API_COLLECTION_CREATE,
  API_COLLECTION_DELETE,
  API_COLLECTION_RENAME,
  API_COLLECTION_BY_SHARE,
  API_COLLECTION_SHARE_LIST,
  API_COLLECTION_MATERIAL_ADD,
  API_COLLECTION_MATERIAL_MOVE,
  API_COLLECTION_MATERIAL_LIST,
  API_COLLECTION_MATERIAL_CANCEL,
  API_COLLECTION_MATERIAL_BATCH_ADD,
  API_COLLECTION_MATERIAL_CANCEL_BATCH,
} from './endpoints'

// TODO: 参数逐步改为JSON格式，避开参数顺序的问题

// 新建收藏夹
export function createCollection(name: string) {
  return API.post(API_COLLECTION_CREATE, { name })
}

// 获取收藏夹列表
export function getCollectionList({
  headers,
  id,
  contentType,
  order = 'created_at',
  by = 'descend',
  page,
  per_page = 23,
  name,
}: {
  headers?: PLUS.Headers
  id?: number
  contentType?: ContentType
  /** 按更新时间/创建时间排序 */
  order?: string
  by?: string
  page?: number
  per_page?: number
  name?: string
}) {
  return API.get(API_COLLECTION_LIST, {
    headers,
    params: {
      resource_id: id,
      resource_type: contentType,
      order,
      by,
      page,
      per_page,
      name,
    },
  })
}

// 删除收藏夹
export function deleteCollection(id: number | string) {
  return API.post(API_COLLECTION_DELETE, { id })
}

// 重命名收藏夹
export function renameCollection(id: number | string, name: string) {
  return API.post(API_COLLECTION_RENAME, { id, name })
}

// 添加素材至收藏夹
export function addMaterialIntoCollection({
  id,
  contentType,
  collectionId,
}: {
  id: string
  contentType: number
  collectionId?: number
}) {
  return API.post(API_COLLECTION_MATERIAL_ADD, {
    id,
    resource_type: contentType,
    group_id: collectionId,
  })
}

export function addBatchMaterialIntoCollection({
  ids,
  contentType = 1,
  collectionId,
}: {
  ids: string
  contentType: number
  collectionId: number
}) {
  return API.post(API_COLLECTION_MATERIAL_BATCH_ADD, {
    ids,
    resource_type: contentType,
    group_id: collectionId,
  })
}

export function addBatchCollectionByShare({ ids, collectionId }: { ids: string; collectionId: number }) {
  return API.post(API_COLLECTION_BY_SHARE, {
    favorite_ids: ids,
    favorite_group_id: collectionId,
  })
}

// 删除素材从收藏夹
export function deleteMaterialFromCollection(id: string, contentType: number) {
  return API.post(API_COLLECTION_MATERIAL_CANCEL, {
    id,
    resource_type: contentType,
  })
}

// 删除素材从收藏夹（批量）
export function deleteMultiMaterialsFromCollection({ ids }: { ids: string }) {
  return API.post(API_COLLECTION_MATERIAL_CANCEL_BATCH, {
    ids,
  })
}

// 获取素材从收藏夹
export function getMaterialsFromCollection({
  headers,
  id,
  page,
  per_page,
  type,
}: {
  headers?: PLUS.Headers
  id?: number
  page?: number
  per_page?: number
  type?: ContentType
}) {
  return API.get(`${API_COLLECTION_MATERIAL_LIST}`, {
    headers,
    params: {
      id,
      page,
      per_page,
      type,
    },
  })
}

// 移动素材至其它收藏夹
export function modifyMaterialsBelongCollection(ids: string, groupId: number) {
  return API.post(API_COLLECTION_MATERIAL_MOVE, {
    ids,
    group_id: groupId,
  })
}

export function getShareCollectionList({
  headers,
  token,
  type,
  page,
}: {
  headers?: PLUS.Headers
  token: string
  type?: ContentType | string
  page?: number
}) {
  return API.get(API_COLLECTION_SHARE_LIST, {
    headers,
    params: {
      token,
      type,
      page,
    },
  })
}

export function getCollectionCount({ id, headers }: { id?: number; headers?: PLUS.Headers }) {
  return API.get(API_COLLECTION_COUNT, {
    headers,
    params: {
      id,
    },
  })
}

export function getLastCollection(id?: string, contentType?: ContentType) {
  return API.get(API_COLLECTION_LAST, {
    params: {
      resource_id: id,
      resource_type: contentType,
    },
  })
}
