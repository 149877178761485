import { useState, useEffect, useLayoutEffect, useRef, useCallback } from 'react'
import { noop, loadImage } from 'helpers/util'
import throttle from 'lodash.throttle'

export function useInputValue(initialValue: string): [string, (e: React.ChangeEvent<HTMLInputElement>) => void] {
  const [value, setValue] = useState(initialValue)
  const onChange = (event) => setValue(event.currentTarget.value)

  return [value, onChange]
}

type UseToggle = [boolean, (nextValue?: boolean) => void]

export function useToggle(initialValue: boolean): UseToggle {
  const [value, setValue] = useState(initialValue)

  const toggle = (nextValue?: boolean) => {
    if (typeof nextValue !== 'undefined') {
      setValue(!!nextValue)
      return
    }

    setValue(!value)
  }

  return [value, toggle]
}

export function useLockBodyScroll() {
  useLayoutEffect((): any => {
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = 'visible'
    }
  }, [])
}

export function useWindowSize() {
  const isClient = typeof window === 'object'

  function getSize() {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    }
  }

  const [windowSize, setWindowSize] = useState(getSize)

  useEffect(() => {
    if (!isClient) {
      return noop
    }

    function handleResize() {
      setWindowSize(getSize())
    }

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowSize
}

export function useWindowScrollPosition(options?: { throttle?: number; use?: boolean }) {
  const opts = { throttle: 100, ...options }

  const [position, setPosition] = useState({ x: 0, y: 0 })

  useEffect(() => {
    const handleScroll = throttle(() => {
      setPosition({
        x: window.pageXOffset,
        y: window.pageYOffset,
      })
    }, opts.throttle)

    if (opts.use) {
      window.addEventListener('scroll', handleScroll, false)
    } else {
      window.removeEventListener('scroll', handleScroll)
    }

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [opts.use])

  return opts.use ? position : null
}

export function useHover() {
  const [value, setValue] = useState(false)

  const ref = useRef(null)

  const handleMouseOver = () => setValue(true)
  const handleMouseOut = () => setValue(false)

  useEffect(() => {
    const node = ref.current
    if (node) {
      node.addEventListener('mouseover', handleMouseOver)
      node.addEventListener('mouseout', handleMouseOut)

      return () => {
        node.removeEventListener('mouseover', handleMouseOver)
        node.removeEventListener('mouseout', handleMouseOut)
      }
    }
  }, [ref.current]) // Recall only if ref changes

  return [ref, value]
}

export function useElementHeight() {
  const [height, setHeight] = useState<number>(0)
  const ref = useRef<HTMLElement>(null)

  useEffect(() => {
    setHeight(ref.current.clientHeight)
  }, [ref.current])

  return [ref, height]
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function useInterval(callback: Function, delay: number) {
  const savedCallback = useRef<any>(null)

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (delay !== null) {
      const id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}

export function useImageAspect(src: string, aspect?: number) {
  const [naturalAspect, setNaturalAspect] = useState(aspect)

  useEffect(() => {
    if (!aspect) {
      loadImage(src)
        .then((img: HTMLImageElement) => {
          setNaturalAspect(img.naturalWidth / img.naturalHeight)
        })
        .catch(console.error)
    }
  }, [src])

  return {
    aspect: naturalAspect,
  }
}

type DebounceFn<T extends any[]> = (...args: T) => any

interface RV<T extends any[]> {
  run: (...args: T) => void
  runNow: (...args: T) => void
  clear: () => void
}

export function useDebounce<T extends any[]>(fn: DebounceFn<T>, delay = 300): RV<T> {
  const timer = useRef<ReturnType<typeof setTimeout> | null>(null)

  const clear = useCallback(() => {
    if (timer.current) {
      clearTimeout(timer.current)
      timer.current = null
    }
  }, [])

  const fnRef = useRef<DebounceFn<T>>(fn)
  fnRef.current = fn

  const runNow = useCallback((...args: T) => {
    fnRef.current(...args)
  }, [])

  const run = useCallback(
    (...args: T) => {
      clear()
      timer.current = setTimeout(() => {
        fnRef.current(...args)
        clear()
      }, delay)
    },
    [delay, clear]
  )

  useEffect(() => clear, [clear])

  return { run, runNow, clear }
}

// table滚动时给固定列添加阴影
export function useTableScroll(container, fixed) {
  const [isShowLeftShadow, setIsShowLeftShadow] = useState(false)
  const [isShowRightShadow, setIsShowRightShadow] = useState(false)

  useEffect(() => {
    if (fixed && container) {
      const tableEle = container?.childNodes?.[0]
      // table外层div宽度
      let containerWidth = container?.offsetWidth
      let tableWidth = tableEle?.offsetWidth
      // table滚动过的距离
      let containerScrollLeft = container?.scrollLeft

      // 屏幕布局改变重新计算table和container宽度
      const handleResize = throttle(() => {
        containerWidth = container?.offsetWidth
        tableWidth = tableEle?.offsetWidth
        if (containerWidth < tableWidth) {
          setIsShowRightShadow(true)
        } else {
          setIsShowRightShadow(false)
        }
      }, 100)

      // table滚动时重新计算table和container宽度
      const handleScroll = throttle(() => {
        containerScrollLeft = container?.scrollLeft
        if (containerScrollLeft + containerWidth >= tableWidth) {
          setIsShowRightShadow(false)
          setIsShowLeftShadow(true)
        } else {
          setIsShowRightShadow(true)
          setIsShowLeftShadow(false)
        }
      }, 300)

      handleResize()
      container.addEventListener('scroll', handleScroll, false)
      window.addEventListener('resize', handleResize, false)

      return () => {
        container.removeEventListener('scroll', handleScroll)
        window.removeEventListener('resize', handleResize)
      }
    }
  }, [container])

  if (!container) return {}

  return { isShowLeftShadow, isShowRightShadow }
}
